<template lang="html">
    <pfd />
</template>

<script>
import pfd from '../components/PFD/pfd_main00.vue'

export default {
    name: 'PFD',
    components: { pfd },
}
</script>

<style lang="scss" scoped></style>
