'use strict'

import { computed, reactive } from 'vue'
//Ces fonctions retourne les coordonée de l'objet à modifier

export default function (plant, compteur) {
    let clicSave = { x: 0, y: 0 }
    let grab = {
        tools: false,
        connector: false,
        dotConnector: false,
        boxName: false,
        boxTechnical: false,
        origin: false,
        graph: false,
    }
    let originPFD = reactive({ x: 0, y: 0, sc: 1.5 })
    let offset = { x: 0, y: 0 }
    let gpos = { x: 0, y: 0 }
    let indexPtMove = -1

    const listOfFeedConnector = computed(() => {
        let res = []
        plant.tools.forEach((tools) => {
            tools.con.forEach((feedConnector) => {
                if (feedConnector.type === 'feed') {
                    res.push({
                        id: `${tools.id}@${feedConnector.id}`,
                        x: tools.gpos.x + feedConnector.x,
                        y: tools.gpos.y + feedConnector.y,
                        isConnect:
                            feedConnector.connectedto === '' ? false : true,
                        connectedTo: feedConnector.connectedto,
                    })
                }
            })
        })
        return res
    })

    function setOriginPFD(a) {
        const INCREMENT_ZOOM = 0.02
        switch (a) {
            case 0:
                originPFD.x = 0
                originPFD.y = 0
                originPFD.sc = 1
                break
            default:
                originPFD.sc += a * INCREMENT_ZOOM
        }
    }
    function getGpos(a) {
        const transforms = a.transform.baseVal
        let svg = document.getElementById('svg_id')
        if (
            transforms.length === 0 ||
            transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE
        ) {
            // Create an transform that translates by (0, 0)
            const translate = svg.createSVGTransform()
            translate.setTranslate(0, 0)
            a.transform.baseVal.insertItemBefore(translate, 0)
        }

        let transform = transforms.getItem(0)
        return {
            x: transform.matrix.e,
            y: transform.matrix.f,
        }
    }
    function getMousePosition(evt) {
        const CTM = document.getElementById('grille').getScreenCTM()
        if (evt.touches) {
            evt = evt.touches[0]
        }

        return {
            x: (evt.clientX - CTM.e) / CTM.a,
            y: (evt.clientY - CTM.f) / CTM.d,
        }
    }
    //Methods : bouger fond de plan
    function bougerFond(evt) {
        clicSave = getMousePosition(evt)
        grab.graph = true
    }
    function bougeFond(evt) {
        let coord = getMousePosition(evt)
        originPFD.x = originPFD.x + (-clicSave.x + coord.x)
        originPFD.y = originPFD.y + (-clicSave.y + coord.y)
        return { x: 0, y: 0, type: '' }
    }
    //Methods : bouger icone matériel
    function bougerTools(evt, isMenu = false) {
        // On bouge l'elt en lui même
        offset = getMousePosition(evt)
        gpos = getGpos(evt.target.parentNode)
        // Get initial translation
        offset.x -= gpos.x
        offset.y -= gpos.y

        if (isMenu) {
            offset.x += originPFD.x / originPFD.sc
            offset.y += originPFD.y / originPFD.sc
        }
        grab.tools = true
    }
    function bougeTools(evt) {
        //On set activeTools to the good number
        const indexTools = compteur.activeToolsIndex
        //console.log("indexTools : " + indexTools);
        // let test = pfdCalc.calcul( this.tools);
        // On recherche la position de la souris

        const coord = getMousePosition(evt)
        let dx = coord.x - offset.x
        let dy = coord.y - offset.y

        plant.tools[indexTools].gpos.x = dx
        plant.tools[indexTools].gpos.y = dy
        // On enregistre dans l'objet tools
        plant.tools[indexTools].gpos.x = dx
        plant.tools[indexTools].gpos.y = dy
        // On modifie les coordonées des fils lié
        plant.filTableau.forEach((el) => {
            // Cas d'une connexion Start
            if (el.start.split('@')[0] == plant.tools[indexTools].id) {
                const shortcutTools = plant.tools[indexTools].con
                const clePoint = shortcutTools
                    .map((x) => x.id == el.start.split('@')[1])
                    .indexOf(true)

                // Dans ce cas on modifie le point [0]
                if (el.point[0].x != el.point[1].x) {
                    el.point[0].x = shortcutTools[clePoint].x + dx
                    el.point[0].y = shortcutTools[clePoint].y + dy

                    // Dans ce cas on modifie le point [1]
                    el.point[1].x = el.point[0].x + 30
                    el.point[1].y = el.point[0].y

                    el.point[2].x = el.point[1].x
                    el.point[2].y = el.point[1].y

                    el.point[3].x = el.point[2].x
                    el.point[3].y = el.point[4].y

                    // Dans ce cas on modifie le point [2]
                    // el.point[2].x = shortcutTools[clePoint].x + dx+50;
                } else {
                    el.point[0].x = shortcutTools[clePoint].x + dx
                    el.point[0].y = shortcutTools[clePoint].y + dy
                    // Dans ce cas on modifie le point [1]
                    el.point[1].x = el.point[0].x
                    el.point[1].y = shortcutTools[clePoint].y + dy + 30
                    // Dans ce cas on modifie le point [2]
                    el.point[2].x = el.point[3].x
                    el.point[2].y = el.point[1].y
                }
            }
            // Cas d'une connexin End
            if (el.end.split('@')[0] == plant.tools[indexTools].id) {
                const shortcutTools = plant.tools[indexTools].con
                const clePoint = shortcutTools
                    .map((x) => x.id == el.end.split('@')[1])
                    .indexOf(true)
                // Dans ce cas on modifie le point [0]
                el.point[5].x = shortcutTools[clePoint].x + dx
                el.point[5].y = shortcutTools[clePoint].y + dy
                // Dans ce cas on modifie le point [1]
                el.point[4].x = el.point[5].x
                el.point[4].y = el.point[5].y - 30

                el.point[3].y = el.point[4].y
            }
        })
    }
    //Methods : bouger icone matériel
    function bougerBox(evt, bType) {
        // On bouge l'elt en lui même
        offset = getMousePosition(evt)
        gpos = getGpos(evt.target.parentNode)
        // Get initial translation
        offset.x -= gpos.x
        offset.y -= gpos.y

        if (bType == 'name') grab.boxName = true
        if (bType == 'technical') grab.boxTechnical = true
    }
    function bougeBox(evt) {
        //On set activeTools to the good number
        const indexTools = compteur.activeToolsIndex
        //console.log("indexTools : " + indexTools);
        // let test = pfdCalc.calcul( this.tools);
        // On recherche la position de la souris

        const coord = getMousePosition(evt)
        const dx = coord.x - offset.x
        const dy = coord.y - offset.y
        //plant.tools[indexTools].gpos.x = dx;
        if (grab.boxName) {
            const raccourci = plant.tools[indexTools].prmShow.name
            raccourci.posX = dx
            raccourci.posY = dy
        }
        if (grab.boxTechnical) {
            const raccourci = plant.tools[indexTools].prmShow.technical
            raccourci.posX = dx
            raccourci.posY = dy
        }
    }
    //Methods : bouger connector
    function getFeedCoorOfconnexion(dx, dy) {
        const DISTANCE_GRAB = 8
        const indexFeed = listOfFeedConnector.value
            .map(
                (p) =>
                    p.x - dx < DISTANCE_GRAB &&
                    dx - p.x < DISTANCE_GRAB &&
                    p.y - dy < DISTANCE_GRAB &&
                    dy - p.y < DISTANCE_GRAB
            )
            .indexOf(true)
        const isConnect = indexFeed != -1
        const x = isConnect ? listOfFeedConnector.value[indexFeed].x : 0
        const y = isConnect ? listOfFeedConnector.value[indexFeed].y : 0
        return { isConnect: isConnect, x: x, y: y }
    }
    function bougerConnector() {
        grab.connector = true
    }
    function bougeConnector(evt) {
        const coord = getMousePosition(evt)
        let dx = coord.x
        let dy = coord.y
        //On teste si l'on est proche d'un feed element
        const coordConnexion = getFeedCoorOfconnexion(dx, dy)

        if (coordConnexion.isConnect) {
            dx = coordConnexion.x
            dy = coordConnexion.y
        }
        plant.filTableau[compteur.activeConnectorIndex].point[5].x = dx
        plant.filTableau[compteur.activeConnectorIndex].point[5].y = dy

        plant.filTableau[compteur.activeConnectorIndex].point[4].x = dx
        plant.filTableau[compteur.activeConnectorIndex].point[4].y = dy + -30

        // this.filTableau[this.fil_index-1].point[2].x=dx;
        plant.filTableau[compteur.activeConnectorIndex].point[3].y = dy + -30
    }
    function upConnector() {
        const indexFeed = listOfFeedConnector.value
            .map(
                (p) =>
                    p.x ==
                        plant.filTableau[compteur.activeConnectorIndex].point[5]
                            .x &&
                    p.y ==
                        plant.filTableau[compteur.activeConnectorIndex].point[5]
                            .y
            )
            .indexOf(true)

        if (indexFeed == -1 || listOfFeedConnector.value[indexFeed].isConnect) {
            // On détruit le fil

            plant.filTableau.splice(compteur.activeConnectorIndex, 1)
        } else {
            // On fait les connexions dans l'objet source
            const feedConnexion = { ...listOfFeedConnector.value[indexFeed] }
            const actCon = compteur.activeConnectorIndex
            const idOfToolsDestination = feedConnexion.id.split('@')[0]
            const idOfConnectorDestination = feedConnexion.id.split('@')[1]

            const indexOfToolsDestination = plant.tools
                .map((e) => e.id == idOfToolsDestination)
                .indexOf(true)
            const indexOfConnectorDestination = plant.tools[
                indexOfToolsDestination
            ].con
                .map((o) => o.id === idOfConnectorDestination)
                .indexOf(true)

            const connector = plant.filTableau[compteur.activeConnectorIndex]
            const idOfToolsFeed = connector.start.split('@')[0]
            const idOfConnectorFeed = connector.start.split('@')[1]
            const indexOfToolsFeed = plant.tools
                .map((e) => e.id == idOfToolsFeed)
                .indexOf(true)
            const indexOfConnectorFeed = plant.tools[indexOfToolsFeed].con
                .map((o) => o.id == idOfConnectorFeed)
                .indexOf(true)

            connector.end = feedConnexion.id
            plant.tools[indexOfToolsDestination].con[
                indexOfConnectorDestination
            ].connectedto = connector.start
            plant.tools[indexOfToolsFeed].con[
                indexOfConnectorFeed
            ].connectedto = connector.end

            plant.filTableau[actCon].end = feedConnexion.id
        }

        grab.connector = false
    }
    function bougerDotConnector(evt) {
        grab.dotConnector = true

        compteur.activeConnectorIndex = plant.filTableau
            .map((o) => o.id == evt.id)
            .indexOf(true)

        indexPtMove = evt.indexPt
    }
    function bougeDotConnector(evt) {
        const coord = getMousePosition(evt)
        const dx = coord.x
        const dy = coord.y
        const ptTable = plant.filTableau[compteur.activeConnectorIndex].point
        switch (indexPtMove) {
            case 1:
                // On regarde si démarre sur un coté
                if (ptTable[0].x != ptTable[1].x) {
                    ptTable[1].x = dx
                    ptTable[2].x = dx
                    ptTable[3].x = dx
                } else {
                    ptTable[1].y = dy
                    ptTable[2].y = dy
                }
                break
            case 2:
                if (ptTable[0].x != ptTable[1].x) {
                    ptTable[1].x = dx
                    ptTable[2].x = dx
                    ptTable[3].x = dx
                } else {
                    ptTable[1].y = dy
                    ptTable[2].y = dy
                    ptTable[2].x = dx
                    ptTable[3].x = dx
                }
                break
            case 3:
                if (ptTable[0].x != ptTable[1].x) {
                    ptTable[1].x = dx
                    ptTable[2].x = dx
                    ptTable[3].x = dx
                    ptTable[3].y = dy
                    ptTable[4].y = dy
                } else {
                    ptTable[2].x = dx
                    ptTable[3].x = dx
                    ptTable[3].y = dy
                    ptTable[4].y = dy
                }
                break
            case 4:
                ptTable[3].y = dy
                ptTable[4].y = dy
                break
            default:
        }
    }

    function sourisBouge(evt) {
        if (grab.graph) bougeFond(evt)
        if (grab.tools) bougeTools(evt)
        if (grab.boxName || grab.boxTechnical) bougeBox(evt)
        if (grab.connector) bougeConnector(evt)
        if (grab.dotConnector) bougeDotConnector(evt)
    }
    function sourisDown(
        evt,
        type = 'tools',
        key = 0,
        bType = '',
        isMenu = false
    ) {
        //evt: est l'evènement du clickDown
        //key: est l'id du tools/connecteur à modifier
        //bType : defini le type de la boite à bouger
        //isMenu: defini si le tools est issu du menu
        if (type === 'graph') bougerFond(evt)
        if (type === 'tools') {
            //key est l'id du tools sélectionner
            const index = plant.tools.map((u) => u.id == key).indexOf(true)
            compteur.activeToolsIndex = index
            bougerTools(evt, isMenu)
        }
        if (type === 'connector') {
            compteur.activeConnectorIndex = key

            bougerConnector(evt)
        }
        if (type === 'box') {
            const index = plant.tools.map((u) => u.id == key).indexOf(true)
            compteur.activeToolsIndex = index
            bougerBox(evt, bType)
        }
    }
    function sourisUp() {
        if (grab.connector) upConnector()
        for (const elt in grab) grab[elt] = false
        compteur.activeToolsIndex = -1
        compteur.activeConnectorIndex = -1
    }

    const transOrigin = computed(() => {
        return `translate(${originPFD.x} ${originPFD.y}) scale(${originPFD.sc})`
    })
    const transMenu = computed(() => {
        return `scale(${originPFD.sc})`
    })
    /*
  function bougerTools() {}
  function bougerFil() {}
  function bougerPtFil() {}
  function bougerText() {}
  */
    return {
        setOriginPFD,
        originPFD,
        transOrigin,
        transMenu,
        sourisUp,
        sourisDown,
        sourisBouge,
        listOfFeedConnector,
        bougerDotConnector,
    }
}
