<template lang="html">
    <g v-show="textTech.l2 != ''" :transform="tranformTxt">
        <rect class="fondBoite" x="0" y="0" width="55" height="75" />
        <text class="t1" x="2.5" y="9"> {{ textTech.l0 }} </text>
        <text class="t2" x="2.5" y="19"> {{ textTech.l1 }} </text>
        <text class="t2" x="2.5" y="29"> {{ textTech.l2 }} </text>
        <text class="t2" x="2.5" y="39"> {{ textTech.l3 }} </text>
        <text class="t2" x="2.5" y="49"> {{ textTech.l4 }} </text>
        <text class="t2" x="2.5" y="59"> {{ textTech.l5 }} </text>
        <text class="t2" x="2.5" y="69"> {{ textTech.l6 }} </text>
        <rect
            class="isTransparent"
            x="0"
            y="0"
            width="55"
            height="75"
            @mousedown="fctBougerBox($event)"
        >
            <title>{{ textTech.helpMsg }}</title>
        </rect>
    </g>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import unitLib from '@/components/PFD/PFD_Modal/compCommon/unit.js'

const { unit, conv, storeUnit } = unitLib()
const { t } = useI18n()

const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['bougerbox'])
const tranformTxt = computed(() => {
    return `translate(${props.d.prmShow.technical.posX},${props.d.prmShow.technical.posY})`
})
const textTech = computed(() => {
    const res = {
        l0: '',
        //l0: '0',
        l1: '',
        //l1: '1',
        l2: '',
        //l2: '2',
        l3: '',
        //l3: '3',
        l4: '',
        //l4: '4',
        l5: '',
        //l5: '5',
        l6: '',
        //l6: '6',
        helpMsg: '',
    }
    res.l0 = props.d.name || 'Id: ' + props.d.id
    res.l0 = res.l0.substring(0, 11)

    switch (props.d.type) {
        case 'screen1':
        case 'screen2':
        case 'screen3':
        case 'crusherCone':
        case 'crusherJaw':
        case 'crusherHSI':
        case 'crusherVSI': {
            res.l1 =
                String(props.d.technical?.brand).substring(0, 4) +
                ' - ' +
                props.d.technical?.ref.substring(0, 6)

            res.l2 = 'load: ' + Math.round(props.d.perfResults.load * 100) + '%'
            break
        }
        default:
            break
    }

    switch (props.d.type) {
        case 'Feed': {
            const mat = { ...props.d.con[0].material.intrinseque }
            let density = mat.density
            if (storeUnit.lbft3) {
                density = conv.mtpm3_lbpft3(density)
            }
            res.l1 = mat.material
            res.l2 = 'De: ' + density + t(unit.value.density)
            res.l3 = 'Mo: ' + mat.moisture + ' %'
            res.l4 = 'Cl: ' + mat.clay + ' %'
            res.l5 = 'wi: ' + mat.wIndex
            res.l6 = 'LA: ' + mat.losAngeles
            res.helpMsg = `De : ${t('word.density')} \nMo : ${t(
                'word.moisture'
            )}\nCl : ${t('word.clay')} \nwi : work index \nLA : losAngeles`
            break
        }
        case 'screen1':
        case 'screen2':
        case 'screen3': {
            let stroke = props.d.technical?.stroke
            let width = props.d.technical?.width
            let length = props.d.technical?.length
            let unite = 'm'
            if (storeUnit.inch) {
                stroke = conv.mm_inch(stroke)
                width = Math.round(conv.mm_inch(width))
                length = Math.round(conv.mm_inch(length))
                unite = t(unit.value.length, 2)
            } else {
                width = Math.round((width * 10) / 1000) / 10
                length = Math.round((length * 10) / 1000) / 10
            }
            res.l3 = 'An : ' + props.d.technical?.anScreen + '°'
            res.l4 = 'Sp : ' + props.d.technical?.speed + 'rpm'
            res.l5 = 'St : ' + stroke + t(unit.value.length, 2)
            res.l6 = 'WxL: ' + width + 'x' + length + unite
            res.helpMsg = `An: ${t('word.angle')} \nSp: ${t(
                'word.speed'
            )} \nSt: ${t('word.stroke')} \nWxL : ${t('word.surface')} (${t(
                'word.widthXLength'
            )}) `
            break
        }
        case 'crusherCone': {
            let css = props.d.technical?.css
            let th = props.d.technical?.stroke
            if (storeUnit.inch) {
                css = conv.mm_inch(css)
                th = conv.mm_inch(th)
            }
            res.l3 = 'css: ' + css + t(unit.value.length, 2)
            res.l4 = 'th: ' + th + t(unit.value.length, 2)
            res.l5 =
                props.d.technical.chamber + ' - ' + props.d.technical.mantle

            res.helpMsg = `th: ${t('word.throw')}`
            break
        }
        case 'crusherJaw': {
            let css = props.d.technical?.css
            let gape = props.d.technical?.gape
            let width = props.d.technical?.width
            let unite = 'm'
            if (storeUnit.inch) {
                css = conv.mm_inch(css)
                gape = Math.round(conv.mm_inch(gape))
                width = Math.round(conv.mm_inch(width))
                unite = t(unit.value.length, 2)
            } else {
                gape = Math.round((gape * 10) / 1000) / 10
                width = Math.round((width * 10) / 1000) / 10
            }

            res.l3 = 'css: ' + css + t(unit.value.length, 2)
            res.l4 = 'op: ' + width + 'x' + gape + unite

            res.helpMsg = `op: ${t('word.openning')} (${t(
                'word.widthXLength'
            )})`
            break
        }
        case 'crusherHSI': {
            let perimetre = (props.d.technical.rDiam / 1000) * Math.PI

            //let unite = 'm/s'
            if (storeUnit.inch) {
                perimetre = conv.mm_inch(props.d.technical.rDiam) * Math.PI
                //unite = 'in/s'
            }
            let tanSpeed = (props.d.technical.speed * perimetre) / 60
            tanSpeed = Math.round(tanSpeed)
            res.l2 = `Sp: ${props.d.technical.speed} ${t('unit.rpm')}`
            res.l3 = `Sp: ${tanSpeed} ${t(unit.value.length, 2)}/s`

            break
        }
        case 'crusherVSI': {
            let perimetre = (props.d.technical.rDiam / 1000) * Math.PI

            //let unite = 'm/s'
            if (storeUnit.inch) {
                perimetre = conv.mm_inch(props.d.technical.rDiam) * Math.PI
                //unite = 'in/s'
            }
            let tanSpeed = (props.d.technical.speed * perimetre) / 60
            tanSpeed = Math.round(tanSpeed)
            res.l2 = `Sp: ${props.d.technical.speed} ${t('unit.rpm')}`
            res.l3 = `Sp: ${tanSpeed} ${t(unit.value.length, 2)}/s`

            break
        }
        default:
            res.l1 = ''
            res.l2 = ''
            res.l3 = ''
            res.l4 = ''
            res.l5 = ''
            res.l6 = ''
    }

    return res
})
function fctBougerBox(evt) {
    console.log(evt)
    emit('bougerbox', evt)
}
</script>

<style lang="scss" scoped>
g {
    text {
        font-family: monospace;
    }
    .t1 {
        font-size: 0.6em;
        font-weight: bolder;
    }
    .t2 {
        font-size: 0.55em;
    }

    .fondBoite {
        fill: #888;
        fill-opacity: 0.3;
        stroke-opacity: 1;
    }
    .isTransparent {
        fill-opacity: 0;
        cursor: pointer;
    }
    .isTransparent:hover {
        fill-opacity: 0.2;
    }
}
</style>
