<template lang="html">
    <div class="global">
        <h3>Sorry you didn't find any &#128142; &#128142; &#128142; !</h3>
        <h3>keep working you may find some soon</h3>
        <h2 style="padding-top: 50px">Error 404</h2>
        <h2>This page does not exist</h2>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.global {
    margin: auto;
    padding: 50px;
}
h3,
h2 {
    text-align: center;
}
</style>
