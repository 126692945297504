<template lang="html">
    <div
        :class="{
            fond: true,
            noColor: props.couleur == 'loading',
            positive: props.couleur == 'positive',
            negative: props.couleur == 'negative',
        }"
    >
        <logolib :d="props.couleur" />
        <h3>{{ props.donnee }}</h3>
    </div>
</template>

<script setup>
import { defineProps } from 'vue'
import logolib from './0_logo.vue'
const props = defineProps({
    donnee: { type: String, required: true },
    couleur: { type: String, required: false, default: 'loading' },
})
//const donnee = ref(props.donnee)
</script>

<style lang="scss" scoped>
.fond {
    position: absolute;
    top: 35%;
    margin: auto;
    min-width: 20%;
    padding: 20px;
    border: solid $infoColor-bg 10px;
    border-left: none;
    border-right: none;
    background-color: $infoColor-bg;
    @extend .flex-c-sb;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-blend-mode: color-burn;
    z-index: 999;
    h3 {
        text-align: center;
        color: $fontColor_H;
        font-size: 1.5rem;
    }
    img {
        display: block;
        width: 8%;
        margin: auto;
    }
}

.positive {
    border-bottom-color: $infoColor-bg-positive;
}
.noColor {
    border: none;
}
.negative {
    border-bottom-color: $infoColor-bg-negative;
}
</style>
