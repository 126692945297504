<template lang="html">
    <div class="blocHaut">
        <h1>Contact</h1>
    </div>
    <div class="blocContact">
        <img src="../assets/Photo/moi.jpg" />
        <div class="contact">
            <p style="font-weight: 900">
                Pierre Billard | {{ t('page-contact.jobTitle') }}
            </p>
            <p>📧 pierre@rock-process.com</p>
            <p>☎️ +33.6.66.67.24.05</p>
            <a href="https://www.linkedin.com/in/pierre-billard-98086659/"
                >👷 Linkedin profile</a
            >
        </div>
    </div>
    <p class="resume" v-html="t('page-contact.resume')"></p>
    <p class="resume" style="font-weight: 900">
        If you need any help from me, feel free to contact me
    </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
// import headernavigation from "../components/header.vue";
</script>

<style lang="scss" scoped>
.resume {
    text-align: center;
}
.blocHaut {
    margin: auto;
    h1 {
        text-align: center;
        @extend h2;
    }
    p {
        text-align: center;
    }
}
.blocContact {
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}
img {
    clip-path: circle();
    height: 200px;
}
.contact {
    //border: solid $fontColor_H 2px;
    //border-radius: 10px;
    padding: 20px;
    p {
        margin: 2px;
    }
}
</style>
