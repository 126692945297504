<template>
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi rounded-lg">
                <!-- Modal Header -->
                <div class="modal-header-pbi">
                    <h3 class="modal-title-pbi">
                        {{ t('word.editDistribution') }}
                    </h3>

                    <button type="button" class="close" @click="emit('close')">
                        &times;
                    </button>
                </div>
                <!-- Modal body -->
                <body>
                    <section class="formulaire">
                        <div class="top">
                            <div class="input-point">
                                <div class="bloc-input-pbi">
                                    <label for="mesh">{{
                                        t('word.mesh')
                                    }}</label>
                                    <input
                                        v-model="newPt.mesh"
                                        name="mesh"
                                        type="number"
                                        step="any"
                                        min="0"
                                    />
                                    <span class="unit">{{
                                        t(unit.length)
                                    }}</span>
                                </div>
                                <div class="bloc-input-pbi">
                                    <label for="passing">{{
                                        t('word.cumulPassing')
                                    }}</label>
                                    <input
                                        v-model="newPt.passing"
                                        name="passing"
                                        type="number"
                                        step="any"
                                        :min="valeurMinimum"
                                        :max="valeurMaximum"
                                    />
                                    <span class="unit">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="comment">
                            <span class="information">{{
                                t('word.min') +
                                ': ' +
                                Math.round(valeurMinimum * 100) / 100 +
                                ' %'
                            }}</span>
                            <span class="information">{{
                                t('word.max') +
                                ': ' +
                                Math.round(valeurMaximum * 100) / 100 +
                                ' %'
                            }}</span>
                        </div>
                        <button class="btnSavePt" @click="saveNewPt()">
                            <div class="std-ButtonTxtIcon">
                                <h5>{{ t('modalDistribution.registerPt') }}</h5>
                                <i class="material-icons">save</i>
                            </div>
                        </button>

                        <canvas id="pbi_canvas" class="graphique"></canvas>
                    </section>
                    <section>
                        <table class="tableau">
                            <tr>
                                <th class="titre">{{ t('word.mesh') }}</th>
                                <th class="titre">{{ t('word.value') }}</th>
                                <th class="titre">{{ t('word.del') }}</th>
                            </tr>
                            <tr v-for="(item, index) in dTemp" :key="item.x">
                                <td>{{ '#' + item.x }}</td>
                                <td>{{ Math.round(item.y * 100) / 100 }}</td>
                                <td
                                    class="tableBoutonDel"
                                    @click="delElement(index)"
                                >
                                    <i class="material-icons">delete</i>
                                </td>
                            </tr>
                        </table>
                    </section>
                </body>
                <modalfooter @save="save()"></modalfooter>
            </div>
        </div>
    </div>
</template>

<script setup>
import Chart from 'chart.js/auto'
import modalfooter from './0_modalfooter.vue'
//import Chart from "chart.js/auto";

import {
    reactive,
    defineEmits,
    defineProps,
    ref,
    onMounted,
    watch,
    computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['close', 'save'])
const dLocal = ref(props.d)
let myGraphique = Object
const options = {
    responsive: true,
    maintainAspectRatio: true,
    title: {
        display: false,
    },
    scales: {
        xAxes: {
            type: 'logarithmic',
            position: 'bottom',
            offset: true,
            ticks: {
                beginAtZero: true,
                userCallback(tick) {
                    const remain =
                        tick /
                        Math.pow(10, Math.floor(Chart.helpers.log10(tick)))
                    if (remain === 1 || remain === 2 || remain === 5) {
                        return `${tick.toString()}mm`
                    }
                    return ''
                },
            },
            scaleLabel: {
                labelString: 'Mesh dimension [mm]',
                display: true,
            },
        },
        yAxes: {
            type: 'linear',
            scaleLabel: {
                labelString: '% Pourcent',
                ticks: {
                    min: 0,
                    max: 100,
                    padding: 1,
                },
                display: true,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
}
const newPt = reactive({ mesh: 0, passing: 0 })
const dTemp = ref([])
import unitLib from './unit.js'
const { unit, conv, storeUnit } = unitLib()

function initialisation() {
    dTemp.value = JSON.parse(JSON.stringify(dLocal.value))
    if (storeUnit.inch) {
        dTemp.value = dTemp.value.map((t) => {
            t.x = conv.mm_inch(t.x)
            return t
        })
    }
}

function delElement(i) {
    //console.table(dLocal.value);
    dTemp.value.splice(i, 1)
    myGraphique.data.datasets = getCourbe()
    myGraphique.update()
}

function save() {
    if (storeUnit.inch) {
        dTemp.value.map((pt) => {
            pt.x = conv.inch_mm(pt.x)
            return pt
        })
    }

    emit('save', dTemp.value)
}

const valeurMinimum = computed(() => {
    const res = JSON.parse(JSON.stringify(dTemp.value)).reduce((a, b) => {
        if (newPt.mesh > b.x) {
            return b.y
        } else {
            return a
        }
    }, 0)

    return res
})
const valeurMaximum = computed(() => {
    const res = JSON.parse(JSON.stringify(dTemp.value))
        .sort((a, b) => b.x - a.x)
        .reduce((a, b) => {
            if (newPt.mesh < b.x) {
                return b.y
            } else {
                return a
            }
        }, 100)

    return res > 100 ? 100 : res
})

function getCourbe() {
    const r = []

    r.push({
        pointStyle: 'crossRot',
        radius: 4,
        borderWidth: 1,
        tension: 0.2,
        fill: false,
        backgroundColor: 'red',
        borderColor: 'tomato',
        showLine: true,
        label: `Output`,
        data: JSON.parse(JSON.stringify(dTemp.value)),
    })
    return r
}

function saveNewPt() {
    console.log('******* SaveNewPt ***************')
    if (
        newPt.passing < valeurMinimum.value ||
        newPt.passing > valeurMaximum.value
    ) {
        console.log('erreur')
        return
    }
    console.log('Ajout du point')

    if (!dTemp.value.find((d) => d.x == newPt.mesh)) {
        dTemp.value.push({
            x: parseFloat(newPt.mesh),
            y: parseFloat(newPt.passing),
        })
        dTemp.value.sort((a, b) => a.x - b.x)
    } else {
        dTemp.value.map((pt) => {
            if (pt.x == newPt.mesh) {
                pt.y = parseFloat(newPt.passing)
            }
            return pt
        })
    }

    myGraphique.data.datasets = getCourbe()
    myGraphique.update()
    //console.table(dLocal.value);
}

watch(dTemp.value, () => {
    console.log('On watch')
    myGraphique.data.datasets = getCourbe()
    myGraphique.update()
})

onMounted(() => {
    initialisation()
    //console.log("initialisatio modal courbe");
    //console.table(dLocal.value);
    myGraphique = new Chart(document.getElementById('pbi_canvas'), {
        type: 'line',
        options: options,
        data: {
            datasets: getCourbe(),
        },
    })
})
</script>
<style scoped lang="scss">
.formulaire {
    padding: 1.5rem;
    .input-point {
        @extend .flex-c-sa;
        label {
            width: auto;
            margin-right: 1rem;
        }
    }
    .comment {
        margin: auto;
        @extend .flex-c-sa;
        width: 50%;
        span {
            font-size: 0.8rem;
            font-weight: bold;
            padding: 0.6rem;
            padding-top: 0;
        }
    }
    .btnSavePt {
        width: 40%;
        display: block;
        margin: auto;
        //width: fit-content;
    }
    canvas {
        margin-top: 1rem;
        min-width: 80% !important;
        aspect-ratio: 0.8 !important;
    }
}

body {
    @extend .flex-t-c;
    max-height: 65vh;
}

.tableau {
    display: block;
    overflow-y: auto;
    height: 100%;
    .tableBoutonDel {
        :hover {
            @extend .hover-std;
        }
    }
}

/*
canvas {
  display: block;
  height: 20rem !important;
  width: calc(20rem * 2) !important;
  padding: 1rem;
  margin: auto;
}
.tableau {
  display: block;
  height: 25rem;

  overflow-y: auto;
  & .titre {
    font-weight: 600;
  }
  th {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  td,
  th {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.boutonSave {
  width: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftPart {
  width: 60%;
}
.tableBoutonDel:hover {
  @extend .hover-std;
}

input,
select {
  @extend .box_pbi;
  font-size: 1.3rem;
  color: $fontColor_H;
  margin-right: 5px;
}
.comment {
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: 30%;
  span {
    padding: 1rem;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
body {
  display: flex;
  flex: 1;
  justify-content: space-around;
  height: 60vh;
  overflow-y: auto;
}
.leftPart {
  margin: auto;
  width: 75%;
}
section {
  height: auto;
}

.modal-header {
  margin-top: 0;
  color: $fontColor_H;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $ShadowColor;

  button {
    border: none;
    box-shadow: none;
    width: 5em;
    border-radius: 0;
  }
}
.top {
  display: flex;
  justify-content: space-around;
}
.bloc-input {
  margin: 1rem;
}
input {
  margin: 1rem;
  margin-bottom: 0.3rem;
  margin-top: 0.4rem;
}
*/
</style>
