<template>
    <div class="fond" :class="{ show: isExpand, hide: !isExpand }">
        <div class="modal-wrapper">
            <div
                style="display: block; opacity: 1; overflow-y: auto"
                class="modal-container"
            >
                <div id="header">
                    <h5>The following errors has occured :</h5>
                    <div id="headerButton" @click="isExpand = !isExpand">
                        <p v-if="isExpand">
                            <i class="material-icons">compress</i>
                        </p>
                        <p v-if="!isExpand">
                            <i class="material-icons">expand</i>
                        </p>
                    </div>
                </div>
                <p v-for="item in eltErreur" :key="item.id">{{ item }}</p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, ref, computed } from 'vue'

const props = defineProps({ d: { type: Array, required: true } })
const isExpand = ref(false)

const eltErreur = computed(() => {
    const res = props.d.map((e) => e.Description_erreur)
    return res
})
</script>

<style scoped lang="scss">
.show {
    top: 85vh;
}
.hide {
    top: 95vh;
}
.fond {
    position: fixed;
    z-index: 1;

    left: 0px;
    margin: auto;
    width: 100%;
    height: 15vh;
    background-color: rgba(0, 0, 0, 0);
    display: table;
    transition: opacity 0.9s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: bottom;
}
.modal-container {
    //max-height: 80vh;
    z-index: 0;
    width: 80%;
    height: 100%;
    margin: 0px auto;
    padding: 10px 10px;
    background-color: $alertColor-bg;
    opacity: 1;
    border-radius: 1rem;
    box-shadow: 0 2px 8px $alertColor-bo;
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;

    h2,
    h5,
    p {
        opacity: 1;
        padding: 0;
        margin: 0;
        color: $alertColor-ft;
    }
}

#headerButton {
    @extend .hover-std;
    padding: 5px;
}
#header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
