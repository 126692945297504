<template lang="html">
    <div class="bloc-auto">
        <select v-model="dLocal.technical.calcMode" class="selectModeCalcul">
            <option value="auto">
                {{ t('modalCrusher.calcMode.auto') }}
            </option>
            <option value="manual">
                {{ t('modalCrusher.calcMode.manual') }}
            </option>
        </select>
        <button
            v-if="dLocal.technical.calcMode == 'auto'"
            class="btn-ico"
            :disabled="cExist"
            @click="emit('open-modal-prm')"
        >
            <i class="material-icons"> view_headline </i>
        </button>
    </div>
</template>

<script setup>
'use strict'
import { ref, defineProps, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const emit = defineEmits(['openModalPrm'])
const props = defineProps({
    d: { type: Object, required: true },
    cExist: { type: Boolean, required: true },
})
const dLocal = ref(props.d)
const cExist = ref(props.cExist)
</script>

<style lang="scss" scoped>
.bloc-auto {
    @extend .flex-c-c;
    margin-left: 1rem;
    margin-right: 1rem;
}
</style>
