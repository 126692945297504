const plantStd = [
    {
        general: {
            name: 'Greenfield',
            author: 'Rock-process',
            date: '01',
        },
        tools: [],
        filTableau: [],
        erreur: [],
    },
    {
        general: {
            name: 'Simple screen',
            author: 'Rock-Process',
            date: 'Date',
        },
        tools: [
            {
                id: 0,
                type: 'screen1',
                name: '',
                gpos: { x: 356, y: 118 },
                con: [
                    {
                        id: '0000',
                        type: 'feed',
                        status: 'NOK',
                        x: 10,
                        y: 5,
                        connectedto: '1@1000',
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                    {
                        id: '1000',
                        type: 'deck',
                        x: 35,
                        y: 20,
                        connectedto: '5@0000',
                        perf: {
                            deckFeedCapacity: 100,
                            b: { isError: false },
                            deckLoad: 0.85,
                            nbImpact: 500,
                            bed: {},
                        },
                        deckTech: {
                            cutPoint: 40,
                            meshType: 'wire',
                            meshGeo: 'Square',
                        },
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                    {
                        id: '1001',
                        type: 'discharge',
                        x: 20,
                        y: 35,
                        connectedto: '3@0000',
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                ],
                prmShow: {
                    flow: true,
                    technical: { show: false, posX: -80, posY: 20 },
                    name: { show: true, posX: -80, posY: -20 },
                    mesh: false,
                },
                perfResults: { load: 1 },
                technical: {
                    brand: 'RP',
                    ref: 'SC0001',
                    calcMode: 'modePerfect',
                    motion: 'circular',
                    width: 1000,
                    length: 4000,
                    stroke: 10,
                    speed: 800,
                    wash: false,
                    anScreen: 16,
                    anEcc: 45,
                    maxFeedSize: 40,
                    maxLoad: 1000,
                },
            },
            {
                id: 1,
                type: 'Feed',
                name: '',
                gpos: { x: 154, y: 59 },
                con: [
                    {
                        id: '1000',
                        type: 'discharge',
                        x: 20,
                        y: 37,
                        connectedto: '0@0000',
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                ],
                prmShow: {
                    flow: true,
                    technical: { show: false, posX: -80, posY: 20 },
                    name: { show: true, posX: -80, posY: -20 },
                    mesh: false,
                },
                perfResults: { load: 1 },
                technical: {},
            },
            {
                id: 3,
                type: 'Stock',
                name: '',
                gpos: { x: 326, y: 245 },
                con: [
                    {
                        id: '0000',
                        type: 'feed',
                        status: 'NOK',
                        x: 20,
                        y: 7,
                        connectedto: '0@1001',
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                ],
                prmShow: {
                    flow: true,
                    technical: { show: false, posX: -80, posY: 20 },
                    name: { show: true, posX: -80, posY: -20 },
                    mesh: false,
                },
                perfResults: { load: 1 },
                technical: {},
            },
            {
                id: 5,
                type: 'Stock',
                name: '',
                gpos: { x: 462, y: 241 },
                con: [
                    {
                        id: '0000',
                        type: 'feed',
                        status: 'NOK',
                        x: 20,
                        y: 7,
                        connectedto: '0@1000',
                        material: {
                            flow: 300,
                            distribution: [
                                { x: 0.063, y: 1 },
                                { x: 1, y: 5 },
                                { x: 2, y: 10 },
                                { x: 4, y: 40 },
                                { x: 10, y: 50 },
                                { x: 20, y: 100 },
                            ],
                            intrinseque: {
                                material: 'LimeStone',
                                density: 1.6,
                                moisture: 3,
                                clay: 1,
                                wIndex: 15,
                                losAngeles: 23,
                                abrasivity: 0.35,
                            },
                        },
                    },
                ],
                prmShow: {
                    flow: true,
                    technical: { show: false, posX: -80, posY: 20 },
                    name: { show: true, posX: -80, posY: -20 },
                    mesh: false,
                },
                perfResults: { load: 1 },
                technical: {},
            },
        ],
        filTableau: [
            {
                id: 2,
                start: '1@1000',
                end: '0@0000',
                type: 'dry',
                point: [
                    { x: 174, y: 96 },
                    { x: 174, y: 146 },
                    { x: 224, y: 146 },
                    { x: 224, y: 93 },
                    { x: 366, y: 93 },
                    { x: 366, y: 123 },
                ],
            },
            {
                id: 4,
                start: '0@1001',
                end: '3@0000',
                type: 'dry',
                point: [
                    { x: 376, y: 153 },
                    { x: 376, y: 191.015625 },
                    { x: 256, y: 191.015625 },
                    { x: 256, y: 222 },
                    { x: 346, y: 222 },
                    { x: 346, y: 252 },
                ],
            },
            {
                id: 6,
                start: '0@1000',
                end: '5@0000',
                type: 'dry',
                point: [
                    { x: 391, y: 138 },
                    { x: 398, y: 138 },
                    { x: 398, y: 138 },
                    { x: 398, y: 218 },
                    { x: 482, y: 218 },
                    { x: 482, y: 248 },
                ],
            },
        ],
        erreur: [],
    },
]

export default function (plant) {
    const listOfPlant = plantStd.map((r) => r.general.name)

    function setNewPlant(req) {
        const res = plantStd.find((r) => r.general.name == req.target.value)
        console.log('Modfication plant standart')
        console.log(res)

        plant.tools = null
        plant.general = JSON.parse(JSON.stringify(res.general))
        plant.filTableau = JSON.parse(JSON.stringify(res.filTableau))
        plant.tools = JSON.parse(JSON.stringify(res.tools))
        plant.erreur = JSON.parse(JSON.stringify(res.erreur))
    }

    return { listOfPlant, setNewPlant }
}
