<template>
    <div class="zoneModele">
        <div class="blocInput">
            <label for="brandInput">{{ t('modalScreen.label.brand') }}</label>
            <input
                id="brandInput"
                v-model="dLocal.technical.brand"
                list="brand"
                @change="getlist()"
            />
            <datalist id="brand">
                <option v-for="item in brandList" :key="item">
                    {{ item }}
                </option>
            </datalist>
        </div>

        <div class="blocInput">
            <label for="ScreenReference">{{ t('word.ref') }}</label>
            <input
                id="ScreenReference"
                v-model="dLocal.technical.ref"
                list="ScreenRef"
            />
            <datalist id="ScreenRef">
                <!-- <option value="Metso"></option> -->
                <option v-for="item in listTechnical.listScreen" :key="item">
                    {{ item }}
                </option>
            </datalist>
        </div>
    </div>
    <div class="zoneTechnique">
        <!-- Zone WASHING & Screen Inclinaison -->
        <div class="zoneTech">
            <div class="blocInputV2">
                <label
                    class="form-check-label"
                    for="cb4"
                    style="margin-right: 2rem"
                    >{{ t('modalScreen.label.wash') }}</label
                >
                <input
                    id="cb4"
                    v-model="dLocal.technical.wash"
                    type="checkbox"
                    :disabled="!listTechnical.mayBeWashed"
                />
            </div>
            <div class="blocInput">
                <span>{{ t('modalScreen.label.angle') }}</span>
                <select
                    v-model="dLocal.technical.anScreen"
                    class="valeurText"
                    :disabled="listTechnical.screenExist"
                >
                    <option value="0">0°</option>
                    <option value="16">16°</option>
                    <option value="18">18°</option>
                    <option value="20">20°</option>
                </select>
            </div>
            <div class="blocInput">
                <span>{{ t('modalScreen.label.motion') }}</span>
                <select
                    v-model="dLocal.technical.motion"
                    class="valeurText"
                    :disabled="listTechnical.screenExist"
                >
                    <option value="circular">{{ t('word.circular') }}</option>
                    <option value="linear">{{ t('word.linear') }}</option>
                </select>
            </div>
        </div>
        <!-- Zone Largeur & longueur -->
        <div class="zoneDimension">
            <div class="blocInput">
                <span>{{ t('word.width') + ' ' + t(unit.length) }}</span>
                <input
                    v-model="dLocal.technical.width"
                    type="number"
                    step="any"
                    min="0"
                    class="valeurNumeric"
                    :disabled="listTechnical.screenExist"
                />
            </div>
            <div class="blocInput">
                <span>{{ t('word.length') + ' ' + t(unit.length) }}</span>
                <input
                    v-model="dLocal.technical.length"
                    type="number"
                    step="any"
                    min="0"
                    class="valeurNumeric"
                    :disabled="listTechnical.screenExist"
                />
            </div>
        </div>
        <!-- Zone vibration -->
        <div class="zoneVibration">
            <div class="blocInput">
                <span>{{ t('word.speed') + ' ' + t('unit.rpm') }} </span>
                <input
                    v-if="!listTechnical.screenExist"
                    v-model="dLocal.technical.speed"
                    type="number"
                    step="any"
                    min="500"
                    max="1200"
                    class="valeurNumeric"
                />
                <select
                    v-if="listTechnical.screenExist"
                    v-model="dLocal.technical.speed"
                    class="valeurNumeric"
                    name=""
                >
                    <option
                        v-for="item in listTechnical.screenSpeed"
                        :key="item"
                        :value="item"
                    >
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="blocInput">
                <span>{{ t('word.stroke') + ' ' + t(unit.length) }}</span>
                <input
                    v-model="dLocal.technical.stroke"
                    type="number"
                    step="any"
                    min="0"
                    max="100"
                    class="valeurNumeric"
                />
            </div>
        </div>
    </div>
    <div class="zoneMidle">
        <span>{{ t('modalScreen.calcul.label') }}</span>
        <select v-model="dLocal.technical.calcMode" class="valeurText">
            <option value="modePerfect">
                {{ t('modalScreen.calcul.perfect') }}
            </option>
            <option value="modeStatistic">
                {{ t('modalScreen.calcul.proba') }}
            </option>
        </select>
    </div>
    <div class="blocDeck">
        <div
            v-for="(valeur, index) in dLocal.con.filter((x) => {
                return x.type == 'deck'
            })"
            :key="valeur.id"
            class="zoneEtage"
        >
            <h5>{{ t('word.deck') + ' ' + (index + 1) }}</h5>
            <label>{{ t('modalScreen.label.meshType') }}</label>
            <select
                v-model="dLocal.con[index + 1].deckTech.meshType"
                class="valeurText"
            >
                <option value="wire">
                    {{ t('modalScreen.meshType.wire') }}
                </option>
                <option value="sideTensionPU">
                    {{ t('modalScreen.meshType.sidePu') }}
                </option>
                <option value="modularPU">
                    {{ t('modalScreen.meshType.modularPu') }}
                </option>
            </select>
            <label>{{
                t('modalScreen.label.cutPoint') + ' ' + t(unit.length)
            }}</label>
            <input
                v-model="dLocal.con[index + 1].deckTech.cutPoint"
                class="valeurNumeric"
                step="any"
                min="0"
                type="number"
            />
            <label>{{ t('modalScreen.label.meshShape') }}</label>
            <select
                v-model="dLocal.con[index + 1].deckTech.meshGeo"
                class="valeurText"
                disabled
            >
                <option value="Round">{{ t('word.round') }}</option>
                <option value="Square">{{ t('word.square') }}</option>
            </select>
        </div>
    </div>

    <!-- Modal footer -->
    <modalfooter @save="save()"></modalfooter>
</template>
<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
import conv from '@/components/convert.js'
import { defineEmits, defineProps, ref, inject, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
const socket = inject('socket')

import { stUnit } from '@/store/index.js'
const storeUnit = stUnit()

const { t } = useI18n()
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['save'])
const dLocal = ref(props.d)
const brandList = ['RP']
let detailsScreen = ref([])

const unit = computed(() => {
    const res = {
        length: 'unit.mm',
        mass: 'unit.mt',
        flow: 'unit.mth',
    }

    res.length = storeUnit.inch ? 'unit.inch' : 'unit.mm'
    res.mass = storeUnit.inch ? 'unit.it' : 'unit.mt'
    res.flow = storeUnit.inch ? 'unit.ith' : 'unit.mth'

    return res
})

const listTechnical = computed(() => {
    const res = {}
    let tabTemp = detailsScreen.value.map((e) => e.ref)
    res.listScreen = tabTemp.filter((g, index) => tabTemp.indexOf(g) == index)

    res.screenExist = false
    const screenSelected = detailsScreen.value.find(
        (j) =>
            dLocal.value.technical.brand == j.brand &&
            dLocal.value.technical.ref == j.ref
    )

    if (screenSelected) {
        setElements(screenSelected)
        res.screenExist = true
        res.mayBeWashed = screenSelected.wash
    }

    tabTemp = detailsScreen.value
        .filter(
            (j) =>
                dLocal.value.technical.brand == j.brand &&
                dLocal.value.technical.ref == j.ref
        )
        .map((y) => y.speed)

    res.screenSpeed = tabTemp.filter((o, i) => i == tabTemp.indexOf(o))

    return res
})

function launchSocket() {
    const brandScreen = dLocal.value.technical.brand
    console.log('GETINFOMACHINE / on lance la socket')
    try {
        socket.emit('GETINFOMACHINE', {
            type: 'screen',
            brand: brandScreen,
            nbDeck: dLocal.value.con.length - 2,
        })
    } catch (error) {
        console.info(error)
    }
}
function setElements(elt) {
    dLocal.value.technical.width = elt.width
    dLocal.value.technical.length = elt.length
    dLocal.value.technical.anScreen = elt.anScreen
    dLocal.value.technical.motion = elt.motion
    dLocal.value.technical.speed = elt.speed
    if (!elt.wash) {
        dLocal.value.technical.wash = false
    }
    initialUnitTranslation()
}
function initialUnitTranslation() {
    if (storeUnit.inch) {
        dLocal.value.technical.width = conv.mm_inch(
            dLocal.value.technical.width
        )
        dLocal.value.technical.length = conv.mm_inch(
            dLocal.value.technical.length
        )
        dLocal.value.technical.stroke = conv.mm_inch(
            dLocal.value.technical.stroke
        )
        dLocal.value.con
            .filter((f) => f.type == 'deck')
            .forEach((item) => {
                item.deckTech.cutPoint = conv.mm_inch(item.deckTech.cutPoint)
            })
    }
}
function save() {
    console.log('FONCTION SAVE SCREEN')
    console.log(dLocal.value)

    if (storeUnit.inch) {
        dLocal.value.technical.width = conv.inch_mm(
            dLocal.value.technical.width
        )
        dLocal.value.technical.length = conv.inch_mm(
            dLocal.value.technical.length
        )
        dLocal.value.technical.stroke = conv.inch_mm(
            dLocal.value.technical.stroke
        )
        dLocal.value.con
            .filter((f) => f.type == 'deck')
            .forEach((item) => {
                item.deckTech.cutPoint = conv.inch_mm(item.deckTech.cutPoint)
            })
    }
    console.log(dLocal.value)
    emit('save', dLocal.value)
}
function getlist() {
    // Lancement socket demande elts Screen
    const brandScreen = dLocal.value.technical.brand
    // console.log('test => ' + brandScreen)
    if (brandList.includes(brandScreen)) {
        if (detailsScreen.value.length > 0) {
            if (brandScreen != detailsScreen.value[0].brand) {
                launchSocket()
            } else {
                console.info('Cette marque est déjà en registre')
            }
        } else {
            launchSocket()
        }
    } else {
        console.info("Cette marque n'est pas référencé")
    }
}

onMounted(() => {
    socket.on('SENDINFOMACHINE', (d) => {
        console.info('**************************************** SOCKET ON ')
        console.info('RECEPTION INFO MACHINE DEPUIS LE SERVEUR ')
        // let n = JSON.parse(d)
        detailsScreen.value = JSON.parse(d)
    })
    launchSocket(dLocal.value.technical.brand)
    initialUnitTranslation()
})
</script>
<style scoped lang="scss">
.blocDeck {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}
.zoneEtage {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0.15rem;
    margin-bottom: 0.15rem;

    select,
    input {
        font-weight: 400;
        font-size: 1rem;
        padding: 0;
        width: 8rem;
        @extend .box_pbi;
        text-align: center;
    }
    h5 {
        margin-top: 0;
        margin-bottom: 0;
    }
    span {
        text-align: right;
        font-weight: 400;
        font-size: 1rem;
        margin-left: 2.5rem;
    }
}
.zoneTechnique {
    display: flex;
    justify-content: space-around;
    border: solid 1px $ShadowColor;
    border-radius: 10px;
    .valeurNumeric,
    .valeurText {
        width: 8rem;
        text-align: center;
        color: $fontColor_H;
        @extend .box_pbi;
        font-size: 1rem;
        font-weight: 400;
        padding: 0;
    }
    span {
        font-weight: 400;
        font-size: 1rem;
        width: 8rem;
        text-align: center;
    }

    .blocInput {
        display: flex;
        justify-content: center;
        align-items: space-around;
        padding: 0.2rem;
    }
    .zoneTech,
    .zoneDimension {
        border-right: solid 1px $ShadowColor;
    }
    .zoneTech,
    .zoneDimension,
    .zoneVibration {
        width: fit-content;
        padding: 1.5rem;
    }

    select,
    input {
    }
}

.zoneMidle {
    border-bottom: solid 1px $ShadowColor;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.2rem;
    input,
    select {
        @extend .box_pbi;
        font-size: 1rem;
        font-weight: 400;
        color: $fontColor_H;
        margin: 0.5rem;
        width: 20rem;
        text-align: center;
    }
}

.zoneModele {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    padding-top: 0.2rem;

    input,
    select {
        @extend .box_pbi;
        font-size: 1.1rem;
        color: $fontColor_H;
        margin: 0.5rem;
        width: 7.5rem;
        text-align: center;
    }
    span {
        font-size: 1.1rem;
        width: auto;
        padding: 0.5rem;
    }
}

.blocInputV2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
}
</style>
