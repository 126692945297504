<template lang="html">
    <div class="mask-initialisation">
        <h2>{{ $t('pfdMask.title1') }}</h2>
        <div class="titreMask">
            <h2>{{ $t('pfdMask.title2') }}</h2>
            <p class="legalTerms">
                {{ $t('pfdMask.legalTerms') }}
                <a href="mailto:unsubscribe@rock-process.com">
                    unsubscribe@rock-process.com
                </a>
            </p>
            <div class="inputCheckbox4">
                <label class="legalTerms" for="cookAcceptance">
                    <input
                        id="cookAcceptance"
                        v-model="d.cookAcceptance"
                        type="checkbox"
                    />
                    {{ $t('pfdMask.legalTermsQuestion') }}
                </label>
            </div>
            <input
                v-model="d.userMail"
                type="mail"
                placeholder="your-email@company.abc"
            />
            <button @click="register()">Register</button>
        </div>

        <div class="mobileMask">
            <h2>{{ $t('pfdMask.mobile.title1') }}</h2>
            <h3>{{ $t('pfdMask.mobile.title2') }}</h3>
        </div>
    </div>
</template>

<script setup>
import { stUser } from '@/store/index.js'
import { reactive, inject, defineEmits } from 'vue'
import { useCookies } from 'vue3-cookies'

const emit = defineEmits(['close'])
const socket = inject('socket')
const { cookies } = useCookies()

const storeUser = stUser()
const d = reactive({
    cookAcceptance: false,
    userMail: '',
})
function testMail(mail) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return mail.match(regex)
}
function saveElts() {
    try {
        cookies.set('cookAcceptance', d.cookAcceptance)
        cookies.set('mailCookies', d.userMail)
        storeUser.cookies = d.cookAcceptance
        storeUser.mail = d.userMail
        socket.emit('SEND_REVIEW', {
            user: d.userMail,
            text: 'Registration on webapp',
        })
    } catch (error) {
        console.info(error)
    }
}
function register() {
    if (!testMail(d.userMail) && d.userMail != 'p') {
        alert('Your mail seems to be not good')
        return
    }
    saveElts()
    emit('close')
}
</script>

<style lang="scss" scoped>
.mask-initialisation {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9998;
    width: 100%;

    min-height: -moz-available; /* WebKit-based browsers will ignore this. */
    min-height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    min-height: stretch;
    background-color: rgba(0, 0, 0, 0.2);

    //opacity:0.2;
    /*
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;

  margin: auto;
  margin-bottom: 0;
  margin-top: 0;
  */
    text-align: center;
    h2 {
        opacity: 0.9;
    }
    input[type='mail'] {
        width: 20rem;
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-align: center;
        border: none;
    }
    input[type='checkbox'] {
        font-size: 1.1rem;
        border-radius: 0.5rem;
        text-align: center;
        border: none;
    }
    button {
        font-size: 1.1rem;
        box-shadow: none;
        margin: 50px;
    }
    .legalTerms {
        font-size: 0.8rem;
    }
    .inputCheckbox4 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 700px) {
    .titreMask {
        display: none;
    }
}
@media (min-width: 700px) {
    .mobileMask {
        display: none;
    }
}
</style>
