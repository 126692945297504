<template>
    <g :id="d.id" class="iconeTools" :transform="valDeplacement">
        <use :xlink:href="refImageSvg"></use>
        <g v-if="d.id != 'menu'">
            <g v-for="flow in flowTable" v-show="d.prmShow.flow" :key="flow.id">
                <rect
                    class="txtBackground"
                    :x="flow.pos.x - (flow.pos.x < rectSize.w / 2 ? 15 : 2)"
                    :y="flow.pos.y - 5"
                    width="15"
                    height="6"
                />
                <text
                    class="flowTxt"
                    :x="flow.pos.x"
                    :y="flow.pos.y"
                    :text-anchor="
                        flow.pos.x <= rectSize.w / 2 ? 'end' : 'start'
                    "
                    >{{ flow.flow }}</text
                >
            </g>

            <g v-for="deck in deckTable" v-show="d.prmShow.mesh" :key="deck.id">
                <rect
                    class="txtBackground"
                    :x="deck.pos.x - 2"
                    :y="deck.pos.y - 5"
                    width="15"
                    height="6"
                />
                <text class="flowTxt" :x="deck.pos.x" :y="deck.pos.y">
                    #{{ deck.mesh }}</text
                >
            </g>
        </g>
        <rect
            class="selectclick"
            x="0"
            y="0"
            rx="3"
            ry="3"
            :width="rectSize.w"
            :height="rectSize.h"
            @dblclick="openModalPreference()"
            @mousedown="selectTools($event)"
            @save="dataEntre = $event"
            ><title>
                {{
                    t('toolsType.' + d.type) +
                    '\nid: ' +
                    d.id +
                    ' - Name: ' +
                    d.name
                }}
            </title></rect
        >
        <g v-if="d.id != 'menu'">
            <!-- Vignette taux de remplissage  -->
            <colorAlert :d="props.d.perfResults.load" :e="props.e" />
            <!-- Bouton supprimer -->
            <rect
                class="dilite"
                fill="red"
                x="0"
                :y="rectSize.h / 2 - 2.5"
                width="5"
                height="5"
                @click="deleteComponent()"
                ><title>{{ t('word.delete') }}</title></rect
            >

            <!-- Feed -->
            <g
                v-for="(connector, index) in d.con.filter(
                    (f) => f.type == 'feed'
                )"
                :key="connector.id"
            >
                <circle
                    :cx="connector.x"
                    :cy="connector.y"
                    :r="circleSize.inner"
                    class="inertCircleFeed"
                >
                    <title>
                        {{
                            `Cl: ${
                                Math.round(
                                    connector.material.intrinseque.clay * 10
                                ) / 10
                            }% \nMo: ${
                                Math.round(
                                    connector.material.intrinseque.moisture * 10
                                ) / 10
                            }%`
                        }}
                    </title>
                </circle>
                <circle
                    :id="'000' + index"
                    class="connect"
                    :cx="connector.x"
                    :cy="connector.y"
                    :r="circleSize.outer"
                />
            </g>
            <!-- Discharge || Deck -->
            <g
                v-for="connector in d.con.filter((f) => f.type != 'feed')"
                :key="connector.id"
            >
                <circle
                    :cx="connector.x"
                    :cy="connector.y"
                    :r="circleSize.inner"
                    :class="'inertCircleDischarge'"
                />
                <circle
                    :id="connector.id"
                    :class="'connect'"
                    :cx="connector.x"
                    :cy="connector.y"
                    :r="circleSize.outer"
                    @mousedown="
                        selectionConnecteur({
                            idTools: d.id,
                            idNoeud: connector.id,
                            x: connector.x,
                            y: connector.y,
                            isSide: connector.x > 28,
                        })
                    "
                >
                    <title>
                        {{
                            `Fl: ${transformFlow(connector.material.flow)} ${t(
                                unit.flow
                            )}\nCl: ${
                                Math.round(
                                    connector.material.intrinseque.clay * 100
                                ) / 100
                            }% \nMo: ${
                                Math.round(
                                    connector.material.intrinseque.moisture *
                                        100
                                ) / 100
                            }%`
                        }}
                    </title>
                </circle>
            </g>
        </g>
        <nameBox
            v-if="d.prmShow.name.show && d.name != '' && d.id != 'menu'"
            :d="d"
            @bougerbox="bougerBox($event, 'name')"
        />
        <technicalBox
            v-if="d.prmShow.technical.show && d.id != 'menu'"
            :d="d"
            @bougerbox="bougerBox($event, 'technical')"
        />
    </g>
</template>
<script setup>
'use strict'
//import ico_stdMethod from "./0_common/ico_stdMethods.js";
import nameBox from './detailsIcon/10_textBoxName.vue'
import technicalBox from './detailsIcon/10_textBoxTechnical.vue'
import colorAlert from './detailsIcon/10_colorAlert.vue'

import { ref, defineEmits, defineProps, computed } from 'vue'
import { stUnit } from '@/store/index.js'
import { useI18n } from 'vue-i18n'
import unitLib from '@/components/PFD/PFD_Modal/compCommon/unit.js'

const { unit, conv, transformFlow } = unitLib()

const { t } = useI18n()
const storeUnit = stUnit()
const props = defineProps({
    d: { type: Object, required: true },
    e: { type: Array, required: true },
})
const dataEntre = ref(props)
const emit = defineEmits([
    'show-modal',
    'create-tools',
    'create-connector',
    'move-tools',
    'delete-tools',
    'bouger-box',
])

function bougerBox(evt, bType) {
    emit('bouger-box', { evt: evt, codeElt: props.d.id, boxType: bType })
}

const refImageSvg = computed(function () {
    let res = props.d ? '#' + props.d.type : '#erreur'
    return res
})
const valDeplacement = computed(function () {
    return `translate(${props.d.gpos.x},${props.d.gpos.y})`
})

const rectSize = computed(() => {
    let res = { w: 40, h: 40 }
    switch (props.d.type) {
        case 'Add':
        case 'Split':
            res = { w: 20, h: 20 }
            break
        default:
            res = { w: 40, h: 40 }
    }
    return res
})
const flowTable = computed(() => {
    const res = []
    props.d.con.forEach((connector) => {
        let fl = connector.material.flow
        fl = storeUnit.iTon ? conv.mton_iton(fl) : fl
        fl = Math.round(fl)
        //const fl = connector.material.flow;
        const pos = { x: 0, y: connector.y + 2 }

        pos.x =
            connector.x >= rectSize.value.w / 2 + 3
                ? connector.x + 10
                : connector.x - 10

        pos.y = connector.y < 1 ? connector.y - 10 : pos.y
        pos.y = connector.y > rectSize.value.h - 1 ? connector.y + 10 : pos.y

        res.push({ id: connector.id, pos: pos, flow: fl })
    })
    return res
})
const deckTable = computed(() => {
    let res = props.d.con.map((j) => {
        if (j.type == 'deck') {
            const pos = { x: j.x - 40, y: j.y - 2 }
            const mesh = storeUnit.inch
                ? conv.mm_inch(j.deckTech.cutPoint)
                : j.deckTech.cutPoint
            return { id: j.id, pos: pos, mesh: mesh }
        }
    })
    res = res.filter((r) => r != undefined)
    return res
})

const circleSize = { inner: 2, outer: 5 }

function selectTools(event) {
    // Dans le cas ou il s'agit d'une sélection dans le menu
    if (String(props.d.id) === 'menu') {
        emit('create-tools', { evt: event, donne: props.d })
    } else {
        // On renvoie le code de l'élément à modifier
        emit('move-tools', { evt: event, codeElt: props.d.id })
    }
}
function selectionConnecteur(event) {
    // Dans le cas ou il existe déjà un fil sort la fonction
    if (props.d.con.find((x) => x.id == event.idNoeud).connectedto == '') {
        emit('create-connector', event)
    }
}

//Comportement modal
function deleteComponent() {
    emit('delete-tools', props.d)
}
function openModalPreference() {
    emit('show-modal', props.d)
}
</script>

<style scoped lang="scss">
.txt_techDetails {
    font-size: 4px;
    font-family: monospace;
}
text {
    fill: black;
}
.txtBackground {
    fill: white;
}
.flowTxt {
    font-family: monospace;
    font-size: 0.5em;
    fill: $fontColor_H !important;
}

.iconeTools {
    .selectclick {
        fill: #888;
        fill-opacity: 0;
        stroke-opacity: 1;
        cursor: pointer;
    }
    .selectclick:hover {
        fill-opacity: 0.5;
    }

    .connect {
        fill: #fff;
        opacity: 0;
        stroke: #888;
        stroke-width: 1;
        cursor: pointer;
        r: 5;
    }
    .connect:hover {
        opacity: 0.5;
    }
    .inertCircleDischarge {
        r: 2;
        stroke: black;
        stroke-width: 0.5;
        fill: orange;
    }
    .inertCircleFeed {
        r: 2;
        stroke: black;
        stroke-width: 0.5;
        fill: green;
    }
}
</style>
