<template lang="html">
    <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <!-- Barre de navigation du PFD -->
    <div class="barNavigationPFD">
        <div class="blockNavMaterial nav-blocks">
            <button
                :class="{ isSelected: menu['feed'] }"
                @click="majMenu('feed')"
            >
                {{ $t('PFD.menuTools.feed') }}
            </button>
            <button
                :class="{ isSelected: menu['screen'] }"
                @click="majMenu('screen')"
            >
                {{ $t('PFD.menuTools.screen') }}
            </button>
            <button
                :class="{ isSelected: menu['crusher'] }"
                @click="majMenu('crusher')"
            >
                {{ $t('PFD.menuTools.crusher') }}
            </button>
            <button
                :class="{ isSelected: menu['add'] }"
                @click="majMenu('add')"
            >
                {{ $t('PFD.menuTools.add') }}
            </button>
            <button
                :class="{ isSelected: menu['stock'] }"
                @click="majMenu('stock')"
            >
                {{ $t('PFD.menuTools.stock') }}
            </button>
            <button
                :class="{ isSelected: menu['others'] }"
                @click="majMenu('others')"
            >
                {{ $t('PFD.menuTools.others') }}
            </button>
        </div>
        <p>V1.0</p>
        <button @click="sendCalculation()">
            <div class="std-ButtonTxtIcon">
                <h5>{{ $t('PFD.btnSendCalc') }}</h5>
                <i class="material-icons">cached</i>
            </div>
        </button>
        <select @change="setNewPlant($event)">
            <option v-for="item in listOfPlant" :key="item" :value="item">
                {{ item }}
            </option>
        </select>

        <div class="blockZoom nav-blocks">
            <button
                :title="t('PFD.menuZoom.zoomOut')"
                @click="setOriginPFD(-2)"
            >
                <i class="material-icons">zoom_out</i>
            </button>
            <button
                :title="t('PFD.menuZoom.zoomReset')"
                @click="setOriginPFD(0)"
            >
                <i class="material-icons">center_focus_strong</i>
            </button>
            <button :title="t('PFD.menuZoom.zoomIn')" @click="setOriginPFD(2)">
                <i class="material-icons">zoom_in</i>
            </button>
        </div>
        <button @click="plantPrmModalShow = true">
            <div class="std-ButtonTxtIcon">
                <i class="material-icons"> view_headline </i>
            </div>
        </button>
    </div>
    <!-- PFD Graphique -->
    <div class="zone_edition">
        <div class="zone_graphique">
            <svg
                id="svg_id"
                xmlns="http://www.w3.org/2000/svg"
                @mousemove="sourisBouge($event)"
                @mousedown.alt="sourisDown($event, 'graph')"
                @mouseup="sourisUp($event, 'graph')"
            >
                <defsvg></defsvg>
                <g id="grille" :transform="transOrigin"></g>
                <g id="dessin" :transform="transOrigin">
                    <connectorStd
                        v-for="fil in plant.filTableau"
                        :key="fil.id"
                        :d="fil"
                        @movedotconnector="bougerDotConnector($event)"
                        @deleteconnector="deleteConnector($event)"
                    ></connectorStd>
                    <icoStd
                        v-for="item in plant.tools"
                        :key="item.id"
                        :d="item"
                        :e="
                            plant.erreur.filter(
                                (h) => h.idTools == item.id && h.type != 'E000'
                            )
                        "
                        @move-tools="
                            sourisDown($event.evt, 'tools', $event.codeElt)
                        "
                        @bouger-text="bougertext($event)"
                        @bouger-box="
                            sourisDown(
                                $event.evt,
                                'box',
                                $event.codeElt,
                                $event.boxType
                            )
                        "
                        @create-connector="createConnector($event)"
                        @delete-tools="deleteTools($event)"
                        @show-modal="showModal($event)"
                    ></icoStd>
                </g>
                <g id="menu" class="menu" :transform="transMenu">
                    <g v-for="(item, index) in listMenu" :key="item">
                        <text x="5" :y="index * 50 + 10" class="txt_menu_1">
                            {{ t('toolsType.' + item) }}
                        </text>
                        <icoStd
                            :d="toolsTemplate(item, 'menu')"
                            @create-tools="createTools($event)"
                        ></icoStd>
                    </g>
                </g>
                <cartouche
                    class="cssCartouche"
                    :d="plant.general"
                    :c="cookiesVal"
                ></cartouche>
            </svg>
            <maskInit v-if="maskShow" @close="maskShow = false" />
            <modalGen
                v-if="modalShow"
                :d="plant.tools[compteur.activeToolsIndex]"
                @close="modalShow = false"
                @save="saveIconeValeur($event)"
            ></modalGen>
        </div>
        <!-- Msg de calcul -->
        <transition name="fade">
            <msg
                v-if="msgContent.isShow"
                :donnee="msgContent.msgText"
                :couleur="msgContent.couleur"
            ></msg>
        </transition>
    </div>

    <modalPlantPrm
        v-if="plantPrmModalShow"
        :d="plant.general"
        @close="plantPrmModalShow = false"
    />
    <erreurBox
        v-if="plant.erreur.length > 0"
        :d="plant.erreur"
        class="cssErreurBox"
    />
</template>

<script setup>
//Appel des fonctions VueJS
import { reactive, onMounted, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
//Appel des composants
import defsvg from './PFD_Components/0_def-svg.vue'
import cartouche from './PFD_Components/3_cartouche.vue'
import icoStd from './PFD_Components/1_ico-std.vue'
import connectorStd from './PFD_Components/2_connector-std.vue'
import msg from './PFD_Window/0_msg-information.vue'
import erreurBox from './PFD_Window/0_window-erreur.vue'
//Appel des Methods locales
import MethodsSouris from './0_MethodsGeneral/1_METHODS-Souris.js'
import MethodsInit from './0_MethodsGeneral/1_METHODS-Initialisation.js'
import MethodsModification from './0_MethodsGeneral/1_METHODS-Modify Plant.js'
import SocketListener from './0_MethodsGeneral/3_MOUNTED-Socket.js'
import MountedFunction from './0_MethodsGeneral/3_MOUNTED-Others.js'
//Appel Computed
import ComputedElt from './0_MethodsGeneral/2_COMPUTED-Divers.js'

//Appel des modals
import modalGen from './PFD_Modal/0_generalModal.vue'
import modalPlantPrm from './PFD_Window/0_plant-parameter.vue'
import maskInit from './PFD_Window/0_mask-initialisation.vue'

//Autres
import cookies from './0_MethodsGeneral/5_GENERAL-Cookies.js'
import toolsTemplate from './0_MethodsGeneral/0_DONNEE-ToolsTemplate.js'

//
import donnee from './0_MethodsGeneral/9_DONNEE-PlantStandart.js'

//Définitions des valeurs Data ************************************************
const { t } = useI18n()
const socket = inject('socket')
const plant = reactive({
    general: {
        name: 'YourProject',
        author: 'ABC',
        date: '01.01.1922',
        company: 'YourCompany',
    },
    tools: [],
    filTableau: [],
    erreur: [],
})
const menu = reactive({
    feed: true,
    screen: false,
    crusher: false,
    add: false,
    stock: false,
    others: false,
})
const msgContent = reactive({
    isShow: false,
    msgText: 'here a msg',
    couleur: 'loading',
})
const { listMenu } = ComputedElt(menu)
const compteur = reactive({
    activeToolsIndex: 0,
    activeConnectorIndex: 0,
    compteurGeneral: -1,
})
const modalShow = ref(false)
const plantPrmModalShow = ref(false)
const maskShow = ref(true)
//Appel des fonctions spécifiques **********************************************
const { readCookies } = cookies()
const { dessinerGrille, getPlantFromCookies } = MethodsInit()
const {
    setOriginPFD,
    transOrigin,
    originPFD,
    transMenu,
    sourisUp,
    sourisDown,
    sourisBouge,
    bougerDotConnector,
} = MethodsSouris(plant, compteur)
const { deleteTools, deleteConnector } = MethodsModification(plant, compteur)
const cookiesVal = reactive(readCookies())
//Enregistrement des valeurs pour souris
const { listOfPlant, setNewPlant } = donnee(plant)
const { eventBeforePrint } = MountedFunction()
//Fonction maj des éléments
function majMenu(val) {
    for (const p in menu) {
        menu[p] = false
    }
    menu[val] = true
}
function createTools(evt) {
    const maxIdTools = plant.tools.reduce(
        (acc, b) => (b.id > acc ? b.id : acc),
        0
    )
    const maxIdCon = plant.filTableau.reduce(
        (acc, b) => (b.id > acc ? b.id : acc),
        0
    )
    compteur.compteurGeneral = maxIdCon > maxIdTools ? maxIdCon : maxIdTools + 1

    plant.tools.push(toolsTemplate(evt.donne.type, compteur.compteurGeneral))
    //let req = getIndexOfToolsId(compteur.compteurGeneral);
    //compteur.activeToolsIndex = req;
    //console.log("compteur.activeToolsIndex : " + compteur.activeToolsIndex);
    sourisDown(evt.evt, 'tools', compteur.compteurGeneral, '', true)
}
function createConnector(evt) {
    const maxIdTools = plant.tools.reduce(
        (acc, b) => (b.id > acc ? b.id : acc),
        0
    )
    const maxIdCon = plant.filTableau.reduce(
        (acc, b) => (b.id > acc ? b.id : acc),
        0
    )
    compteur.compteurGeneral = maxIdCon > maxIdTools ? maxIdCon : maxIdTools
    compteur.compteurGeneral++

    compteur.activeToolsIndex = getIndexOfToolsId(evt.idTools)

    const gposTools = plant.tools.find((t) => t.id == evt.idTools).gpos
    // Dans le cas ou on est sur un côté on met un certain objet sinon l'autre
    if (evt.isSide) {
        plant.filTableau.push({
            id: compteur.compteurGeneral,
            start: `${evt.idTools}@${evt.idNoeud}`,
            end: 'fin_du_start',
            type: 'dry',
            point: [
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 0 },
                { x: gposTools.x + evt.x + 50, y: gposTools.y + evt.y + 0 },
                { x: gposTools.x + evt.x + 50, y: gposTools.y + evt.y + 0 },
                { x: gposTools.x + evt.x + 50, y: gposTools.y + evt.y + 30 },
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 30 },
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 0 },
            ],
        })
    } else {
        plant.filTableau.push({
            id: compteur.compteurGeneral,
            start: `${evt.idTools}@${evt.idNoeud}`,
            end: 'fin_du_start',
            type: 'dry',
            point: [
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 0 },
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 50 },
                { x: gposTools.x + evt.x + 50, y: gposTools.y + evt.y + 50 },
                { x: gposTools.x + evt.x + 50, y: gposTools.y + evt.y + 30 },
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 15 },
                { x: gposTools.x + evt.x + 0, y: gposTools.y + evt.y + 0 },
            ],
        })
    }
    compteur.activeConnectorIndex = getIndexOfConnectorId(
        compteur.compteurGeneral
    )
    sourisDown(evt, 'connector', compteur.activeConnectorIndex)
}
function showModal(evt) {
    let req = getIndexOfToolsId(evt.id)
    compteur.activeToolsIndex = req
    modalShow.value = true
}
function saveIconeValeur(val) {
    let req = getIndexOfToolsId(val.id)

    //plant.tools[req].value = val;

    plant.tools[req].con = val.con
    plant.tools[req].technical = val.technical
    plant.tools[req].prmShow = val.prmShow
    plant.tools[req].name = val.name

    modalShow.value = false
}
//Fonction raccourci
function getIndexOfToolsId(id) {
    let res = plant.tools.map((e) => e.id == id).indexOf(true)
    return res
}
function getIndexOfConnectorId(id) {
    let res = plant.filTableau.map((e) => e.id == id).indexOf(true)
    return res
}
//Fonction envoyer calculation
function sendCalculation() {
    try {
        socket.emit('SEND_CALCULATION', plant)
        msgContent.msgText = t('PFD.msg.msgSendCalculation')
        msgContent.isShow = true
        msgContent.couleur = 'loading'

        setTimeout(() => {
            if (msgContent.isShow) {
                msgContent.msgText = t('PFD.msg.msgCalculationErrorSend')
                msgContent.couleur = 'negative'
                setTimeout(() => {
                    msgContent.isShow = false
                }, 800)
            }
        }, 10000)
    } catch (err) {
        console.error(err)
        msgContent.isShow = true
        msgContent.msgText = t('PFD.msg.msgCalculationError')
    }
}

onMounted(() => {
    dessinerGrille()
    SocketListener(socket, plant, msgContent, cookiesVal)
    getPlantFromCookies(socket)
    eventBeforePrint(originPFD, plant)
    if (cookiesVal.mail != null && screen.width > 500) maskShow.value = false
})
</script>

<style lang="scss" scoped>
.barNavigationPFD {
    * {
        @extend .user-select-none-pbi;
    }
    .isSelected {
        font-weight: bolder;
        letter-spacing: 0.2rem;
        background-color: $fontColor_L;
        font-weight: 900;
        //color: $fontColor_L;
        //border-color: $fontColor_H;
        transition: all 0.5s ease-in-out;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0.8rem;
}
.zone_edition .zone_graphique {
    border: 2px solid #37352f;
    border-radius: 5px;
    position: relative;
    //width: 100%;
    height: 80vh;
    margin: 5px;
    svg {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

@media (max-width: 700px) {
    .barNavigationPFD,
    #menu {
        display: none;
    }
}

@media screen {
    .cssCartouche {
        display: none;
    }
}
@media print {
    @page {
        size: A4 landscape;
        margin: 0;
    }
    html,
    body {
        width: 210mm;
        height: 297mm;
        margin: 1.6cm;
    }

    .menu,
    .barNavigationPFD,
    .cssErreurBox {
        display: none;
    }

    .zone_edition .zone_graphique {
        border: 1mm solid #37352f;
        border-radius: 5px;
        position: relative;
        height: 18cm;
        margin: 2mm;
        svg {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
