'use strict'
export default function () {
    function eventBeforePrint(originPFD, plant) {
        window.addEventListener('beforeprint', () => {
            //alert('Nous allons imprimer')

            const minX = Math.min(
                ...plant.tools.map((t) => {
                    const listPos = []
                    listPos.push(t.gpos.x)
                    if (t.prmShow.name.show)
                        listPos.push(t.prmShow.name.posX + t.gpos.x)
                    if (t.prmShow.technical.show)
                        listPos.push(t.prmShow.technical.posX + t.gpos.x)
                    console.table(listPos)
                    return Math.min(...listPos)
                })
            )
            const maxX = Math.max(
                ...plant.tools.map((t) => {
                    const listPos = []
                    listPos.push(t.gpos.x)
                    if (t.prmShow.name.show)
                        listPos.push(t.prmShow.name.posX + t.gpos.x)
                    if (t.prmShow.technical.show)
                        listPos.push(t.prmShow.technical.posX + t.gpos.x)
                    console.table(listPos)
                    return Math.max(...listPos)
                })
            )
            const minY = Math.min(
                ...plant.tools.map((t) => {
                    const listPos = []
                    listPos.push(t.gpos.y)
                    if (t.prmShow.name.show)
                        listPos.push(t.prmShow.name.posY + t.gpos.y)
                    if (t.prmShow.technical.show)
                        listPos.push(t.prmShow.technical.posY + t.gpos.y)
                    console.table(listPos)
                    return Math.min(...listPos)
                })
            )
            const maxY = Math.max(
                ...plant.tools.map((t) => {
                    const listPos = []
                    listPos.push(t.gpos.y)
                    if (t.prmShow.name.show)
                        listPos.push(t.prmShow.name.posY + t.gpos.y)
                    if (t.prmShow.technical.show)
                        listPos.push(t.prmShow.technical.posY + t.gpos.y)
                    console.table(listPos)
                    return Math.max(...listPos)
                })
            )
            console.log('Min X : ' + minX)
            console.log('Max X : ' + maxX)
            console.log('Min Y : ' + minY)
            console.log('Max Y : ' + maxY)
            console.log(originPFD)

            /*
          const MARGIN = { x: 20, y: 20 }
            const CARTOUCHE = { x: 240 / originPFD.sc, y: 100 / originPFD.sc }
            originPFD.x = -minX + MARGIN.x
            originPFD.y = -minY + MARGIN.y

            console.log(originPFD)
            originPFD.y =
                originPFD.x < CARTOUCHE.x && originPFD.y < CARTOUCHE.y
                    ? originPFD.y + CARTOUCHE.y
                    : originPFD.y

            console.log(originPFD)
            */
        })
    }

    return { eventBeforePrint }
}
