'use strict'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
import { useI18n } from 'vue-i18n'

export default function (socket, plant, msgContent) {
    const { t } = useI18n()

    socket.on('RECEIVE_CALCULATION', (d) => {
        console.info('RECEPTION CALCUL DEPUIS LE SERVEUR ')
        if (d) {
            const dataFromServer = JSON.parse(d)
            //console.table(dataFromServer.tools)
            //console.table(plant.tools)
            /*
            dataFromServer.tools.forEach((item) => {
                //console.log(JSON.stringify(item))
                //console.log(JSON.stringify(item.id))
                //console.log(plant.tools.map((d) => d.id))
                const index = plant.tools.map((d) => d.id).indexOf(item.id)
                //console.log({ index })
                if (index) {
                    for (const a in item) {
                        if (a != 'id') plant.tools[index][a] = item[a]
                    }
                }
                //plant.tools[index].con = item.con;
            })
            */
            plant.tools = dataFromServer.tools
            plant.erreur = dataFromServer.erreur

            ///console.table(plant.tools[1].con.map((o) => o.material.flow));
        }
        msgContent.msgText = t('PFD.msg.msgCalculationFinished')
        msgContent.couleur = 'positive'
        setTimeout(() => {
            msgContent.isShow = false
            console.log(msgContent)
        }, 800)
    })
    socket.on('ERREUR_SERVEUR', (err) => {
        //msgContent.isShow = true;
        msgContent.msgText = 'Serveur failed to made your calculation'
        msgContent.couleur = 'negative'
        console.log('RECEIVE ERROR FROM SERVER')
        console.log(err)
        setTimeout(() => {
            msgContent.isShow = false
        }, 800)
    })
    socket.on('SAVE_COOKIES_PLANT', (cookiePlantId) => {
        console.log('Reception identifiant plant | cookies')
        if (cookies.get('cookAcceptance') == 'true') {
            console.log('Plant id has been saved in cookies')
            cookies.set('plant', cookiePlantId)
        } else {
            console.log(
                "Plant id hasn't been saved, because cookies is not accepted"
            )
        }
    })
    socket.on('RECEIVE_COOKIES_PLANT', (p) => {
        console.log('RECEPTION DU PLANT')
        const dataFromServer = JSON.parse(p)
        try {
            plant.tools = dataFromServer.tools
            plant.filTableau = dataFromServer.filTableau
            plant.erreur = dataFromServer.erreur
        } catch (err) {
            console.log(err)
            //this.$cookies.remove("plantReference");
        }
    })
}
