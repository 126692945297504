<template lang="html">
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi">
                <div class="modal-header-pbi">
                    <h4 class="modal-title">Crushing parameter</h4>
                    <button type="button" class="close" @click="emit('close')">
                        &times;
                    </button>
                </div>
                <div class="modal-body-pbi">
                    <div class="general">
                        <div class="classificationPrm">
                            <h4 class="title">Classification parameter</h4>
                            <div class="bloc-input-pbi">
                                <label for="">&alpha; 1</label>
                                <input
                                    v-model="dataTemp.classificationRef.alpha1"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">&alpha; 2</label>
                                <input
                                    v-model="dataTemp.classificationRef.alpha2"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">d*</label>
                                <input
                                    v-model="dataTemp.classificationRef.dEtoile"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">n</label>
                                <input
                                    v-model="dataTemp.classificationRef.n"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                        </div>
                        <div class="crushingPrm">
                            <h4 class="title">Breakage matrix parameter</h4>
                            <div class="bloc-input-pbi">
                                <label for="">&phi;</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.phi"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">m</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.m"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">l</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.l"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer-pbi">
                    <button @click="$emit('close')">save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
const props = defineProps({ d: { type: Object, required: true } })
const dataTemp = ref(props.d)
const emit = defineEmits(['close'])
</script>

<style lang="scss" scoped>
.general {
    display: flex;
    align-items: top;
    justify-content: space-around;
    .title {
        font-weight: 600;
        margin: 0.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }
    div {
        padding: 0rem 1rem;

        &:first-child {
            border-right: solid 1px $ShadowColor;
        }
    }
}
</style>
