class courbeMethods {
    //Somme de deux produit
    static produitSomme(p1, p2) {
        let val = { flow: 0, courbe: [] }
        let test = this.courbeVerificationTamis(p1.courbe, p2.courbe)
        p1.courbe = test.c1
        p2.courbe = test.c2
        val.flow = p1.flow + p2.flow
        for (let l = 0; l < p1.courbe.length; l++) {
            val.courbe.push({
                x: p1.courbe[l].x,
                y:
                    (p1.courbe[l].y * p1.flow + p2.courbe[l].y * p2.flow) /
                    val.flow,
            })
        }
        val.courbe.sort((a, b) => a.x - b.x)
        return val
    }
    //Ajout d'un tamis dans une courbe
    static courbeAjoutTamis(d, m) {
        //d ==> Tableau granulométrique en passant cumulé
        //[{x: , y: },{x: , y: }...]
        //m ==> la valeur du tamis à ajouter
        m = parseFloat(m)
        let i = 0
        let val = 100

        if (m < d[d.length - 1].x) {
            while (d[i].x < m && i <= d.length) {
                i++
            }
        } else {
            i = d.length
        }

        if (i == d.length) {
            val = 100
        }

        if (i == 0) {
            let a = (0 - d[i].y) / (0 - d[i].x)
            let b = d[i].y - a * d[i].x
            val = a * m + b
        }
        if (i != d.length && i != 0) {
            let a = (d[i - 1].y - d[i].y) / (d[i - 1].x - d[i].x)
            let b = d[i].y - a * d[i].x
            val = a * m + b
        }
        if (d.find((e) => e.x == m) == undefined) {
            d.push({ x: m, y: val })
        }
        return d.sort((a, b) => a.x - b.x)
    }
    //Controle des tamis dans deux courbe
    static courbeVerificationTamis(c1, c2) {
        let f = 0
        //Safety
        c1 = c1.sort((a, b) => a.x - b.x)
        c2 = c2.sort((a, b) => a.x - b.x)

        while (f < c1.length || f < c2.length) {
            //Dans le cas
            if (f == c1.length) {
                c1 = this.courbeAjoutTamis(c1, c2[f].x)
            }
            if (f == c2.length) {
                c2 = this.courbeAjoutTamis(c2, c1[f].x)
            }

            if (c1[f].x > c2[f].x) {
                c1 = this.courbeAjoutTamis(c1, c2[f].x)
            }
            if (c1[f].x < c2[f].x) {
                c2 = this.courbeAjoutTamis(c2, c1[f].x)
            }
            //console.log(c2)
            f++
        }

        return { c1: c1, c2: c2 }
    }
    //Separation criblage
    static courbeScreen(c, flow, tamis) {
        let test = c.map((el) => el.x == tamis).indexOf(true)
        if (test == -1) {
            c = this.courbeAjoutTamis(c, tamis)
        }

        //Création d'une vue de la courbe en RP
        let c_RP = this.transform_PCtoRP(c)

        //Crétion des over et underflow
        //Transformation standart

        let c_UnderFlow_RP = c_RP.map((el) => {
            if (tamis <= el.x) {
                return { x: el.x, y: 0 }
            } else {
                return el
            }
        })
        let c_OverFlow_RP = c_RP.map((el) => {
            if (tamis > el.x) {
                return { x: el.x, y: 0 }
            } else {
                return el
            }
        })

        let sommeOver = c_OverFlow_RP.reduce((a, v) => a + v.y, 0)
        if (sommeOver == 0) {
            sommeOver = 1
        }

        let sommeUnder = c_UnderFlow_RP.reduce((a, v) => a + v.y, 0)
        c_OverFlow_RP = c_OverFlow_RP.map((el) => {
            return { x: el.x, y: (el.y * 100) / sommeOver }
        })
        c_UnderFlow_RP = c_UnderFlow_RP.map((el) => {
            return { x: el.x, y: (el.y * 100) / sommeUnder }
        })

        let c_OverFlow_PC = this.transform_RPtoPC(c_OverFlow_RP)
        let c_UnderFlow_PC = this.transform_RPtoPC(c_UnderFlow_RP)

        //Retourne un objet avec overflow et UnderFlow
        let pourcentCut = c.find((a) => a.x == tamis)
        let objetRetour = {
            overFlow: {
                flow: ((100 - pourcentCut.y) / 100) * flow,
                courbe: c_OverFlow_PC,
            },
            underFlow: {
                flow: (pourcentCut.y / 100) * flow,
                courbe: c_UnderFlow_PC,
            },
        }
        return objetRetour
    }
    //Transformation Passant cumulé en Refus Partiel
    static transform_PCtoRP(c) {
        //On trie la courbe dans le sens décroissant sur x
        c = c.sort((a, b) => b.x - a.x)
        //On ajoute le tamis 0
        if (c.find((a) => a.x == 0) == undefined) {
            c.push({ x: 0, y: 0 })
        }

        let c_RefusPartiel = []
        let res = 0
        for (let j = 0; j < c.length; j++) {
            if (j == 0 || c[j].y == 100) {
                res = 100 - c[j].y
            } else {
                res = c[j - 1].y - c[j].y
            }
            c_RefusPartiel.push({ x: c[j].x, y: res })
        }

        return c_RefusPartiel
    }
    //Transformation Refus partiel en Passant Cumulé
    static transform_RPtoPC(c) {
        c = c.sort((a, b) => a.x - b.x)
        let som = 0
        let res = []
        for (let f = 0; f < c.length; f++) {
            if (f != 0) {
                som += c[f - 1].y
                res.push({ x: c[f].x, y: som })
            } else {
                som +=
                    100 -
                    c.reduce((a, b) => {
                        return a + b.y
                    }, 0)
                if (som < 0) {
                    som = 0
                }
                res.push({ x: c[f].x, y: som })
            }
        }

        if (res[0].x == 0) {
            res.shift()
        }

        res = res.map((g) => {
            if (g.y > 100) {
                g.y = 100
            } else if (g.y < 0) {
                g.y = 0
            }
            return g
        })
        return res
    }
    //Fonction raport erreur
    static createErrorReporting(t) {
        //On retournera un tableau d'erreur sous la form
        //{idTools : , type :erreur: ,Description_erreur: }
        /*
  Erreur
  E000 --> Connexion inexistante
  */
        let res = []
        t.forEach((el) =>
            el.con.forEach((element) => {
                if (element.connectedto == '') {
                    res.push({
                        idTools: el.id,
                        type: 'E000',
                        Description_erreur:
                            'Object : ' +
                            el.id +
                            ' / dot id : ' +
                            element.id +
                            ' is not connected to any other element',
                    })
                }
            })
        )
        return res
    }
    //Fonction modifier les élements avals
    static modificationAval(tata, index, okStatus) {
        //renvoir l'objet modifier
        tata[index].con.forEach((item, i) => {
            if (item.type != 'feed' && item.status == okStatus) {
                let cleRecherche = item.connectedto

                let materiauxTransfert = JSON.stringify(
                    tata[index].con[i].material
                )
                tata.forEach((dest, i_dest) => {
                    if (tata[i_dest].id == cleRecherche.split('@')[0]) {
                        tata[i_dest].con.forEach((a, ia) => {
                            if (a.id == cleRecherche.split('@')[1]) {
                                tata[i_dest].con[ia].material =
                                    JSON.parse(materiauxTransfert)
                                tata[i_dest].con[ia].status = okStatus
                            }
                        })
                    }
                })
            }
        })
        console.log(tata)
        return tata
    }
    //Fonction de calcul des screens
    static screenCalculation(objScreen, okStatus) {
        let flow = 0
        let c = []
        let tamis = 20
        let majRes = {}

        objScreen.con.forEach((itemDot, i_dot) => {
            if (itemDot.type == 'feed') {
                flow = itemDot.material.flow
                c = itemDot.material.distribution
            }
            if (itemDot.type == 'deck') {
                tamis = itemDot.deckTech.cutPoint

                majRes = this.courbeScreen(c, flow, tamis)

                objScreen.con[i_dot].material.flow = majRes.overFlow.flow
                objScreen.con[i_dot].material.distribution =
                    majRes.overFlow.courbe
                objScreen.con[i_dot].status = okStatus
                flow = majRes.underFlow.flow
                c = majRes.underFlow.courbe
            }
            if (itemDot.type == 'discharge') {
                objScreen.con[i_dot].material.flow = flow
                objScreen.con[i_dot].material.distribution = c
                objScreen.con[i_dot].status = okStatus
            }
        })
        return objScreen
    }
    //Fonction recherche tamis pour un passant spécifique
    static getMeshForPassing(product, passing) {
        let p = product.sort((a, b) => b.x - a.x)
        let idSup = p.find((j) => j.y < passing)
        p = product.sort((a, b) => a.x - b.x)
        let idInf = p.find((j) => j.y > passing)
        passing = passing || 20

        if (idInf == undefined) {
            idInf = { x: 0.01, y: 0 }
        }
        if (idSup == undefined) {
            idSup = { x: 1000, y: 100 }
        }

        let a = (idSup.x - idInf.x) / (idSup.y - idInf.y)
        let b = idSup.x - a * idSup.y
        let res = a * passing + b

        return Math.round(res * 100) / 100
    }
    //Fonction recherche % de passant pour un tamis spécifique
    static getPassingForMesh(product, mesh) {
        mesh = mesh || 50
        product = this.courbeAjoutTamis(product, mesh)
        let res = product.find((g) => g.x == mesh).y
        return res
    }
}

module.exports = courbeMethods
