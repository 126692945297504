<template lang="html">
    <div class="headBarGranulo">
        <h4>{{ t('modalCrusher.dichargeDistribution') }}</h4>
        <slot name="btnCrCalc"></slot>
        <button :donne="courbe" @click="showModal.crDistribution = true">
            <div class="std-ButtonTxtIcon">
                <h5>
                    {{ t('word.editDistribution') }}
                </h5>
                <i class="material-icons">edit</i>
            </div>
        </button>
    </div>
    <tabDistri :d="courbe" />
    <modaldistribution
        v-if="showModal.crDistribution"
        :d="courbe"
        @close="showModal.crDistribution = false"
        @save="save($event)"
    ></modaldistribution>
</template>

<script setup>
import modaldistribution from './0_modaldistribution.vue'
import tabDistri from './0_tableauDistribution'

import { defineProps, ref, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
//import { stUnit } from "@/store/index.js";
//const storeUnit = stUnit();
const props = defineProps({ d: { type: Object, required: true } })
const dLocal = ref(props.d)
//const emit = defineEmits();
const showModal = reactive({
    crPrm: false,
    crDistribution: false,
})

const courbe = computed(() => {
    let res = dLocal.value.con[0].material.distribution
    if (dLocal.value.type != 'Feed') {
        res = dLocal.value.con[1].material.distribution
    }

    return res
})
function save(evt) {
    if (dLocal.value.type != 'Feed') {
        dLocal.value.con[1].material.distribution = JSON.parse(
            JSON.stringify(evt)
        )
    } else {
        dLocal.value.con[0].material.distribution = JSON.parse(
            JSON.stringify(evt)
        )
    }

    showModal.crDistribution = false
}
</script>

<style lang="scss" scoped>
.headBarGranulo {
    @extend .flex-c-sa;
    border-top: solid 1px $primary;
}
</style>
