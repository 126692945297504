<template lang="html">
    <g :transform="tranformTxt">
        <text class="t1" text-anchor="middle" x="25" y="12">
            {{ d.name.substring(0, 8) }}
        </text>
        <rect
            class="titre"
            x="0"
            y="0"
            rx="3"
            ry="3"
            width="50"
            height="16"
            @mousedown="fctBougerBox($event)"
        />
    </g>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['bougerbox'])
const tranformTxt = computed(() => {
    return `translate(${props.d.prmShow.name.posX},${props.d.prmShow.name.posY})`
})
function fctBougerBox(evt) {
    console.log(evt)
    emit('bougerbox', evt)
}
</script>

<style lang="scss" scoped>
g {
    @extend .styleBoxPFD;
}
</style>
