<template>
    <div class="section-top">
        <div class="dataCrusher">
            <div class="blocData">
                <!-- Brand -->
                <div class="bloc-input-pbi">
                    <label>{{ t('word.brand') }}</label>
                    <input
                        v-model="dLocal.technical.brand"
                        list="brand"
                        type="text"
                        @change="launchSocket()"
                    />
                    <datalist id="brand">
                        <option
                            v-for="item in brandConeCrusherList"
                            :key="item"
                        >
                            {{ item }}
                        </option>
                    </datalist>
                </div>
                <!-- Reference -->
                <div class="bloc-input-pbi">
                    <label>{{ t('word.ref') }}</label>
                    <input
                        v-model="dLocal.technical.ref"
                        list="ref"
                        type="text"
                    />
                    <datalist id="ref">
                        <option
                            v-for="item in listTechnical.listCrusher"
                            :key="item"
                        >
                            {{ item }}
                        </option>
                    </datalist>
                </div>
            </div>
            <div class="blocData">
                <div class="bloc-input-pbi">
                    <label>{{ t('word.chamber') }}</label>
                    <input
                        v-if="!crusherExist"
                        v-model="dLocal.technical.chamber"
                        type="text"
                    />
                    <select
                        v-if="crusherExist"
                        v-model="dLocal.technical.chamber"
                        class="selectText"
                        name=""
                    >
                        <option
                            v-for="item in listTechnical.listChamber"
                            :key="item"
                            :value="item"
                        >
                            {{ item }}
                        </option>
                    </select>
                </div>

                <div class="bloc-input-pbi">
                    <label>{{ t('word.mantle') }}</label>
                    <input
                        v-if="!crusherExist"
                        v-model="dLocal.technical.mantle"
                        type="text"
                    />

                    <select
                        v-if="crusherExist"
                        v-model="dLocal.technical.mantle"
                        class="selectText"
                    >
                        <option
                            v-for="item in listTechnical.listMantle"
                            :key="item"
                            :value="item"
                        >
                            {{ item }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="blocData">
                <div class="bloc-input-pbi">
                    <label>{{ t('word.throw') }}</label>
                    <input
                        v-if="!crusherExist"
                        v-model="dLocal.technical.stroke"
                        type="number"
                        step="any"
                    />

                    <select
                        v-if="crusherExist"
                        v-model="dLocal.technical.stroke"
                        class="selectNumber"
                    >
                        <option
                            v-for="item in listTechnical.listStroke"
                            :key="item"
                            :value="item"
                        >
                            {{ item }}
                        </option>
                    </select>

                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
                <div class="bloc-input-pbi">
                    <label>{{ t('word.css') }}</label>
                    <input
                        v-model="dLocal.technical.css"
                        type="number"
                        step="any"
                        :min="listTechnical.cssMin"
                        :max="listTechnical.cssMax"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
            </div>
        </div>
    </div>
    <p v-if="!crusherExist" class="msgCrusherDoesNotExist">
        {{ t('modalCrusher.crusherNotFind') }}
    </p>
    <formCourbeCrusher :d="dLocal">
        <template #btnCrCalc>
            <btnCalcCrusher
                :d="dLocal"
                :c-exist="crusherExist"
                @open-modal-prm="showTechPrm = true"
            />
        </template>
    </formCourbeCrusher>
    <modalfooter @save="save()"></modalfooter>
    <modalcrushingparameter
        v-if="showTechPrm"
        :d="dLocal.technical"
        @close="showTechPrm = false"
        @save="showTechPrm = false"
    />
</template>

<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
//import modaldistribution from "./0_modaldistribution.vue";
import formCourbeCrusher from './compCommon/0_formCourbe.vue'
import btnCalcCrusher from './compCrusher/0_btnCalcModeCrushing'
import modalcrushingparameter from './compCrusher/2_modalConeTechnicalParameter.vue'

//import conv from "@/components/convert.js";
import {
    defineEmits,
    defineProps,
    ref,
    inject,
    computed,
    onMounted,
    //  reactive,
} from 'vue'
import { useI18n } from 'vue-i18n'
const socket = inject('socket')

import unitLib from './compCommon/unit.js'
const { unit, conv, storeUnit } = unitLib()

//import { stUnit } from "@/store/index.js";
//const storeUnit = stUnit();

const { t } = useI18n()
const objStdTechnicalValue = {
    maxFeedSize: 800,
    classificationRef: {
        alpha1: 0.6,
        alpha2: 2.0,
        dEtoile: 0,
        n: 2,
    },
    breakageMatrixRef: {
        phi: 0.2,
        m: 0.5,
        l: 2.5,
    },
    flowRef: {
        CSS_min: 10,
        CSS_max: 35,
        refFlow_min: 46,
        refFlow_max: 80,
    },
}

const props = defineProps({ d: { type: Object, required: true } })
const dLocal = ref(props.d)

const emit = defineEmits(['save'])
const brandConeCrusherList = ['RP', 'Sandvik', 'Metso']
const showTechPrm = ref(false)

let detailsCrusher = ref([])

function launchSocket() {
    detailsCrusher.value = []
    if (brandConeCrusherList.includes(dLocal.value.technical.brand)) {
        const a = dLocal.value.technical.brand
        try {
            socket.emit('GETINFOMACHINE', {
                type: 'coneCrusher',
                brand: a,
            })
        } catch (error) {
            console.info(error)
        }
    }
}
function majElts(f, listStroke = []) {
    dLocal.value.technical.maxFeedSize = f.maxFeedSize
    dLocal.value.technical.classificationRef = f.classificationRef
    dLocal.value.technical.breakageMatrixRef = f.breakageMatrixRef
    dLocal.value.technical.flowRef = f.flowRef
    dLocal.value.technical.isExist = crusherExist.value
    //a*x + B
    if (crusherExist.value) {
        if (
            !listStroke.includes(dLocal.value.technical.stroke) &&
            listStroke.length > 0
        ) {
            dLocal.value.technical.stroke = listStroke[0]
        }
        if (
            dLocal.value.technical.css > f.flowRef.CSS_max ||
            dLocal.value.technical.css < f.flowRef.CSS_min
        ) {
            dLocal.value.technical.css = f.flowRef.CSS_min
        }

        const a =
            (f.flowRef.refFlow_max - f.flowRef.refFlow_min) /
            (f.flowRef.CSS_max - f.flowRef.CSS_min)
        const b = f.flowRef.refFlow_max - a * f.flowRef.CSS_max
        dLocal.value.technical.flowReference =
            a * dLocal.value.technical.css + b
    }
}
function initialUnitTranslation() {
    if (storeUnit.inch) {
        dLocal.value.technical.css = conv.mm_inch(dLocal.value.technical.css)
        dLocal.value.technical.stroke = conv.mm_inch(
            dLocal.value.technical.stroke
        )
    }
}
function save() {
    if (storeUnit.inch) {
        dLocal.value.technical.css = conv.inch_mm(dLocal.value.technical.css)
        dLocal.value.technical.stroke = conv.inch_mm(
            dLocal.value.technical.stroke
        )
    }
    emit('save', dLocal.value)
}
/*
const unit = computed(() => {
  const res = {
    length: "unit.mm",
    mass: "unit.mt",
    flow: "unit.mth",
  };

  res.length = storeUnit.inch ? "unit.inch" : "unit.mm";
  res.mass = storeUnit.inch ? "unit.it" : "unit.mt";
  res.flow = storeUnit.inch ? "unit.ith" : "unit.mth";

  return res;
});
*/
const listTechnical = computed(() => {
    const res = {}
    const a = detailsCrusher.value ?? []
    const listCrusher = a.map((f) => f.ref)

    res.listCrusher = listCrusher.filter(
        (g, index) => listCrusher.indexOf(g) == index
    )

    // Recherche liste des Chambre
    const crusherOfRef = a.filter(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref
    )
    if (crusherOfRef != undefined) {
        res.listChamber = crusherOfRef
            .map((u) => u.chamber)
            .filter(
                (t, index) =>
                    crusherOfRef.map((u) => u.chamber).indexOf(t) == index
            )
    } else {
        res.listChamber = []
    }

    // Recherche listes des Mantle
    const listCrusherForMantle = a.filter(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref &&
            u.chamber == dLocal.value.technical.chamber
    )
    if (listCrusherForMantle != undefined) {
        res.listMantle = listCrusherForMantle
            .map((u) => u.mantle)
            .filter(
                (t, index) =>
                    listCrusherForMantle.map((u) => u.mantle).indexOf(t) ==
                    index
            )
    } else {
        res.listMantle = []
    }
    // Recherche listes des Stroke
    const listCrusherForStroke = a.filter(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref &&
            u.chamber == dLocal.value.technical.chamber &&
            u.mantle == dLocal.value.technical.mantle
    )
    if (listCrusherForStroke != undefined) {
        res.listStroke = listCrusherForStroke
            .map((u) => u.stroke)
            .filter(
                (t, index) =>
                    listCrusherForStroke.map((u) => u.stroke).indexOf(t) ==
                    index
            )
        if (listCrusherForStroke[0]) {
            majElts(listCrusherForStroke[0], res.listStroke)
        } else {
            majElts(objStdTechnicalValue, [])
        }
    } else {
        res.listStroke = []
    }
    // Recherche Min-Max CSS
    const listCrusherForCSS = a.filter(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref &&
            u.chamber == dLocal.value.technical.chamber &&
            u.mantle == dLocal.value.technical.mantle &&
            u.stroke == dLocal.value.technical.stroke
    )
    if (listCrusherForCSS.length > 0) {
        res.cssMin = listCrusherForCSS[0].flowRef.CSS_min || 0
        res.cssMax = listCrusherForCSS[0].flowRef.CSS_max || 999
        majElts(objStdTechnicalValue)
    } else {
        res.cssMin = 0
        res.cssMax = 999
    }

    //Transformation en inch si besoin
    if (storeUnit.inch) {
        res.listStroke.map((h) => conv.mm_inch(h))
        res.cssMin = conv.mm_inch(res.cssMin)
        res.cssMax = conv.mm_inch(res.cssMax)
    }

    return res
})
const crusherExist = computed(() => {
    const a = detailsCrusher.value ?? []
    let res = false
    const rechercheCrusher = a.find(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref
    )

    if (undefined != rechercheCrusher) {
        res = true
    }
    return res
})
onMounted(() => {
    socket.on('SENDINFOMACHINE', (d) => {
        console.info('**************************************** SOCKET ON ')
        console.info('RECEPTION INFO MACHINE DEPUIS LE SERVEUR ')
        // let n = JSON.parse(d)
        detailsCrusher.value = JSON.parse(d)
    })
    launchSocket(dLocal.value.technical.brand)

    initialUnitTranslation()
})
</script>
<style scoped lang="scss">
.dataCrusher {
    padding: 0.5rem;
    @extend .flex-c-sb;
    .blocData {
        border-left: solid 1px $ShadowColor;
        padding: 0.5rem;
    }
    .blocData:first-child {
        border: none;
    }
}
.msgCrusherDoesNotExist {
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
}
</style>
