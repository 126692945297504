<template lang="html">
    <g v-show="afficher">
        <circle
            cx="30"
            cy="0"
            r="5"
            :class="{
                overTheLimit: classIsValid.overTheLimit,
                onTheLimit: classIsValid.onTheLimit,
                underTheLimit: classIsValid.underTheLimit,
            }"
            ><title>
                {{
                    Math.round(props.d * 100) +
                    '%\n' +
                    e.map((r) => r.Description_erreur).join('\n')
                }}
            </title></circle
        >
    </g>
</template>

<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
    d: { type: Number, required: true, default: 1 },
    e: { type: Array, required: true },
})
const afficher = computed(() => props.d != 1 || props.e.length > 0)
const classIsValid = computed(() => {
    const SEUIL_OVERTHELIMIT = 1
    const SEUIL_UNDERTHELIMIT = 0.95
    const SEUIL_TOOLOW = 0.7

    const res = {
        overTheLimit: false,
        onTheLimit: false,
        underTheLimit: false,
    }

    if (props.e.length == 0) {
        if (props.d >= SEUIL_OVERTHELIMIT) {
            res.overTheLimit = true
        }
        if (props.d > SEUIL_UNDERTHELIMIT && props.d < SEUIL_OVERTHELIMIT) {
            res.onTheLimit = true
        }
        if (props.d < SEUIL_UNDERTHELIMIT && props.d > SEUIL_TOOLOW) {
            res.underTheLimit = true
        }
        if (props.d <= SEUIL_TOOLOW) {
            res.onTheLimit = true
        }
    } else {
        res.overTheLimit = true
    }

    return res
})
</script>

<style lang="scss" scoped>
.overTheLimit {
    fill: $infoColor-bg-negative;
}
.onTheLimit {
    fill: orange;
}
.underTheLimit {
    fill: $infoColor-bg-positive;
}
</style>
