<template lang="html">
    <div class="tableau">
        <table>
            <tr>
                <td class="titreGranulo">
                    {{ t('word.mesh') }} [{{ t(unit.length) }}]
                </td>
                <td v-for="item in courbeAff" :key="item.x">
                    {{ item.x }}
                </td>
            </tr>
            <tr>
                <td class="titreGranulo">Passing [%]</td>
                <td v-for="item in courbeAff" :key="item.x">
                    {{ parseInt(item.y * 10) / 10 }}
                </td>
            </tr>
        </table>
    </div>
</template>

<script setup>
import conv from '@/components/convert.js'
import { defineProps, computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { stUnit } from '@/store/index.js'
const storeUnit = stUnit()

const props = defineProps({ d: { type: Array, required: true } })

const courbeAff = computed(() => {
    const cInit = JSON.parse(JSON.stringify(props.d))
    let res = cInit

    if (storeUnit.inch) {
        res = cInit.map((o) => {
            o.x = conv.mm_inch(o.x)
            return o
        })
    }

    return res
})

const unit = computed(() => {
    const res = {
        length: 'unit.mm',
        mass: 'unit.mt',
        flow: 'unit.mth',
    }

    res.length = storeUnit.inch ? 'unit.inch' : 'unit.mm'
    res.mass = storeUnit.inch ? 'unit.it' : 'unit.mt'
    res.flow = storeUnit.inch ? 'unit.ith' : 'unit.mth'

    return res
})
</script>

<style lang="css" scoped></style>
