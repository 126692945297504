'use strict'
export default function () {
    const dataMaterial = [
        {
            rockName: 'Amphibolite',
            rockOrigin: 'Igneous',
            workIndex: '16',
            densityCompact: '3',
            densityBulk: '1.8',
            abrasionIndex: '0.4',
            compressiveStrength: '',
        },
        {
            rockName: 'Andesite',
            rockOrigin: 'Igneous',
            workIndex: '17',
            densityCompact: '2.7',
            densityBulk: '1.6',
            abrasionIndex: '0.35',
            compressiveStrength: '235',
        },
        {
            rockName: 'Basalt',
            rockOrigin: 'Igneous',
            workIndex: '20',
            densityCompact: '3',
            densityBulk: '1.8',
            abrasionIndex: '0.2',
            compressiveStrength: '350',
        },
        {
            rockName: 'Diabase',
            rockOrigin: 'Igneous',
            workIndex: '18',
            densityCompact: '2.85',
            densityBulk: '1.7',
            abrasionIndex: '0.25',
            compressiveStrength: '300',
        },
        {
            rockName: 'Diorite',
            rockOrigin: 'Igneous',
            workIndex: '19',
            densityCompact: '2.85',
            densityBulk: '1.7',
            abrasionIndex: '0.25',
            compressiveStrength: '235',
        },
        {
            rockName: 'Dolomite',
            rockOrigin: 'Sedimentary',
            workIndex: '13',
            densityCompact: '2.65',
            densityBulk: '1.6',
            abrasionIndex: '0.25',
            compressiveStrength: '125',
        },
        {
            rockName: 'Gabbro',
            rockOrigin: 'Igneous',
            workIndex: '22',
            densityCompact: '3',
            densityBulk: '1.8',
            abrasionIndex: '0.025',
            compressiveStrength: '310',
        },
        {
            rockName: 'Gneiss',
            rockOrigin: 'Metamorphic',
            workIndex: '16',
            densityCompact: '2.85',
            densityBulk: '1.7',
            abrasionIndex: '0.5',
            compressiveStrength: '250',
        },
        {
            rockName: 'Granite',
            rockOrigin: 'Igneous',
            workIndex: '16',
            densityCompact: '2.7',
            densityBulk: '1.6',
            abrasionIndex: '0.45',
            compressiveStrength: '250',
        },
        {
            rockName: 'Graywacke',
            rockOrigin: 'Sedimentary',
            workIndex: '17',
            densityCompact: '2.7',
            densityBulk: '1.6',
            abrasionIndex: '0.5',
            compressiveStrength: '225',
        },
        {
            rockName: 'Gristone',
            rockOrigin: 'Sedimentary',
            workIndex: '16',
            densityCompact: '2.7',
            densityBulk: '1.8',
            abrasionIndex: '0.25',
            compressiveStrength: '',
        },
        {
            rockName: 'Homfels',
            rockOrigin: 'Metamorphic',
            workIndex: '18',
            densityCompact: '2.9',
            densityBulk: '1.8',
            abrasionIndex: '0.2',
            compressiveStrength: '225',
        },
        {
            rockName: 'LimestoneHard',
            rockOrigin: 'Sedimentary',
            workIndex: '13',
            densityCompact: '2.5',
            densityBulk: '1.5',
            abrasionIndex: '0.4',
            compressiveStrength: '130',
        },
        {
            rockName: 'LimestoneSoft',
            rockOrigin: 'Sedimentary',
            workIndex: '7',
            densityCompact: '2.4',
            densityBulk: '1.5',
            abrasionIndex: '0.1',
            compressiveStrength: '130',
        },
        {
            rockName: 'Marble',
            rockOrigin: 'Metamorphic',
            workIndex: '12',
            densityCompact: '2.5',
            densityBulk: '1.5',
            abrasionIndex: '0.1',
            compressiveStrength: '130',
        },
        {
            rockName: 'Porphyry',
            rockOrigin: 'Igneous',
            workIndex: '18',
            densityCompact: '2.85',
            densityBulk: '1.7',
            abrasionIndex: '0.55',
            compressiveStrength: '240',
        },
        {
            rockName: 'Quartzite',
            rockOrigin: 'Metamorphic',
            workIndex: '15',
            densityCompact: '2.65',
            densityBulk: '1.6',
            abrasionIndex: '0.8',
            compressiveStrength: '225',
        },
        {
            rockName: 'Sandstone',
            rockOrigin: 'Sedimentary',
            workIndex: '11',
            densityCompact: '2.8',
            densityBulk: '1.7',
            abrasionIndex: '0.5',
            compressiveStrength: '105',
        },
        {
            rockName: 'Magnetite',
            rockOrigin: 'Iron Ore',
            workIndex: '8',
            densityCompact: '4.5',
            densityBulk: '2.7',
            abrasionIndex: '0.4',
            compressiveStrength: '',
        },
        {
            rockName: 'Hematite',
            rockOrigin: 'Iron Ore',
            workIndex: '11',
            densityCompact: '4.5',
            densityBulk: '2.7',
            abrasionIndex: '0.65',
            compressiveStrength: '',
        },
    ]
    const dataDistribution = [
        {
            label: 'Blasted rock fine. <600mm',
            plot: [
                { x: 0.063, y: 1 },
                { x: 0.25, y: 2 },
                { x: 1, y: 3 },
                { x: 2, y: 5 },
                { x: 4, y: 6 },
                { x: 8, y: 7.5 },
                { x: 12, y: 8.6 },
                { x: 16, y: 9.4 },
                { x: 20, y: 10.4 },
                { x: 25, y: 12 },
                { x: 31.5, y: 14 },
                { x: 40, y: 17 },
                { x: 50, y: 20 },
                { x: 63, y: 23 },
                { x: 90, y: 32 },
                { x: 125, y: 43 },
                { x: 200, y: 60 },
                { x: 300, y: 77 },
                { x: 500, y: 95 },
                { x: 600, y: 100 },
            ],
        },
        {
            label: 'Blasted rock ave. <750mm',
            plot: [
                { x: 0.063, y: 0.8 },
                { x: 0.25, y: 1.5 },
                { x: 1, y: 3.2 },
                { x: 2, y: 4 },
                { x: 4, y: 4.5 },
                { x: 8, y: 6 },
                { x: 12, y: 7 },
                { x: 16, y: 7.5 },
                { x: 20, y: 8.3 },
                { x: 25, y: 9.5 },
                { x: 31.5, y: 11.5 },
                { x: 40, y: 14 },
                { x: 50, y: 17 },
                { x: 63, y: 19.5 },
                { x: 90, y: 27 },
                { x: 125, y: 37 },
                { x: 200, y: 53 },
                { x: 300, y: 69 },
                { x: 500, y: 90 },
                { x: 750, y: 100 },
            ],
        },
        {
            label: 'Blasted rock coarse. <900mm',
            plot: [
                { x: 0.063, y: 0.6 },
                { x: 0.25, y: 1.2 },
                { x: 1, y: 2.3 },
                { x: 2, y: 2.8 },
                { x: 4, y: 3.4 },
                { x: 8, y: 4.5 },
                { x: 12, y: 5.3 },
                { x: 16, y: 5.8 },
                { x: 20, y: 6.4 },
                { x: 25, y: 7.3 },
                { x: 31.5, y: 9.3 },
                { x: 40, y: 11.6 },
                { x: 50, y: 14 },
                { x: 63, y: 16.3 },
                { x: 90, y: 22.6 },
                { x: 125, y: 31.6 },
                { x: 200, y: 46.6 },
                { x: 300, y: 62.3 },
                { x: 500, y: 85 },
                { x: 900, y: 100 },
            ],
        },
        {
            label: 'Blasted rock extra coarse. <1000mm',
            plot: [
                { x: 0.063, y: 0.4 },
                { x: 0.25, y: 0.8 },
                { x: 1, y: 1.5 },
                { x: 2, y: 1.7 },
                { x: 4, y: 2.2 },
                { x: 8, y: 3 },
                { x: 12, y: 3.7 },
                { x: 16, y: 4.1 },
                { x: 20, y: 4.6 },
                { x: 25, y: 5 },
                { x: 31.5, y: 7 },
                { x: 40, y: 9 },
                { x: 50, y: 11 },
                { x: 63, y: 13 },
                { x: 90, y: 18 },
                { x: 125, y: 26 },
                { x: 200, y: 40 },
                { x: 300, y: 55 },
                { x: 500, y: 80 },
                { x: 1000, y: 100 },
            ],
        },
        {
            label: 'Gravel fine',
            plot: [
                { x: 0.063, y: 4.05 },
                { x: 0.25, y: 13.44 },
                { x: 1, y: 32 },
                { x: 2, y: 45 },
                { x: 4, y: 56 },
                { x: 8, y: 69 },
                { x: 12, y: 76 },
                { x: 16, y: 82 },
                { x: 20, y: 86 },
                { x: 25, y: 88 },
                { x: 31.5, y: 90 },
                { x: 40, y: 92.5 },
                { x: 50, y: 94 },
                { x: 63, y: 95 },
                { x: 90, y: 99 },
                { x: 100, y: 100 },
            ],
        },
        {
            label: 'Gravel medium',
            plot: [
                { x: 0.063, y: 2.2 },
                { x: 0.25, y: 7.7 },
                { x: 1, y: 22 },
                { x: 2, y: 33 },
                { x: 4, y: 43 },
                { x: 8, y: 55 },
                { x: 12, y: 62 },
                { x: 16, y: 67 },
                { x: 20, y: 72 },
                { x: 25, y: 76 },
                { x: 31.5, y: 80 },
                { x: 40, y: 82.5 },
                { x: 50, y: 86 },
                { x: 63, y: 88 },
                { x: 90, y: 92 },
                { x: 125, y: 96 },
                { x: 200, y: 100 },
            ],
        },
        {
            label: 'Gravel coarse',
            plot: [
                { x: 0.063, y: 1 },
                { x: 0.25, y: 3 },
                { x: 1, y: 15 },
                { x: 2, y: 23 },
                { x: 4, y: 33 },
                { x: 8, y: 43 },
                { x: 12, y: 50 },
                { x: 16, y: 54 },
                { x: 20, y: 57 },
                { x: 25, y: 60.5 },
                { x: 31.5, y: 64.5 },
                { x: 40, y: 67.7 },
                { x: 50, y: 71.5 },
                { x: 63, y: 74 },
                { x: 90, y: 80 },
                { x: 125, y: 86 },
                { x: 200, y: 94 },
                { x: 300, y: 100 },
            ],
        },
        {
            label: 'Gravel Extra coarse',
            plot: [
                { x: 0.063, y: 0.2 },
                { x: 0.25, y: 2.4 },
                { x: 1, y: 7 },
                { x: 2, y: 15 },
                { x: 4, y: 24 },
                { x: 8, y: 33 },
                { x: 12, y: 38 },
                { x: 16, y: 42 },
                { x: 20, y: 47 },
                { x: 25, y: 50 },
                { x: 31.5, y: 53 },
                { x: 40, y: 57 },
                { x: 50, y: 60 },
                { x: 63, y: 63 },
                { x: 90, y: 70 },
                { x: 125, y: 76 },
                { x: 200, y: 84 },
                { x: 300, y: 92 },
                { x: 500, y: 98 },
                { x: 600, y: 100 },
            ],
        },
        {
            label: 'Blasted limestone fine <600 mm',
            plot: [
                { x: 0.063, y: 0.1 },
                { x: 0.25, y: 0.9 },
                { x: 1, y: 2.2 },
                { x: 2, y: 4 },
                { x: 4, y: 7.7 },
                { x: 8, y: 15 },
                { x: 12, y: 20 },
                { x: 16, y: 23 },
                { x: 20, y: 27 },
                { x: 25, y: 30 },
                { x: 31.5, y: 32 },
                { x: 40, y: 35 },
                { x: 50, y: 38 },
                { x: 63, y: 41 },
                { x: 90, y: 49 },
                { x: 125, y: 56 },
                { x: 200, y: 68 },
                { x: 300, y: 80.4 },
                { x: 450, y: 100 },
            ],
        },
        {
            label: 'Blasted limestone medium <600 mm',
            plot: [
                { x: 0.063, y: 0.05 },
                { x: 0.25, y: 0.3 },
                { x: 1, y: 1.2 },
                { x: 2, y: 2.5 },
                { x: 4, y: 5 },
                { x: 8, y: 10 },
                { x: 12, y: 13.5 },
                { x: 16, y: 15 },
                { x: 20, y: 17.5 },
                { x: 25, y: 20 },
                { x: 31.5, y: 22 },
                { x: 40, y: 24.5 },
                { x: 50, y: 26 },
                { x: 63, y: 30 },
                { x: 90, y: 37.72 },
                { x: 125, y: 45 },
                { x: 200, y: 56 },
                { x: 300, y: 68.4 },
                { x: 500, y: 86 },
                { x: 600, y: 100 },
            ],
        },
        {
            label: 'Blasted limestone coarse <900 mm',
            plot: [
                { x: 0.063, y: 0.01 },
                { x: 0.25, y: 0.07 },
                { x: 1, y: 0.4 },
                { x: 2, y: 0.9 },
                { x: 4, y: 1.9 },
                { x: 8, y: 4.15 },
                { x: 12, y: 6.8 },
                { x: 16, y: 9.3 },
                { x: 20, y: 11.5 },
                { x: 25, y: 13.8 },
                { x: 31.5, y: 15.55 },
                { x: 40, y: 17.3 },
                { x: 50, y: 19 },
                { x: 63, y: 21 },
                { x: 90, y: 27 },
                { x: 125, y: 34 },
                { x: 200, y: 45 },
                { x: 300, y: 57 },
                { x: 500, y: 70 },
                { x: 900, y: 100 },
            ],
        },
    ]

    return { dataMaterial, dataDistribution }
}
