'use strict'
import { computed } from 'vue'
import { stUnit } from '@/store/index.js'
import conv from '@/components/convert.js'

export default function () {
    const storeUnit = stUnit()
    const unit = computed(() => {
        const res = {
            length: 'unit.mm',
            mass: 'unit.mt',
            flow: 'unit.mth',
        }

        res.length = storeUnit.inch ? 'unit.inch' : 'unit.mm'
        res.mass = storeUnit.iTon ? 'unit.it' : 'unit.mt'
        res.flow = storeUnit.iTon ? 'unit.ith' : 'unit.mth'
        res.density = storeUnit.lbft3 ? 'unit.lbpft3' : 'unit.tpm3'

        return res
    })

    function transformFlow(flow) {
        let res = flow
        if (storeUnit.iTon) res = conv.mton_iton(flow)
        return res
    }
    return { unit, conv, storeUnit, transformFlow }
}
