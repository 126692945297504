'use strict'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

export default function () {
    function dessinerGrille() {
        const ligneAutomatique = 'stroke:#CFD6EA;stroke-width:0.5;'
        let grille = document.getElementById('grille')
        // On crée les lignes
        let gridline = []
        // On crée les lignes Horizontal
        for (let z = 0; z < 200; z++) {
            gridline[z] = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'line'
            )
            gridline[z].setAttribute('x1', z * 50 - 5000)
            gridline[z].setAttribute('y1', -5000)
            gridline[z].setAttribute('x2', z * 50 - 5000)
            gridline[z].setAttribute('y2', 5000)
            gridline[z].setAttribute('style', ligneAutomatique)
            grille.appendChild(gridline[z])
        }
        // On crée les lignes Vertical
        for (let z = 200; z < 400; z++) {
            gridline[z] = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'line'
            )
            gridline[z].setAttribute('x1', -5000)
            gridline[z].setAttribute('y1', (z - 200) * 50 - 5000)
            gridline[z].setAttribute('x2', 5000)
            gridline[z].setAttribute('y2', (z - 200) * 50 - 5000)
            gridline[z].setAttribute('style', ligneAutomatique)
            grille.appendChild(gridline[z])
        }
    }
    function getPlantFromCookies(socket) {
        if (cookies.get('cookAcceptance') == 'true') {
            const req = cookies.get('plant')
            socket.emit('REQUEST_PLANT_COOKIES', req)
        }
    }
    //function socketListenning(socket) {}
    return { dessinerGrille, getPlantFromCookies }
}
