<template lang="html">
    <div class="section-top">
        <div class="dataCrusher">
            <div class="blocData">
                <!-- Brand -->
                <div class="bloc-input-pbi">
                    <label>{{ t('word.brand') }}</label>
                    <input
                        v-model="dLocal.technical.brand"
                        list="brand"
                        type="text"
                        @change="launchSocket()"
                    />
                    <datalist id="brand">
                        <option v-for="item in brandHSIList" :key="item">
                            {{ item }}
                        </option>
                    </datalist>
                </div>
                <!-- Reference -->
                <div class="bloc-input-pbi">
                    <label>{{ t('word.ref') }}</label>
                    <input
                        v-model="dLocal.technical.ref"
                        list="ref"
                        type="text"
                        @change="launchSocket()"
                    />
                    <datalist id="ref">
                        <option
                            v-for="item in listTechnical.listCrusher"
                            :key="item"
                        >
                            {{ item }}
                        </option>
                    </datalist>
                </div>
            </div>
            <div class="blocData detailsElt">
                <div class="bloc-input-pbi">
                    <label>{{ t('word.speed') }}</label>
                    <input
                        v-model="dLocal.technical.speed"
                        class="btn-number"
                        type="number"
                        step="any"
                        :min="listTechnical.speed.min"
                        :max="listTechnical.speed.max"
                    />
                    <span class="unit">rpm</span>
                </div>
                <div class="bloc-input-pbi">
                    <label>{{ t('word.rotorDiam') }}</label>
                    <input
                        v-model="dLocal.technical.rDiam"
                        class="btn-number"
                        type="number"
                        step="any"
                        min="0"
                        :disabled="listTechnical.crExist"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
            </div>
        </div>
    </div>
    <p v-if="!crusherExist" class="msgCrusherDoesNotExist">
        {{ t('modalCrusher.crusherNotFind') }}
    </p>
    <formCourbeCrusher :d="dLocal">
        <template #btnCrCalc>
            <btnCalcCrusher
                :d="dLocal"
                :c-exist="listTechnical.crExist"
                @open-modal-prm="showTechPrm = true"
            />
        </template>
    </formCourbeCrusher>
    <modalfooter @save="save()"></modalfooter>
    <modalcrushingparameter
        v-if="showTechPrm"
        :d="dLocal.technical"
        @close="showTechPrm = false"
        @save="showTechPrm = false"
    />
</template>

<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
//import modaldistribution from "./0_modaldistribution.vue";
import formCourbeCrusher from './compCommon/0_formCourbe.vue'
import btnCalcCrusher from './compCrusher/0_btnCalcModeCrushing'
import modalcrushingparameter from './compCrusher/2_modalImpactTechnicalParameter.vue'

import { useI18n } from 'vue-i18n'
import { ref, defineEmits, inject, defineProps, computed, onMounted } from 'vue'

const socket = inject('socket')
import unitLib from './compCommon/unit.js'
const { unit, conv, storeUnit } = unitLib()
console.log({ unit, conv, storeUnit })
const { t } = useI18n()
const props = defineProps({ d: { type: Object, required: true } })
const dLocal = ref(props.d)
const showTechPrm = ref(false)
let detailsCrusher = ref([])

const emit = defineEmits(['save'])
const brandHSIList = ['RP', 'Sandvik', 'Metso']

function initialUnitTranslation() {
    console.log('Translate initial :=> ' + storeUnit.inch)
    if (storeUnit.inch) {
        dLocal.value.technical.rDiam = conv.mm_inch(
            dLocal.value.technical.rDiam
        )
        dLocal.value.technical.css = conv.mm_inch(dLocal.value.technical.css)
    }
    console.log(dLocal.value)
}
function launchSocket() {
    detailsCrusher.value = []
    if (brandHSIList.includes(dLocal.value.technical.brand)) {
        const a = dLocal.value.technical.brand
        try {
            socket.emit('GETINFOMACHINE', {
                type: 'vsi',
                brand: a,
            })
        } catch (error) {
            console.info(error)
        }
    }
}
function save() {
    if (storeUnit.inch) {
        dLocal.value.technical.rDiam = conv.inch_mm(
            dLocal.value.technical.rDiam
        )
        dLocal.value.technical.css = conv.inch_mm(dLocal.value.technical.css)
    }
    emit('save', dLocal.value)
}
function majElts(listCr) {
    dLocal.value.breakageMatrixRef = { ...listCr[0].breakageMatrixRef }
    dLocal.value.impactCrusherRef = { ...listCr[0].impactCrusherRef }
    dLocal.value.flowReference = listCr[0].flowReference
    dLocal.value.rDiam = listCr[0].rotorDiam
    if (storeUnit.inch) {
        dLocal.value.technical.rDiam = conv.mm_inch(
            dLocal.value.technical.rDiam
        )
    }
}

const listTechnical = computed(() => {
    const res = {
        crExist: false,
        listCrusher: [],
        css: { min: 0, max: 999 },
        speed: { min: 0, max: 9999 },
    }
    const a = detailsCrusher.value

    res.listCrusher = new Set(a?.map((f) => f.ref))

    const listOfSelectedCrusher = a.filter(
        (r) =>
            r.ref == dLocal.value.technical.ref &&
            r.brand == dLocal.value.technical.brand
    )

    res.crExist = 0 < listOfSelectedCrusher.length

    if (res.crExist) {
        res.speed.min = listOfSelectedCrusher[0].speed.spMin ?? 0
        res.speed.max = listOfSelectedCrusher[0].speed.spMax ?? 9999
        res.css.min = listOfSelectedCrusher[0].css.min ?? 0
        res.css.max = listOfSelectedCrusher[0].css.max ?? 999
    }

    if (storeUnit.inch) {
        res.css.min = conv.mm_inch(res.speed.min)
        res.css.max = conv.mm_inch(res.speed.max)
    }

    if (res.crExist) majElts(listOfSelectedCrusher)

    return res
})

onMounted(() => {
    socket.on('SENDINFOMACHINE', (d) => {
        console.info('**************************************** SOCKET ON ')
        console.info('RECEPTION INFO MACHINE DEPUIS LE SERVEUR ')
        console.info(d)
        detailsCrusher.value = JSON.parse(d)
    })
    launchSocket(dLocal.value.technical.brand)

    initialUnitTranslation()
})
</script>

<style lang="scss" scoped>
.blocData {
    @extend .flex-c-sa;
    margin-bottom: 1rem;
}
.detailsElt {
    > div {
        border-left: solid $ShadowColor 2px;
    }
    :first-child {
        border: none;
    }
}
.msgCrusherDoesNotExist {
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
}
</style>
