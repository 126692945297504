<template>
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi">
                <div class="modal-header-pbi">
                    <h4 class="modal-title">Plant parameter</h4>

                    <button type="button" class="close" @click="$emit('close')">
                        &times;
                    </button>
                </div>
                <div class="modal-body">
                    <div class="zonePrmPlant">
                        <h4 class="modal-title">Plant information</h4>
                        <div class="blocInput">
                            <label for="plantName"></label>
                            <input
                                id="plantName"
                                v-model="p.name"
                                type="text"
                                placeholder="Your project Name"
                            />
                        </div>
                        <div class="blocInput">
                            <label for="plantCompany"></label>
                            <input
                                id="plantCompany"
                                v-model="p.company"
                                type="text"
                                placeholder="Company Name"
                            />
                        </div>
                        <div class="blocInput">
                            <label for="plantAuthor"></label>
                            <input
                                id="plantAuthor"
                                v-model="p.author"
                                type="text"
                                placeholder="Author"
                            />
                        </div>
                        <div class="blocInput">
                            <label for="plantDate"></label>
                            <input
                                id="plantDate"
                                v-model="p.date"
                                type="date"
                                placeholder="Author"
                            />
                        </div>
                    </div>
                    <div class="zoneUnit">
                        <h5>{{ t('word.unit') }}</h5>
                        <!--Imperial _______________________________ -->
                        <div class="pill">
                            <div
                                :class="{ validClass: storeUnit.imperial }"
                                @click="storeUnit.setImperial()"
                            >
                                <p>Imperial</p>
                            </div>
                            <div
                                :class="{ validClass: !storeUnit.imperial }"
                                @click="storeUnit.setMetric()"
                            >
                                <p>Metric</p>
                            </div>
                        </div>

                        <h5>{{ t('word.details') }}</h5>
                        <!--Imperial _______________________________ -->
                        <div class="pill">
                            <div
                                :class="{ validClass: storeUnit.inch }"
                                @click="storeUnit.inch = true"
                            >
                                <p>inch</p>
                            </div>
                            <div
                                :class="{ validClass: !storeUnit.inch }"
                                @click="storeUnit.inch = false"
                            >
                                <p>mm</p>
                            </div>
                        </div>
                        <!--Imperial _______________________________ -->
                        <div class="pill">
                            <div
                                :class="{ validClass: storeUnit.iTon }"
                                @click="storeUnit.iTon = true"
                            >
                                <p>Imperial tons</p>
                            </div>
                            <div
                                :class="{ validClass: !storeUnit.iTon }"
                                @click="storeUnit.iTon = false"
                            >
                                <p>Metric tons</p>
                            </div>
                        </div>
                        <!--Imperial _______________________________ -->
                        <div class="pill">
                            <div
                                :class="{ validClass: storeUnit.lbft3 }"
                                @click="storeUnit.lbft3 = true"
                            >
                                <p>{{ t('unit.lbpft3') }}</p>
                            </div>
                            <div
                                :class="{ validClass: !storeUnit.lbft3 }"
                                @click="storeUnit.lbft3 = false"
                            >
                                <p>{{ t('unit.tpm3') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer-pbi">
                    <button
                        type="button"
                        class="btn btn-info"
                        @click="emit('close')"
                    >
                        Save
                    </button>
                </div>
                <!-- ********************************************** -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { stUnit } from '@/store/index.js'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const storeUnit = stUnit()
const emit = defineEmits(['close'])
const props = defineProps({ d: { type: Object, required: true } })
const p = ref(props.d)
</script>
<style scoped lang="scss">
h3,
h4,
h5 {
    margin: 0;
    margin-top: 0.5rem;
}
.blocInput {
    margin: 1rem;
}
.modal-body {
    @extend .flex-t-sa;
    .zonePrmPlant {
        border-right: 1px solid $primary;
        margin-right: 1rem;
    }
}
.pill {
    @extend .flex-c-c;
    padding: 0.5rem;
    div {
        border: solid 1px $primary;
        width: 8rem;
        p {
            text-align: center;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
        }

        &:hover {
            @extend .hover-std;
            background-color: $HoverColor;
            opacity: 0.5;
        }
    }
    :first-child {
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }
    :last-child {
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
    }
    .validClass {
        background-color: green;
    }
}
</style>
