'use strict'
import { createApp } from 'vue'
import VueCookies from 'vue3-cookies'
import Socketio from '@/plugins/socket.js'
import App from './application-general.vue'
import router from './router'
import { createPinia } from 'pinia'
import i18n from './locales/i18n-config.js'

//import VueCookies from "vue-cookies";
//import VueI18n from "vue-i18n";
//console.log(app);pierre
const app = createApp(App)
app.use(i18n)

app.use(createPinia())
app.use(Socketio, {
    connection: 'https://rock-process.com/',
    //connection: 'http://localhost:3000',
})
app.use(VueCookies, {
    expireTimes: '20d',
    secure: true,
})
app.use(router).mount('#app')
