<template>
    <div class="modal-footer">
        <button name="button" @click="emit('save')">
            {{ t('word.save') }}
        </button>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const emit = defineEmits(['save'])
</script>

<style scoped lang="scss">
.modal-footer {
    padding-top: 0.5rem;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $ShadowColor;
}
</style>
