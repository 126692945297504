<template>
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi rounded-lg">
                <!-- Modal Header -->
                <modalheader
                    :d="dataLocal"
                    @close="emit('close')"
                ></modalheader>
                <!-- Modal body -->

                <body class="modal-body-pbi">
                    <modalscreen
                        v-if="
                            dataLocal.type.substring(0, 6) == 'screen' ||
                            dataLocal.type == 'GrizzlyScreen'
                        "
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalscreen>
                    <modalfeed
                        v-if="dataLocal.type == 'Feed'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalfeed>
                    <modalsplit
                        v-if="dataLocal.type == 'Split'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalsplit>
                    <modalnothing
                        v-if="
                            dataLocal.type == 'Bin' ||
                            dataLocal.type == 'StockPile' ||
                            dataLocal.type == 'Stock' ||
                            dataLocal.type == 'PanFeeder' ||
                            dataLocal.type == 'BeltFeeder' ||
                            dataLocal.type == 'Add'
                        "
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalnothing>

                    <modalcrusherJaw
                        v-if="dataLocal.type == 'crusherJaw'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalcrusherJaw>
                    <modalcrusherCone
                        v-if="dataLocal.type == 'crusherCone'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalcrusherCone>
                    <modalcrusherHSI
                        v-if="dataLocal.type == 'crusherHSI'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalcrusherHSI>
                    <modalcrusherVSI
                        v-if="dataLocal.type == 'crusherVSI'"
                        :d="dataLocal"
                        @save="sauvegarde($event)"
                    ></modalcrusherVSI>
                </body>
            </div>
        </div>
    </div>
</template>

<script setup>
import modalheader from './compCommon/0_modalheader.vue'

import modalscreen from './1_modalscreen.vue'
import modalfeed from './1_modalfeed.vue'
import modalsplit from './1_modalsplit.vue'
import modalnothing from './1_modalnothing.vue'
// import modalcrusher from "./1_modalcrusher.vue"
import modalcrusherJaw from './1_modalcrusherJaw.vue'
import modalcrusherCone from './1_modalcrusherCone.vue'
import modalcrusherHSI from './1_modalcrusherHSI.vue'
import modalcrusherVSI from './1_modalcrusherVSI.vue'

import { defineEmits, defineProps, ref } from 'vue'

const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['close', 'save'])
const dprops = ref(props.d)
const dataLocal = JSON.parse(JSON.stringify(dprops.value))

function sauvegarde(a) {
    dataLocal.con = a.con
    dataLocal.technical = a.technical
    emit('save', dataLocal)
}
</script>
<style scoped lang="scss"></style>
