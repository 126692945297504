'use strict'
import { computed } from 'vue'
export default function (menu) {
    const listMenu = computed(() => {
        let res = []
        if (menu.feed) res = ['Feed']
        if (menu.screen) res = ['screen1', 'screen2', 'screen3']
        if (menu.crusher)
            res = ['crusherJaw', 'crusherCone', 'crusherHSI', 'crusherVSI']
        if (menu.add) res = ['Add', 'Split']
        if (menu.stock) res = ['Stock', 'StockPile', 'Bin']
        if (menu.others) {
            res = ['BeltFeeder', 'PanFeeder', 'PrimaryFeeder', 'GrizzlyScreen']
        }
        return res
    })
    return { listMenu }
}
