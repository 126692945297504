<template>
    <div class="logoSvg">
        <svg
            viewBox="0 0 100 60"
            width="50"
            heigth="30"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="blue"
        >
            <g
                xmlns="http://www.w3.org/2000/svg"
                clip-path="url(#_clipPath_p5Q59siV5La9KDuETwudVph9s9LujWv9)"
            >
                <!--      <polygon points="65,15,85,45,45,45" fill="rgb(93,93,93)" /> -->
                <polygon class="logo_log" points="65,15,85,45,45,45" />
                <!--      <polygon points="35,20,55,45,15,45" fill="rgb(93,93,93)" /> -->
                <polygon class="logo_log" points="35,20,55,45,15,45" />

                <!--      <polygon points="50,10,75,45,25,45" fill="rgb(93,93,93)" />   -->
                <polygon class="logo_log" points="50,10,75,45,25,45" />
            </g>
        </svg>

        <p class="brandName">rock-process.com</p>
    </div>
</template>

<script setup></script>

<style scoped lang="scss">
.logoSvg {
    @extend .flex-c-sb;
    .logo_log {
        fill: $BorderColor;
        vector-effect: 'non-scaling-stroke';
        stroke-width: 2;
        stroke: $BGColor_L2;
        stroke-linejoin: 'miter';
        stroke-linecap: 'round';
        stroke-miterlimit: '3';
    }
    .brandName {
        text-decoration: none;
        white-space: nowrap;
        color: $fontColor_H;
        font-weight: 800;
        font-size: 15px;
        font-family: monospace;
        padding: 0;
        margin: 0;
    }
}

svg {
    position: relative;
    transform: translate(-10 -20) scale(1);
    transform-origin: top left;
}

//Taille écran mobile
@media (max-width: 500px) {
    .brandName {
        display: none;
    }
}
</style>
