<template>
    <div class="bloc-haut">
        <div class="left">
            <input
                v-model="dataTemp.technical.left"
                type="number"
                step="any"
                min="0"
                max="100"
                @change="
                    dataTemp.technical.right = 100 - dataTemp.technical.left
                "
            />
            <span>%</span>
        </div>
        <div class="dessin">
            <div class="desByPass" :style="{ transform: valStyle }">
                <div class="byPass"></div>
            </div>
        </div>
        <div class="right">
            <input
                v-model="dataTemp.technical.right"
                type="number"
                step="any"
                min="0"
                max="100"
                disabled
            />
            <span>%</span>
        </div>
    </div>
    <div class="bloc-bas"></div>
    <!-- Modal footer -->
    <modalfooter @save="emit('save', dataTemp)"></modalfooter>
</template>
<script setup>
import { ref, computed, defineEmits, defineProps } from 'vue'
import modalfooter from './compCommon/0_modalfooter.vue'

const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['save'])
const dataTemp = ref(props.d)

const valStyle = computed(() => {
    const n = -45 + (dataTemp.value.technical.left * 90) / 100
    return `rotate(${n}deg)`
})
</script>
<style scoped lang="scss">
span {
    @extend p;
    margin-left: 1rem;
}
.bloc-haut {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .dessin {
        width: 20vh;
        height: 20vh;
        //  background:black;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.byPass {
    content: '';
    height: 15vh;
    width: 1vh;
    border-radius: 0.5vh;
    background-color: $fontColor_H;
    transition: all 0.5s ease-in-out;
}
.desByPass {
    transform-origin: bottom;
    width: 18vh;
    height: 18vh;
    //background-color:red;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
