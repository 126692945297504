<template lang="html">
    <g transform="translate(0, 0)">
        <rect
            x="0"
            y="0"
            width="8cm"
            height="25mm"
            fill="white"
            stroke="black"
            stroke-width="2"
        ></rect>
        <line x1="0" y1="12mm" x2="25mm" y2="12mm" stroke-width="1"></line>
        <line x1="0" y1="18.5mm" x2="80mm" y2="18.5mm" stroke-width="1"></line>
        <line x1="25mm" y1="0" x2="25mm" y2="25mm" stroke-width="1"></line>

        <g transform="scale(0.5) translate(50 0)">
            <logo></logo>
        </g>
        <text class="brand" text-anchor="middle" x="12.5mm" y="10mm"
            >rock-process</text
        >
        <text class="description" x="0.8mm" y="14mm">Author:</text>
        <text class="description" x="0.8mm" y="20.5mm">Date:</text>
        <text class="description" x="25.8mm" y="20.5mm">PlantId:</text>
        <text class="infoLight" text-anchor="middle" x="12.5mm" y="17.5mm">{{
            props.d.author
        }}</text>
        <text class="infoLight" text-anchor="middle" x="12.5mm" y="23.5mm">{{
            props.d.date
        }}</text>
        <text class="infoLight" text-anchor="middle" x="52.5mm" y="23.5mm">{{
            props.c.plant
        }}</text>

        <text class="infoHigh" text-anchor="middle" x="52.5mm" y="8mm">{{
            props.d.name
        }}</text>
        <text class="infoHigh" text-anchor="middle" x="52.5mm" y="15mm">{{
            props.d.company
        }}</text>
    </g>
</template>

<script setup>
import logo from '@/components/logo-raw.vue'
import { defineProps } from 'vue'
const props = defineProps({
    d: { type: Object, required: true },
    c: { type: Object, required: true },
})
</script>

<style lang="scss" scoped>
* {
    font-family: monospace;
}
.brand {
    font-size: 3mm;
    font-weight: bolder;
    color: $fontColor_H;
}
.description {
    font-size: 2mm;
    font-weight: bolder;
    color: $fontColor_H;
}
.infoLight {
    font-size: 3mm;
    font-weight: bold;
    color: $fontColor_H;
}
.infoHigh {
    font-size: 4mm;
    font-weight: bold;
    color: $fontColor_H;
}
</style>
