'use strict'
const INCH_IN_MM = 25.4
const IMPERIALTON_IN_METRICTON = 1.01605
const GALPERMIN_IN_M3PERH = 0.22712470704
const METRICTON_IN_LBPERCUBICFOOT = 62.4279605761
class conversion {
    //Fonction de test des valeurs d'entrées
    static testEntryVal = function (req) {
        try {
            const res = parseFloat(req)
            if (isNaN(res)) {
                throw 'Parameter is not a number! (NaN)'
            }
            return res
        } catch (err) {
            console.error(`CONVERT JS => ${err}`)
            return 0
        }
    }

    //***** LENGHT *****
    static mm_inch = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round((res / INCH_IN_MM) * 100) / 100
        return res
    }
    static inch_mm = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res * INCH_IN_MM * 100) / 100
        return res
    }
    //***** WEIGTH *****
    //Metric ton => imperial ton
    static mton_iton = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res / IMPERIALTON_IN_METRICTON)
        return res
    }
    //Imperial ton => Metric ton
    static iton_mton = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res * IMPERIALTON_IN_METRICTON)
        return res
    }
    //***** FLOW *****
    static galmin_m3h = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res * GALPERMIN_IN_M3PERH)
        return res
    }
    static m3h_galmin = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res / GALPERMIN_IN_M3PERH)
        return res
    }
    //***** DENSITY *****
    static mtpm3_lbpft3 = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res * METRICTON_IN_LBPERCUBICFOOT)
        return res
    }
    static lbpft3_mtpm3 = function (req) {
        let res = this.testEntryVal(req)
        res = Math.round(res / METRICTON_IN_LBPERCUBICFOOT)
        return res
    }
}

module.exports = conversion
