<template>
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi">
                <div class="modal-header-pbi">
                    <h4 class="modal-title-pbi">Display settings</h4>
                    <button type="button" class="close" @click="$emit('close')">
                        &times;
                    </button>
                </div>

                <div class="modal-body-pbi">
                    <div class="form-check">
                        <input id="cb1" v-model="sc.flow" type="checkbox" />
                        <label class="form-check-label" for="cb1">{{
                            t('showPrm.displayFlow')
                        }}</label>
                    </div>

                    <div class="form-check">
                        <input
                            id="cb2"
                            v-model="sc.name.show"
                            type="checkbox"
                        />
                        <label class="form-check-label" for="cb2">{{
                            t('showPrm.displayName')
                        }}</label>
                    </div>

                    <div class="form-check">
                        <input
                            id="cb3"
                            v-model="sc.technical.show"
                            type="checkbox"
                        />
                        <label class="form-check-label" for="cb3">{{
                            t('showPrm.displayTech')
                        }}</label>
                    </div>

                    <div class="form-check">
                        <input id="cb4" v-model="sc.mesh" type="checkbox" />
                        <label class="form-check-label" for="cb4"
                            >{{ t('showPrm.displayMesh') }}
                        </label>
                    </div>
                </div>
                <div class="modal-footer-pbi">
                    <button type="button" class="btn btn-info" @click="save()">
                        {{ t('word.save') }}
                    </button>
                </div>
            </div>
            <!-- ********************************************** -->
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue'
import { useI18n } from 'vue-i18n'
const props = defineProps({ objSrc: { type: Object, required: true } })
const emit = defineEmits(['close', 'save'])

const { t } = useI18n()
const sc = ref(props.objSrc)

function save() {
    emit('save', sc)
}
</script>
<style scoped lang="scss">
.form-check-label {
    padding-left: 0.5rem;
}

.form-check {
    display: flex;
    align-items: center;
    padding-top: 0.25rem;
}
</style>
