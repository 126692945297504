<template>
    <div class="bloc-haut">
        <p>{{ t('modalNothing.text') }}</p>
    </div>
    <!-- Modal footer -->
    <modalfooter @save="emit('save', dLocal)"></modalfooter>
</template>
<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
import { defineEmits, defineProps, ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['save'])
const dLocal = ref(props.d)
</script>
<style scoped lang="scss">
.bloc-haut {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>
