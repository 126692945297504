import { createI18n } from 'vue-i18n'
import fr from './data/fr.json'
import en from './data/en.json'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
    /*
  const locales = require.context("./data", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  console.log(locales);
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    console.log(matched);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = locales(key).default;
    }
  });
  console.log(messages);
  */
    return { en, fr }
}

export default createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages(),
    legacy: false,
    globalInjection: true,
})
