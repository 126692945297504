<template>
    <!--      <polygon points="65,15,85,45,45,45" fill="rgb(93,93,93)" /> -->
    <polygon class="logo_log" points="65,15,85,45,45,45" />
    <!--      <polygon points="35,20,55,45,15,45" fill="rgb(93,93,93)" /> -->
    <polygon class="logo_log" points="35,20,55,45,15,45" />

    <!--      <polygon points="50,10,75,45,25,45" fill="rgb(93,93,93)" />   -->
    <polygon class="logo_log" points="50,10,75,45,25,45" />
</template>

<script setup></script>

<style scoped lang="scss">
.logo_log {
    fill: $BorderColor;
    vector-effect: 'non-scaling-stroke';
    stroke-width: 2;
    stroke: $BGColor_L2;
    stroke-linejoin: 'miter';
    stroke-linecap: 'round';
    stroke-miterlimit: '3';
}
</style>
