<template lang="html">
    <div v-if="p.d == 'loading'" class="loadingLogo"></div>
    <div v-if="p.d == 'positive'" class="positiveLogo"></div>
    <div v-if="p.d == 'negative'" class="negativeLogo"></div>
</template>

<script setup>
import { defineProps } from 'vue'
const p = defineProps({ d: { type: String, required: true } })
</script>

<style lang="scss" scoped>
.loadingLogo {
    content: '';
    margin: 1rem;
    border: 6px solid rgba(0, 0, 0, 0);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    position: relative;
    &:after {
        content: '';
        border: 6px solid transparent;
        border-top-color: $fontColor_H;
        border-bottom-color: $fontColor_H;
        opacity: 1;
        height: 35px;
        width: 35px;
        position: absolute;
        top: -6px;
        left: -6px;
        border-radius: 50px;
        animation: tour 2s linear infinite;
    }
    &:before {
        content: '';
        border: 6px solid transparent;
        border-left-color: $fontColor_H;
        border-right-color: $fontColor_H;
        opacity: 0.6;
        height: 15px;
        width: 15px;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 50px;
        animation: tour-reverse 1s linear infinite;
    }
    @keyframes tour {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes tour-reverse {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
}
.negativeLogo {
    border: 6px solid $infoColor-bg-negative;
    background-color: $infoColor-bg-negative;
    box-shadow: $infoColor-bg-negative 0px 0px 4px 3px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 1rem;
    &:after {
        content: '';
        position: absolute;
        top: 17.5px;
        left: 5px;
        background-color: $fontColor_L;
        height: 5px;
        width: 30px;
        transform: rotate(45deg);
        border-radius: 1px;
    }

    &:before {
        border-radius: 1px;
        content: '';
        position: absolute;
        top: 17.5px;
        left: 5px;
        background-color: $fontColor_L;
        height: 5px;
        width: 30px;
        transform: rotate(-45deg);
    }
}
.positiveLogo {
    border: 6px solid $infoColor-bg-positive;
    background-color: $infoColor-bg-positive;
    box-shadow: $infoColor-bg-positive 0px 0px 4px 3px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 1rem;
    &:after {
        content: '';
        position: absolute;
        top: 19.5px;
        left: 3px;
        border-radius: 1px;
        background-color: $fontColor_L;
        height: 5px;
        width: 20px;
        transform: rotate(45deg);
    }

    &:before {
        content: '';
        border-radius: 1px;
        position: absolute;
        top: 17.5px;
        left: 13px;
        background-color: $fontColor_L;
        height: 5px;
        width: 26px;
        transform: rotate(-50deg);
    }
}
</style>
