<template>
    <div class="header">
        <div class="left">
            <router-link to="/"> <logo /></router-link>
        </div>
        <div class="right">
            <nav class="">
                <router-link to="/">{{ t('header.Home') }}</router-link>
                <router-link to="/PFD" class="pfdBtn">{{
                    t('header.PFD')
                }}</router-link>
                <router-link to="/Contact">{{
                    t('header.Contact')
                }}</router-link>
                <a href="https://rock-process.gitbook.io/rp.com">Doc</a>
            </nav>
            <select v-model="locale">
                <option v-for="lang in ['en', 'fr']" :key="lang" :value="lang">
                    {{ lang }}
                </option>
            </select>
        </div>
    </div>
    <router-view />
</template>

<script setup>
//import { inject } from "vue";
import { useI18n } from 'vue-i18n'
import logo from './components/logo-rp.vue'
//const i18n = inject("i18n");
const { t, locale } = useI18n()
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
}
logo:hover {
    @extend .hover-std;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border: none;
    border-radius: 0;
    border-bottom: 1px solid $BorderColor;
    color: $fontColor_H;
    //margin-bottom: 5px;
}
.right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: fit-content;
    select {
        width: auto;
        border: none;
        padding-right: 0.2rem;
        color: $fontColor_H;
        box-shadow: none;
    }
    nav {
        //    width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 20rem;
        a {
            flex: 1;
            padding: 1rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            text-decoration: none;
            text-align: center;
            color: $fontColor_H;
            font-size: 0.875rem;
            font-weight: 900;
            &:hover {
                @extend .hover-std;
                color: $fontColor_H;
                &::after {
                    width: 100%;
                }
            }
            &::after {
                content: '';
                transform: translateY(4px);
                display: flex;
                align-items: flex-end;
                height: 2px;
                width: 0%;
                border-radius: 0rem;
                background-color: $fontColor_H;
                transition: all 0.5s ease-in-out;
            }
        }
    }
}
@media (max-width: 500px) {
    .pfdBtn {
        display: none;
    }
    .right {
        nav {
            width: auto;
            a {
                padding: 0.5rem;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
            }
        }
    }
}

@media print {
    * {
        display: none;
    }
}
</style>
