import { defineStore } from 'pinia'

export const stUnit = defineStore('unit', {
    state: () => {
        return { imperial: false, iTon: false, inch: false, lbft3: false }
    },
    actions: {
        changeConfig() {
            this.imperial = !this.imperial
            this.iTon = !this.iTon
            this.inch = !this.inch
            this.lbft3 = !this.lbft3
        },
        setImperial() {
            this.imperial = true
            this.iTon = true
            this.inch = true
            this.lbft3 = true
        },
        setMetric() {
            this.imperial = false
            this.iTon = false
            this.inch = false
            this.lbft3 = false
        },
    },
})
export const stUser = defineStore('user', {
    state: () => {
        return { cookies: false, mail: '' }
    },
})
