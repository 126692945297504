<template>
    <!-- Définition des éléments de logo -->

    <defs>
        <g id="motor">
            <path
                d="M50 9 L64 9 L66 11 L66 29 L64 31 L50 31 Z"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></path>
            <rect
                x="10"
                y="13"
                width="40"
                height="14"
                rx="1"
                ry="1"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></rect>
            <rect
                x="25"
                y="11"
                width="7"
                height="18"
                rx="1"
                ry="1"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></rect>
            <rect
                x="10"
                y="10"
                width="20"
                height="20"
                rx="1"
                ry="1"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></rect>

            <rect
                x="25"
                y="11"
                width="7"
                height="18"
                rx="1"
                ry="1"
                fill="none"
                stroke="black"
                stroke-width=".5"
                transform="translate(12,0)"
            ></rect>
            <rect
                x="10"
                y="9"
                width="20"
                height="22"
                fill="none"
                stroke="black"
                stroke-width=".5"
                transform="translate(29,0)"
            ></rect>

            <line x1="39" y1="15" x2="59" y2="15" stroke-width=".5"></line>
            <line x1="39" y1="17" x2="59" y2="17" stroke-width=".5"></line>
            <line x1="39" y1="19" x2="59" y2="19" stroke-width=".5"></line>
            <line x1="39" y1="21" x2="59" y2="21" stroke-width=".5"></line>
            <line x1="39" y1="23" x2="59" y2="23" stroke-width=".5"></line>
            <line x1="39" y1="25" x2="59" y2="25" stroke-width=".5"></line>

            <line x1="39" y1="10" x2="59" y2="10" stroke-width=".5"></line>
            <line x1="39" y1="30" x2="59" y2="30" stroke-width=".5"></line>

            <circle
                cx="18"
                cy="18"
                r="2"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></circle>
        </g>

        <g id="arrosage">
            <ellipse
                vector-effect="non-scaling-stroke"
                cx="4"
                cy="1.4528380312567573"
                rx="1.5894760885860872"
                ry="1.452838031256757"
                fill="rgb(58,115,206)"
            ></ellipse>
            <line
                x1="4"
                y1="4"
                x2="4"
                y2="9"
                fill="rgb(58,115,206)"
                vector-effect="non-scaling-stroke"
                stroke-width="1"
                stroke="rgb(58,115,206)"
                stroke-linejoin="miter"
                stroke-linecap="square"
                stroke-miterlimit="3"
            ></line>
            <line
                x1="6"
                y1="3.453"
                x2="8"
                y2="6.453"
                vector-effect="non-scaling-stroke"
                stroke-width="1"
                stroke="rgb(58,115,206)"
                stroke-linejoin="miter"
                stroke-linecap="square"
                stroke-miterlimit="3"
            ></line>
            <line
                x1="2"
                y1="3.5"
                x2="0"
                y2="7"
                vector-effect="non-scaling-stroke"
                stroke-width="1"
                stroke="rgb(58,115,206)"
                stroke-linejoin="miter"
                stroke-linecap="square"
                stroke-miterlimit="3"
            ></line>
        </g>

        <g id="crusherCone">
            <line x1="20" y1="15" x2="10" y2="25" class="l-1"></line>
            <line x1="20" y1="15" x2="30" y2="25" class="l-1"></line>

            <line x1="10" y1="15" x2="5" y2="25" class="l-1"></line>
            <line x1="30" y1="15" x2="35" y2="25" class="l-1"></line>

            <line x1="5" y1="15" x2="10" y2="15" class="l-1"></line>
            <line x1="30" y1="15" x2="35" y2="15" class="l-1"></line>

            <line x1="5" y1="5" x2="5" y2="15" class="l-1"></line>
            <line x1="35" y1="5" x2="35" y2="15" class="l-1"></line>

            <line x1="2" y1="25" x2="38" y2="25" class="l-1"></line>
            <line x1="2" y1="30" x2="38" y2="30" class="l-1"></line>
            <line x1="15" y1="35" x2="25" y2="35" class="l-1"></line>

            <line x1="2" y1="25" x2="2" y2="30" class="l-1"></line>
            <line x1="38" y1="25" x2="38" y2="30" class="l-1"></line>

            <line x1="15" y1="30" x2="15" y2="35" class="l-1"></line>
            <line x1="25" y1="30" x2="25" y2="35" class="l-1"></line>
        </g>

        <g id="crusherHSI">
            <circle
                cx="20"
                cy="20"
                r="8"
                fill="none"
                stroke="black"
                stroke-width="2"
            ></circle>
            <rect
                x="18"
                y="10"
                width="5"
                height="3"
                fill="none"
                stroke="black"
                stroke-width="1"
                transform="rotate(90 20 20)"
            ></rect>
            <rect
                x="18"
                y="10"
                width="5"
                height="3"
                fill="none"
                stroke="black"
                stroke-width="1"
                transform="rotate(180 20 20)"
            ></rect>
            <rect
                x="18"
                y="10"
                width="5"
                height="3"
                fill="none"
                stroke="black"
                stroke-width="1"
                transform="rotate(270 20 20)"
            ></rect>
            <rect
                x="18"
                y="10"
                width="5"
                height="3"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></rect>

            <line x1="5" y1="15" x2="5" y2="35" class="l-2"></line>
            <line x1="35" y1="15" x2="35" y2="35" class="l-2"></line>
            <line x1="15" y1="5" x2="25" y2="5" class="l-2"></line>
            <line x1="25" y1="5" x2="35" y2="15" class="l-2"></line>
            <line x1="5" y1="35" x2="15" y2="35" class="l-2"></line>
            <line x1="25" y1="35" x2="35" y2="35" class="l-2"></line>
        </g>

        <g id="crusherVSI">
            <rect
                x="11"
                y="13.688"
                width="18"
                height="6.624"
                transform="matrix(1,0,0,1,0,0)"
                fill="white"
                stroke="black"
                stroke-width="1"
            />
            <rect
                x="5"
                y="16"
                width="30"
                height="12"
                transform="matrix(1,0,0,1,0,0)"
                fill="white"
                stroke="black"
                stroke-width="1"
            />
            <polygon
                points="14,11,26,11.016,20.165,23"
                fill="white"
                stroke="black"
                stroke-width="1"
            />
            <rect
                x="11"
                y="19.688"
                width="18"
                height="6.624"
                transform="matrix(1,0,0,1,0,0)"
                fill="white"
                stroke="black"
                stroke-width="1"
            />
        </g>

        <g id="crusherJaw">
            <line x1="8" y1="10" x2="16" y2="35" class="l-3"></line>
            <line x1="30" y1="10" x2="22" y2="35" class="l-5"></line>
            <line x1="8" y1="10" x2="8" y2="35" class="l-1"></line>
            <line
                x1="16"
                y1="35"
                x2="8"
                y2="35"
                stroke-width="1"
                class="l-1"
            ></line>
            <circle
                cx="30"
                cy="10"
                r="10"
                fill="none"
                stroke="black"
                stroke-width="2"
            ></circle>
            <circle
                cx="30"
                cy="10"
                r="7"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></circle>
            <circle
                cx="30"
                cy="10"
                r="2"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></circle>
        </g>

        <g id="PanFeeder">
            <g
                class="details_draw"
                transform="rotate(15 20 20) translate(0) scale(1)"
            >
                <rect
                    x="10"
                    y="10"
                    width="25"
                    height="7"
                    fill="none"
                    stroke="black"
                    stroke-width="1"
                ></rect>
                <path
                    d="M12 17 L16 25 L30 17 Z"
                    fill="none"
                    stroke="black"
                    stroke-width="1"
                ></path>

                <line
                    x1="5"
                    x2="10"
                    y1="10"
                    y2="10"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <line
                    x1="7"
                    x2="10"
                    y1="10"
                    y2="17"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <line
                    x1="15"
                    x2="15"
                    y1="10"
                    y2="18"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <line
                    x1="20"
                    x2="20"
                    y1="10"
                    y2="18"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <line
                    x1="25"
                    x2="25"
                    y1="10"
                    y2="18"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <line
                    x1="30"
                    x2="30"
                    y1="10"
                    y2="18"
                    stroke="black"
                    stroke-width=".5"
                ></line>
                <use
                    xlink:href="#motor"
                    transform="translate(8 13) scale(0.18) rotate(63 0 20)"
                ></use>
            </g>
        </g>

        <g id="BeltFeeder">
            <circle
                cx="5"
                cy="20"
                r="3"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></circle>
            <circle
                cx="25"
                cy="20"
                r="3"
                fill="none"
                stroke="black"
                stroke-width=".5"
            ></circle>

            <line
                x1="5"
                x2="25"
                y1="17"
                y2="17"
                stroke="black"
                stroke-width=".5"
            ></line>
            <line
                x1="5"
                x2="25"
                y1="23"
                y2="23"
                stroke="black"
                stroke-width=".5"
            ></line>

            <use
                xlink:href="#motor"
                transform="translate(27 15.5) scale(0.22) rotate(180 0 20)"
            ></use>

            <line
                x1="25"
                x2="28"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="35"
                x2="32"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>

            <line
                x1="35"
                x2="35"
                y1="25"
                y2="15"
                stroke="black"
                stroke-width=".8"
            ></line>

            <line
                x1="5"
                x2="2"
                y1="15"
                y2="5"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="10"
                x2="12"
                y1="15"
                y2="5"
                stroke="black"
                stroke-width=".8"
            ></line>
        </g>

        <g id="PrimaryFeeder">
            <line
                x1="5"
                x2="10"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="5"
                x2="30"
                y1="5"
                y2="5"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="10"
                x2="35"
                y1="25"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="30"
                x2="30"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="30"
                x2="30"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="10"
                x2="15"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="35"
                x2="35"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="15"
                x2="35"
                y1="35"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <use
                xlink:href="#motor"
                transform="translate(20 20) scale(0.18) rotate(60 0 20)"
            ></use>
        </g>

        <g id="GrizzlyScreen">
            <line
                x1="5"
                x2="10"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="5"
                x2="30"
                y1="5"
                y2="5"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="10"
                x2="35"
                y1="25"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="30"
                x2="30"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="30"
                x2="30"
                y1="5"
                y2="25"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="10"
                x2="15"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="35"
                x2="35"
                y1="25"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <line
                x1="15"
                x2="35"
                y1="35"
                y2="35"
                stroke="black"
                stroke-width=".8"
            ></line>
            <use
                xlink:href="#motor"
                transform="translate(20 20) scale(0.18) rotate(60 0 20)"
            ></use>
        </g>

        <g id="Split" transform="scale(0.7)">
            <line x1="15" y1="3" x2="15" y2="15" class="l-4"></line>
            <line x1="8" y1="22" x2="15" y2="15" class="l-4"></line>
            <line x1="22" y1="22" x2="15" y2="15" class="l-4"></line>
            <path
                fill="black"
                d="M0 0 L-5 -10 L5 -10 Z"
                transform="translate(3 27) rotate(45)"
            ></path>
            <path
                fill="black"
                d="M0 0 L-5 -10 L5 -10 Z"
                transform="translate(27 27) rotate(-45)"
            ></path>
        </g>

        <g id="Feed" transform="scale(2)">
            <line x1="10" y1="5" x2="10" y2="15" class="l-3"></line>
            <path fill="black" d="M7.5 12.5 L10 17.5 L12.5 12.5 Z"></path>
            <g transform="translate(-5 -5)">
                <path
                    d=" M 10.786 4.667 L 9 6.333 L 9.714 8.667 L 10.429 8 L 11.143 9 L 12.929 7.667 L 12.571 7 L 14 5.667 L 13.286 5.333 L 13.643 4.667 L 11.857 4 L 11.5 5 L 10.786 4.667 Z "
                    fill="rgb(255,255,255)"
                    vector-effect="non-scaling-stroke"
                    stroke-width="1"
                    stroke="rgb(0,0,0)"
                    stroke-linejoin="miter"
                    stroke-linecap="square"
                    stroke-miterlimit="3"
                />
                <path
                    d=" M 15.786 6 L 14 7.667 L 14.714 10 L 15.429 9.333 L 16.143 10.333 L 17.929 9 L 17.571 8.333 L 19 7 L 18.286 6.667 L 18.643 6 L 16.857 5.333 L 16.5 6.333 L 15.786 6 Z "
                    fill="rgb(255,255,255)"
                    vector-effect="non-scaling-stroke"
                    stroke-width="1"
                    stroke="rgb(0,0,0)"
                    stroke-linejoin="miter"
                    stroke-linecap="square"
                    stroke-miterlimit="3"
                />
                <path
                    d=" M 10.786 11 L 9 12.667 L 9.714 15 L 10.429 14.333 L 11.143 15.333 L 12.929 14 L 12.571 13.333 L 14 12 L 13.286 11.667 L 13.643 11 L 11.857 10.333 L 11.5 11.333 L 10.786 11 Z "
                    fill="rgb(255,255,255)"
                    vector-effect="non-scaling-stroke"
                    stroke-width="1"
                    stroke="rgb(0,0,0)"
                    stroke-linejoin="miter"
                    stroke-linecap="square"
                    stroke-miterlimit="3"
                />
            </g>
        </g>

        <g id="Add" transform="scale(0.7)">
            <line x1="15" y1="15" x2="15" y2="25" class="l-4"></line>
            <line x1="5" y1="5" x2="15" y2="15" class="l-4"></line>
            <line x1="25" y1="5" x2="15" y2="15" class="l-4"></line>
            <path
                fill="black"
                d="M0 0 L-5 -10 L5 -10 Z"
                transform="translate(15 30) rotate(0)"
            ></path>
        </g>

        <g id="Stock">
            <path
                d="M 5,30 a 5 2 0  0 0 30,0"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></path>
            <line
                x1="20"
                y1="7"
                x2="5"
                y2="30"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
            <line
                x1="20"
                y1="7"
                x2="35"
                y2="30"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
        </g>

        <g id="StockPile">
            <line
                x1="20"
                y1="5"
                x2="5"
                y2="30"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
            <line
                x1="20"
                y1="5"
                x2="35"
                y2="30"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
            <line
                x1="5"
                y1="30"
                x2="35"
                y2="30"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>

            <line
                x1="22.5"
                y1="30"
                x2="28"
                y2="18"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
            <line
                x1="17.5"
                y1="30"
                x2="12"
                y2="18"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>

            <rect
                x="17.5"
                y="30"
                width="5"
                height="2"
                fill="green"
                stroke="black"
                stroke-width="1"
            ></rect>
        </g>

        <g id="Bin">
            <rect
                x="6"
                y="6"
                width="28"
                height="18"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></rect>
            <line
                x1="6"
                y1="24"
                x2="20"
                y2="35"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
            <line
                x1="34"
                y1="24"
                x2="20"
                y2="35"
                fill="none"
                stroke="black"
                stroke-width="1"
            ></line>
        </g>
        <g id="screen1">
            <line x1="10" y1="15" x2="30" y2="20" stroke="black" />
            <circle
                cx="20"
                cy="35"
                r="1.5"
                fill="black"
                stroke="black"
                stroke-width=".5"
            ></circle>
        </g>
        <g id="screen2">
            <line x1="10" y1="10" x2="30" y2="15" stroke="black" />
            <line x1="10" y1="15" x2="30" y2="20" stroke="black" />
            <circle
                cx="20"
                cy="35"
                r="1.5"
                fill="black"
                stroke="black"
                stroke-width=".5"
            ></circle>
        </g>
        <g id="screen3">
            <line x1="10" y1="10" x2="30" y2="15" stroke="black" />
            <line x1="10" y1="15" x2="30" y2="20" stroke="black" />
            <line x1="10" y1="20" x2="30" y2="25" stroke="black" />
            <circle
                cx="20"
                cy="35"
                r="1.5"
                fill="black"
                stroke="black"
                stroke-width=".5"
            ></circle>
        </g>
    </defs>
</template>
<style lang="scss">
.l-1 {
    stroke: black;
    stroke-width: 1;
}
.l-2 {
    stroke: black;
    stroke-width: 2;
}
.l-3 {
    stroke: black;
    stroke-width: 3;
}
.l-4 {
    stroke: black;
    stroke-width: 4;
}
.l-5 {
    stroke: black;
    stroke-width: 5;
}
</style>
