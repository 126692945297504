<template>
    <div class="section-top">
        <div class="blocData">
            <div class="bloc-input-pbi">
                <label for="fl00">{{ t('word.flow') }}</label>
                <input
                    v-model="dLocal.con[0].material.flow"
                    name="fl00"
                    type="number"
                    min="0"
                />
                <span class="unit">{{ t(unit.flow) }}</span>
            </div>
        </div>
        <div class="blocData">
            <div class="bloc-input-pbi">
                <label for="mat00">{{ t('word.material') }}</label>
                <input
                    v-model="dLocal.con[0].material.intrinseque.material"
                    name="mat00"
                    style="width: 10rem"
                    list="material"
                    type="text"
                    @change="updateMaterial()"
                />
                <datalist id="material">
                    <option v-for="item in dataMaterial" :key="item.rockName">
                        {{ item.rockName }}
                    </option>
                </datalist>
                <span class="unit"></span>
            </div>
            <div class="bloc-input-pbi">
                <label for="dens00">{{ t('word.density') }}</label>
                <input
                    v-model="dLocal.con[0].material.intrinseque.density"
                    name="dens00"
                    type="number"
                    step="any"
                    min="0.1"
                    max="10"
                />
                <span class="unit">{{ t(unit.density) }}</span>
            </div>

            <button>
                <div
                    class="std-ButtonTxtIcon"
                    @click="showDetails = !showDetails"
                >
                    <h5>Material details</h5>
                    <i class="material-icons">menu</i>
                </div>
            </button>

            <div v-show="showDetails" class="blocDataDetails">
                <div class="bloc-input-pbi">
                    <label for="mois00"> {{ t('word.moistureContent') }}</label>
                    <input
                        v-model="dLocal.con[0].material.intrinseque.moisture"
                        name="mois00"
                        type="number"
                        step="any"
                        min="0"
                        max="50"
                    />
                    <span class="unit">%</span>
                </div>

                <div class="bloc-input-pbi">
                    <label for="clay00">{{ t('word.clayContent') }}</label>
                    <input
                        v-model="dLocal.con[0].material.intrinseque.clay"
                        name="clay00"
                        type="number"
                        step="any"
                        min="0"
                        max="80"
                    />
                    <span class="unit">%</span>
                </div>

                <div class="bloc-input-pbi">
                    <label for="wi00">{{ t('word.workIndex') }}</label>
                    <input
                        v-model="dLocal.con[0].material.intrinseque.wIndex"
                        name="wi00"
                        type="number"
                        step="any"
                        min="1"
                        max="35"
                    />
                    <span class="unit"></span>
                </div>

                <div class="bloc-input-pbi">
                    <label for="abra00">{{ t('word.abrasivity') }}</label>
                    <input
                        v-model="dLocal.con[0].material.intrinseque.abrasivity"
                        name="abra00"
                        type="number"
                        step="any"
                        min="0"
                        max="1"
                    />
                    <span class="unit"></span>
                </div>
            </div>
        </div>
    </div>

    <formCourbeCrusher :d="dLocal">
        <template #btnCrCalc>
            <input
                id="distributionName"
                class="labelDistribution"
                style="width: 18rem"
                list="stdDistri"
                @change="updateDistribution($event)"
            />
            <datalist id="stdDistri">
                <option v-for="item in dataDistribution" :key="item.label">
                    {{ item.label }}
                </option>
            </datalist>
        </template>
    </formCourbeCrusher>

    <modalfooter @save="save()"></modalfooter>
</template>

<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
import formCourbeCrusher from './compCommon/0_formCourbe.vue'

import dataElt from './compFeed/dataMaterial.js'
import { ref, defineProps, defineEmits, onMounted } from 'vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import unitLib from './compCommon/unit.js'

const props = defineProps({ d: { type: Object, required: true } })
const dLocal = ref(props.d)
const showDetails = ref(false)
const emit = defineEmits(['save'])

const { unit, conv, storeUnit } = unitLib()

const { dataMaterial, dataDistribution } = dataElt()

function updateDistribution(evt) {
    const key = evt.target.value
    let res = dataDistribution.find((t) => t.label == key)
    dLocal.value.con[0].material.distribution = JSON.parse(
        JSON.stringify(res.plot)
    )
}

function updateMaterial() {
    const key = dLocal.value.con[0].material.intrinseque.material
    const val = dataMaterial.find((g) => g.rockName == key)
    const racourci = dLocal.value.con[0].material.intrinseque
    racourci.density = val.densityBulk
    racourci.abrasivity = val.abrasionIndex
    console.log(val)
    racourci.wIndex = val.workIndex
    if (storeUnit.lbft3) {
        racourci.density = conv.mtpm3_lbpft3(val.densityBulk)
    }
}
function save() {
    const rac = dLocal.value.con[0].material
    if (storeUnit.lbft3) {
        rac.intrinseque.density = conv.lbpft3_mtpm3(rac.intrinseque.density)
    }
    if (storeUnit.iTon) {
        rac.flow = conv.iton_mton(rac.flow)
    }
    emit('save', dLocal.value)
}
function initialUnitTranslation() {
    const rac = dLocal.value.con[0].material
    if (storeUnit.iTon) {
        rac.flow = conv.mton_iton(rac.flow)
    }
    if (storeUnit.lbft3) {
        rac.intrinseque.density = conv.mtpm3_lbpft3(rac.intrinseque.density)
    }
}
onMounted(() => {
    initialUnitTranslation()
})
</script>
<style scoped lang="scss">
.section-top {
    @extend .flex-c-sa;

    .blocData {
        padding: 1rem;
        width: 50%;
    }
    button {
        width: 100%;
    }

    .blocData:last-child {
        border-left: solid 1px $primary;
    }

    .blocDataDetails {
        padding-top: 1rem;
        font-size: 0.9rem;
        label {
            width: 8rem;
        }
    }
}
</style>
