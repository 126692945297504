<template>
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi rounded-lg">
                <!-- Modal Header -->
                <div class="modal-header-pbi">
                    <h3 class="modal-title">
                        {{ t('modalGraph.header.title') }}
                        {{
                            props.d.name == ''
                                ? props.d.type + '  , id : ' + props.d.id
                                : props.d.name
                        }}
                    </h3>

                    <div class="bloc-bouton">
                        <button
                            class=""
                            title="Closing window"
                            @click="emit('close')"
                        >
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
                <!-- Modal body -->
                <body class="modal-body-pbi">
                    <select v-model="graphType">
                        <option value="cumulPassing">
                            {{ t('word.cumulPassing') }}
                        </option>
                        <option value="cumulRetain">
                            {{ t('word.cumulRetain') }}
                        </option>
                    </select>
                    <div class="zGraph">
                        <canvas id="canvas10254" class="graphique"></canvas>
                        <table>
                            <tr class="tableTitre">
                                <th>
                                    {{ t('word.mesh') + ' ' + t(unit.length) }}
                                </th>
                                <th
                                    v-for="truc in preparationCourbe"
                                    :key="truc.label"
                                >
                                    {{ truc.label }}
                                </th>
                            </tr>

                            <tr
                                v-for="deck in tableau"
                                :key="deck"
                                class="tableLigne"
                            >
                                <td v-for="point in deck" :key="point">
                                    {{ Math.round(point * 100) / 100 }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </body>
            </div>
        </div>
    </div>
</template>

<script setup>
import Chart from 'chart.js/auto'
import courbeMethods from '@/components/PFD/1_CourbeMethods.js'
import { defineEmits, defineProps, ref, watch, onMounted, computed } from 'vue'

import unitLib from './unit.js'
const { unit, conv, storeUnit } = unitLib()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['close'])
//const dLocal = ref(props.d)
const graphType = ref('cumulPassing')

let myGraphique = Object

const options = {
    responsive: true,
    maintainAspectRatio: true,
    title: {
        display: false,
        text: 'Graphical vizualisation',
        responsive: true,
    },
    scales: {
        xAxes: {
            type: 'logarithmic',
            position: 'bottom',
            offset: true,
            ticks: {
                beginAtZero: true,
                userCallback(tick) {
                    const remain =
                        tick /
                        Math.pow(10, Math.floor(Chart.helpers.log10(tick)))
                    if (remain === 1 || remain === 2 || remain === 5) {
                        return `${tick.toString()}mm`
                    }
                    return ''
                },
            },
            scaleLabel: {
                labelString: 'Mesh dimension [mm]',
                display: true,
            },
        },
        yAxes: {
            type: 'linear',
            scaleLabel: {
                labelString: '% Pourcent',
                ticks: {
                    min: 0,
                    max: 100,
                    padding: 1,
                },
                display: true,
            },
        },
    },
}

function getCourbe() {
    const r = []
    const color = ['tomato', 'black', 'black', 'black', 'black']
    console.log(preparationCourbe.value)
    preparationCourbe.value.forEach((item, i) => {
        r.push({
            pointStyle: 'crossRot',
            radius: 4,
            borderWidth: 1,
            tension: 0.2,
            fill: false,
            backgroundColor: color[i],
            borderColor: color[i],
            showLine: true,
            label: item.label,
            data: item.courbe,
        })
    })
    return r
}

const preparationCourbe = computed(() => {
    console.log('PREPARATION COURBE ************************')
    const dataEntree = JSON.parse(JSON.stringify(props.d))

    const listeCourbe = dataEntree.con.map((o) => {
        return {
            label: o.type + ' ' + o.id,
            courbe: o.material.distribution,
        }
    })
    const listeTamis = new Set()
    listeCourbe.forEach((item) => {
        item.courbe.forEach((k) => listeTamis.add(k.x))
    })

    listeCourbe.map((courbe) => {
        listeTamis.forEach((tamis) => {
            courbe.courbe = courbeMethods.courbeAjoutTamis(courbe.courbe, tamis)
        })
        return courbe
    })

    if (storeUnit.inch) {
        listeCourbe.map((etage) => {
            etage.courbe.map((u) => {
                u.x = conv.mm_inch(u.x)
                return u
            })
        })
    }
    if (graphType.value != 'cumulPassing') {
        listeCourbe.map((etage) => {
            etage.courbe.map((u) => {
                u.y = 100 - u.y
                return u
            })
        })
    }
    console.log(listeCourbe)
    return listeCourbe
})
const tableau = computed(() => {
    const res = []
    for (let i = 0; i < preparationCourbe.value[0].courbe.length; i++) {
        const ligne = []
        ligne.push(preparationCourbe.value[0].courbe[i].x)
        preparationCourbe.value.forEach((item) => {
            ligne.push(item.courbe[i].y)
        })
        res.push(ligne)
    }
    console.table(res)
    return res
})
watch(preparationCourbe, () => {
    console.log('On watch')
    myGraphique.data.datasets = getCourbe()
    myGraphique.update()
})
onMounted(() => {
    myGraphique = new Chart(document.getElementById('canvas10254'), {
        type: 'line',
        options: options,
        data: {
            datasets: getCourbe(),
        },
    })
})
</script>
<style scoped lang="scss">
select {
    font-size: 0.8rem;
    margin: 0.2rem;
}
.zGraph {
    position: relative;
    margin-left: 2rem;
    margin-right: 2rem;
    canvas {
        //width: 80%;
        min-width: 40rem !important;
        margin: auto;
        margin-bottom: 3rem;
        max-height: 60vh;
    }
}

body {
    height: 60vh;
    overflow-y: auto;
}

table {
    border-collapse: collapse;
}
tr {
    text-align: center;
}

.tableTitre {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}
.tableLigne {
    border-bottom: 1px solid black;
}
</style>
