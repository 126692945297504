<template>
    <div class="section-top">
        <div class="dataCrusher">
            <div class="blocData">
                <!-- Brand -->
                <div class="bloc-input-pbi">
                    <label for="brand00">{{ t('word.brand') }}</label>
                    <input
                        v-model="dLocal.technical.brand"
                        name="brand00"
                        list="brand"
                        type="text"
                        @change="getlist()"
                    />
                    <datalist id="brand">
                        <option v-for="item in brandCrusherList" :key="item">
                            {{ item }}
                        </option>
                    </datalist>
                </div>
                <!-- Reference -->
                <div class="bloc-input-pbi">
                    <label for="ref00">{{ t('word.ref') }}</label>
                    <input
                        v-model="dLocal.technical.ref"
                        name="ref00"
                        list="ref"
                        type="text"
                    />
                    <datalist id="ref">
                        <option
                            v-for="cr in listTechnical.listCrusher"
                            :key="cr"
                        >
                            {{ cr }}
                        </option>
                    </datalist>
                </div>
            </div>
            <div class="blocData">
                <div class="bloc-input-pbi">
                    <label for="css00">{{ t('word.css') }}</label>
                    <input
                        v-model="dLocal.technical.css"
                        name="css00"
                        type="number"
                        step="any"
                        :min="listTechnical.cssMin"
                        :max="listTechnical.cssMax"
                        @change="majFlowRef()"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
                <div class="bloc-input-pbi">
                    <label for="throw00">{{ t('word.throw') }}</label>
                    <input
                        v-model="dLocal.technical.throw"
                        name="throw00"
                        :disabled="crusherExist"
                        type="number"
                        step="any"
                        min="0"
                        @change="majFlowRef()"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
            </div>
            <div class="blocData">
                <div class="bloc-input-pbi">
                    <label for="width00">{{ t('word.width') }}</label>
                    <input
                        v-model="dLocal.technical.width"
                        name="width00"
                        :disabled="crusherExist"
                        type="number"
                        step="any"
                        min="0"
                        @change="majFlowRef()"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
                <div class="bloc-input-pbi">
                    <label for="gap00">{{ t('word.gape') }}</label>
                    <input
                        v-model="dLocal.technical.gape"
                        name="gap00"
                        :disabled="crusherExist"
                        type="number"
                        step="any"
                        min="0"
                    />
                    <span class="unit">{{ t(unit.length) }}</span>
                </div>
            </div>
        </div>
    </div>
    <p v-if="!crusherExist" class="msgCrusherDoesNotExist">
        {{ t('modalCrusher.crusherNotFind') }}
    </p>

    <formCourbeCrusher :d="dLocal">
        <template #btnCrCalc>
            <btnCalcCrusher
                :d="dLocal"
                :c-exist="true"
                @open-modal-prm="showTechPrm = true"
            />
        </template>
    </formCourbeCrusher>
    <modalfooter @save="save()"></modalfooter>
</template>

<script setup>
import modalfooter from './compCommon/0_modalfooter.vue'
//import modaldistribution from "./0_modaldistribution.vue";
import formCourbeCrusher from './compCommon/0_formCourbe.vue'
import btnCalcCrusher from './compCrusher/0_btnCalcModeCrushing'
//import modalcrushingparameter from "./compCrusher/2_modalConeTechnicalParameter.vue";

import unitLib from './compCommon/unit.js'
const { unit, conv, storeUnit } = unitLib()

import {
    defineEmits,
    defineProps,
    ref,
    inject,
    computed,
    onMounted,
    //  reactive,
} from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const socket = inject('socket')

//import conv from "@/components/convert.js";
//import { stUnit } from "@/store/index.js";
//const storeUnit = stUnit();

const props = defineProps({ d: { type: Object, required: true } })
const dLocal = ref(props.d)
const emit = defineEmits(['save'])
const brandCrusherList = ['RP', 'Sandvik', 'Metso']
let detailsCrusher = ref([])

function getlist() {
    detailsCrusher.value = undefined
    if (brandCrusherList.includes(dLocal.value.technical.brand)) {
        console.log('GETINFOMACHINE / on lance la socket ')
        const a = dLocal.value.technical.brand
        try {
            // consoleinfo(a);

            socket.emit('GETINFOMACHINE', {
                type: 'jaw',
                brand: a,
            })
        } catch (error) {
            console.info(error)
        }
    } else {
        console.log("Cette marque n'est pas référencé")
    }
}
function majElts(f) {
    if (crusherExist.value) {
        dLocal.value.technical.maxFeedSize = f.maxFeedSize
        dLocal.value.technical.throw = f.throw
        dLocal.value.technical.width = f.width
        dLocal.value.technical.flowReference = f.flowReference
        dLocal.value.technical.gape = f.gape
        dLocal.value.technical.isExist = crusherExist.value
        dLocal.value.technical.css =
            dLocal.value.technical.css < f.cssFlow.cssMin ||
            dLocal.value.technical.css > f.cssFlow.cssMax
                ? f.cssFlow.cssMax
                : dLocal.value.technical.css
        // dLocal.value.technical.flowReference = f.flowReference;
    } else {
        dLocal.value.technical.maxFeedSize = 1000
        dLocal.value.technical.flowReference = 1000
        dLocal.value.technical.isExist = crusherExist.value
    }
}
function majFlowRef() {
    console.log(
        `Attention mise à jour du flow reference | ${dLocal.value.technical.css}`
    )
    if (crusherExist.value) {
        const c = detailsCrusher.value.find(
            (u) =>
                u.brand == dLocal.value.technical.brand &&
                u.ref == dLocal.value.technical.ref
        )
        //  Flow reference  = a * css + b
        const a =
            (c.cssFlow.flowMax - c.cssFlow.flowMin) /
            (c.cssFlow.cssMax - c.cssFlow.cssMin)
        const b = c.cssFlow.flowMin - c.cssFlow.cssMin * a
        dLocal.value.technical.flowReference =
            a * parseFloat(dLocal.value.technical.css) + b
    } else {
        const th = parseFloat(dLocal.value.technical.throw) || 20
        const css = parseFloat(dLocal.value.technical.css) || 120
        const w = parseFloat(dLocal.value.technical.width) || 800

        dLocal.value.technical.flowReference =
            0.6 * (w * (th + css) * Math.pow(0.0393701, 2))
    }
    console.log(`Flow reference :=> ${dLocal.value.technical.flowReference}`)
}
function initialUnitTranslation() {
    if (storeUnit.inch) {
        dLocal.value.technical.css = conv.mm_inch(dLocal.value.technical.css)
        dLocal.value.technical.throw = conv.mm_inch(
            dLocal.value.technical.throw
        )
        dLocal.value.technical.gape = conv.mm_inch(dLocal.value.technical.gape)
        dLocal.value.technical.width = conv.mm_inch(
            dLocal.value.technical.width
        )
    }
}
function save() {
    if (storeUnit.inch) {
        dLocal.value.technical.css = conv.inch_mm(dLocal.value.technical.css)
        dLocal.value.technical.throw = conv.inch_mm(
            dLocal.value.technical.throw
        )
        dLocal.value.technical.gape = conv.inch_mm(dLocal.value.technical.gape)
        dLocal.value.technical.width = conv.inch_mm(
            dLocal.value.technical.width
        )
    }
    console.log(dLocal.value)
    emit('save', dLocal.value)
}

const crusherExist = computed(() => {
    const a = detailsCrusher.value ?? []
    let res = false
    const rechercheCrusher = a.find(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref
    )

    if (undefined != rechercheCrusher) {
        res = true
        //  selectedCrusher = rechercheCrusher;
    }
    return res
})
const listTechnical = computed(() => {
    const res = {}
    const a = detailsCrusher.value ?? []
    const listCrusher = a.map((f) => f.ref)
    res.listCrusher = listCrusher.filter(
        (g, index) => listCrusher.indexOf(g) == index
    )

    const crusherFound = a.find(
        (u) =>
            u.brand == dLocal.value.technical.brand &&
            u.ref == dLocal.value.technical.ref
    )

    if (crusherFound) {
        res.cssMin = crusherFound.cssFlow.cssMin || 0
        res.cssMax = crusherFound.cssFlow.cssMax || 1000
    }

    majElts(crusherFound)
    return res
})

onMounted(() => {
    //dLocal.value = JSON.parse(JSON.stringify(props.d))
    socket.on('SENDINFOMACHINE', (d) => {
        console.info('**************************************** SOCKET ON ')
        console.info('RECEPTION INFO MACHINE DEPUIS LE SERVEUR ')
        // let n = JSON.parse(d)
        detailsCrusher.value = JSON.parse(d)
    })
    getlist()
    initialUnitTranslation()
})
</script>
<style scoped lang="scss">
.dataCrusher {
    padding: 0.5rem;
    @extend .flex-c-sb;
    .blocData {
        border-left: solid 1px $ShadowColor;
        padding: 0.5rem;
    }
    :first-child {
        border: none;
    }
}
.msgCrusherDoesNotExist {
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
}
</style>
