import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/page-home.vue'
import PFD from '@/views/page-PFD.vue'
import Contact from '@/views/page-contact.vue'
import NotFound from '@/views/page-NotFound.vue'

const routes = [
    {
        name: 'Home',
        path: '/',
        component: Home,
        meta: {
            title: 'How simulate crushing and screening plant',
            metaTags: [
                {
                    name: 'description',
                    content:
                        'Simulate your crushing and screening plant online. Optimize your process, test modification and find the optimum of your plant.',
                },
            ],
        },
    },
    {
        name: 'PFD',
        path: '/PFD',
        component: PFD,
        meta: {
            title: 'Create your own flowsheet',
            metaTags: [
                {
                    name: 'description',
                    content:
                        'Create your flowsheet! Draw your plant with crusher, screen...',
                },
            ],
        },
    },
    {
        name: 'Contact',
        path: '/Contact',
        component: Contact,
        meta: {
            title: 'Contact',
            metaTags: [
                {
                    name: 'description',
                    content: 'Pierre Billard, Engineer, contact information',
                },
            ],
        },
    },
    {
        name: 'NotFound',
        path: '/:pathMatch(.*)',
        component: NotFound,
        meta: { title: '404 not found' },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.before
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
        (el) => el.parentNode.removeChild(el)
    )

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement('meta')

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key])
            })

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '')

            return tag
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag))

    next()
})

export default router
