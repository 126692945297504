<template lang="html">
    <div class="modal-mask-pbi">
        <div class="modal-wrapper-pbi">
            <div class="modal-container-pbi">
                <div class="modal-header-pbi">
                    <h4 class="modal-title">Crushing parameter</h4>
                    <button type="button" class="close" @click="emit('close')">
                        &times;
                    </button>
                </div>
                <div class="modal-body-pbi">
                    <div class="general">
                        <div class="classificationPrm">
                            <h4 class="title">Classification parameter</h4>
                            <div class="bloc-input-pbi">
                                <label for="">Ref feed rate</label>
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef.refFeedRate
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">t/h </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for=""
                                    >Impact energy per unit mass</label
                                >
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef
                                            .refImpactEnergy
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">J/kg </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">Specific size &beta;</label>
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef
                                            .refSpecificParticuleSize
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">mm </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">Material parameter k</label>
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef
                                            .refMaterialPrm_k
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">- </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">Material parameter n</label>
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef
                                            .refMaterialPrm_N
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">- </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">Material parameter s</label>
                                <input
                                    v-model="
                                        dataTemp.impactCrusherRef
                                            .refIntensityPartPartInter_S
                                    "
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">- </span>
                            </div>
                        </div>
                        <div class="crushingPrm">
                            <h4 class="title">Breakage matrix parameter</h4>
                            <div class="bloc-input-pbi">
                                <label for="">&phi;</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.phi"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">m</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.m"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                            <div class="bloc-input-pbi">
                                <label for="">l</label>
                                <input
                                    v-model="dataTemp.breakageMatrixRef.l"
                                    type="number"
                                    step="any"
                                />
                                <span class="unit">unit </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="source">
                    <p>
                        Modelling and simulation of particle breakage in impact
                        crushers | S.Nikolov <br />
                        International Journal of Mineral Processing Volume 74,
                        Supplement, 10 December 2004, Pages S219-S225
                    </p>
                    <a
                        href="https://www.sciencedirect.com/science/article/abs/pii/S0301751604000560"
                        >consult article</a
                    >
                </div>
                <div class="modal-footer-pbi">
                    <button @click="emit('close')">save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue'
const props = defineProps({ d: { type: Object, required: true } })
const dataTemp = ref(props.d)
const emit = defineEmits(['close'])
</script>

<style lang="scss" scoped>
.general {
    display: flex;
    align-items: top;
    justify-content: space-around;
    .title {
        font-weight: 600;
        margin: 0.5rem;
        margin-bottom: 1rem;
        text-align: center;
    }
    div {
        padding: 0rem 1rem;

        &:first-child {
            border-right: solid 1px $ShadowColor;
        }
    }
    .classificationPrm {
        label {
            width: 15rem;
        }
    }
}
.source {
    text-align: center;
    p,
    a {
        font-size: 0.8rem;
        margin: 0;
    }
}
</style>
