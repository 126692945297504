<template lang="html">
    <div class="formulaire formulaireWidth">
        <h3>Feel free to review our apps ! We loved to be helped to improve</h3>
        <input
            v-model="user"
            type="mail"
            name=""
            placeholder="your-email@company.xxx"
        />
        <textarea v-model="message" name="name" rows="8" cols="80"></textarea>
        <button
            type="button"
            name="button"
            @click="sendMessage('pierre vous envoie un message')"
        >
            Send msg
        </button>
    </div>
</template>

<script>
// import io from 'socket.io-client';

export default {
    data() {
        return {
            user: '',
            message: '',
        }
    },

    created() {
        // e.preventDefault();
        console.log(`On est isi : ${this.user}`)
    },
    methods: {
        sendMessage() {
            // m.preventDefault();
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
            if (this.user.match(regex)) {
                console.log('---------------1')
                // this.$emit('sendReview',{user:this.user,text:this.message});
                try {
                    console.log({ user: this.user, text: this.message })
                    // this.socket.emit('SEND_REVIEW', a);
                    this.$socket.emit('SEND_REVIEW', {
                        user: this.user,
                        text: this.message,
                    })
                } catch (error) {
                    console.info(error)
                }

                this.message =
                    'Thanks for reviewing our works, it is most valuable for us !'
            } else {
                alert('Your mail adress seems to be unvalid')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.formulaire {
    display: block;

    //padding: 20px;
    padding: 0px;
    margin: auto;

    input {
        //margin:20px;
        padding: 0px;
        //width: -webkit-fill-available;
        text-align: center;
        width: 95%;
    }
    textarea {
        @extend input;
        padding: 2px;
        //width: -webkit-fill-available;
        width: 95%;
        //height: 50px;
        margin-top: 10px;
    }

    button {
        margin: auto;
        margin-bottom: 20px;
        padding: 10px;
        width: 95%;
    }
}

@media (max-width: 500px) {
    .formulaireWidth {
        width: 90%;
    }
}
@media (min-width: 500px) {
    .formulaireWidth {
        width: 50vh;
    }
}

@media print {
    * {
        display: none;
    }
}
</style>
