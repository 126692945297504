<template>
    <div class="modal-header-pbi">
        <div class="titreElt">
            <h3 class="modal-title">{{ title + ' ' + dataLocal.id }}</h3>

            <input
                v-model="dataLocal.name"
                type="text"
                :placeholder="t('modalHeader.placeholder.textInput')"
            />
        </div>

        <div class="bloc-bouton">
            <button
                :title="t('modalHeader.title.btnGraph')"
                @click="show.graph = true"
            >
                <i class="material-icons">trending_up</i>
            </button>
            <button
                :title="t('modalHeader.title.btnPrm')"
                @click="show.prm = true"
            >
                <i class="material-icons">build</i>
            </button>
            <button
                :title="t('modalHeader.title.btnClose')"
                @click="emit('close')"
            >
                <i class="material-icons">close</i>
            </button>
        </div>
    </div>
    <prmModal
        v-show="show.prm"
        :obj-src="dataLocal.prmShow"
        @close="show.prm = false"
        @save="
            //dataLocal = $event;
            show.prm = false
        "
    ></prmModal>
    <modalgraphique
        v-if="show.graph"
        :d="d"
        @close="show.graph = false"
    ></modalgraphique>
</template>

<script setup>
import prmModal from './0_modalPRM.vue'
import modalgraphique from './0_modalGraphique.vue'

import { defineProps, defineEmits, reactive, ref, computed } from 'vue'

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const emit = defineEmits(['close'])
const props = defineProps({ d: { type: Object, required: true } })
const dataLocal = ref(props.d)

const show = reactive({
    graph: false,
    prm: false,
})

const title = computed(() => {
    let res = ''
    switch (dataLocal.value.type) {
        case 'crusherJaw':
            res = 'Jaw Crusher'
            break
        case 'crusherHSI':
        case 'crusherVSI':
            res = 'Impact Crusher'
            break
        case 'crusherCone':
            res = 'Cone crusher'
            break
        case 'screen1':
        case 'screen2':
        case 'screen3':
            res = 'Screen'
            break
        case 'afeedFeed':
        case 'Feed':
            res = 'Feed'
            break
        default:
            res = 'Standart'
    }
    return res
})
</script>

<style scoped lang="scss">
.titreElt {
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    border: none;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 1rem;
    background: #f2f2f2;
    text-align: center;
    border-radius: 0.2rem;
}
</style>
