<template>
    <line
        :x1="d.point[0].x"
        :y1="d.point[0].y"
        :x2="d.point[1].x"
        :y2="d.point[1].y"
    ></line>
    <line
        :x1="d.point[1].x"
        :y1="d.point[1].y"
        :x2="d.point[2].x"
        :y2="d.point[2].y"
    ></line>
    <line
        :x1="d.point[2].x"
        :y1="d.point[2].y"
        :x2="d.point[3].x"
        :y2="d.point[3].y"
    ></line>
    <line
        :x1="d.point[3].x"
        :y1="d.point[3].y"
        :x2="d.point[4].x"
        :y2="d.point[4].y"
    ></line>
    <line
        :x1="d.point[4].x"
        :y1="d.point[4].y"
        :x2="d.point[5].x"
        :y2="d.point[5].y"
    ></line>

    <polygon :points="fleche" />

    <circle
        :cx="d.point[1].x"
        :cy="d.point[1].y"
        class="inertCircleFeed"
        :r="circleSize.inner"
    />
    <circle
        :cx="d.point[2].x"
        :cy="d.point[2].y"
        class="inertCircleFeed"
        :r="circleSize.inner"
    />
    <circle
        :cx="d.point[3].x"
        :cy="d.point[3].y"
        class="inertCircleFeed"
        :r="circleSize.inner"
    />
    <circle
        :cx="d.point[4].x"
        :cy="d.point[4].y"
        class="inertCircleFeed"
        :r="circleSize.inner"
    />

    <circle
        :cx="d.point[1].x"
        :cy="d.point[1].y"
        :r="circleSize.outer"
        class="grab_element rond_fil"
        @mousedown="mouvementFil(1)"
        @dblclick="supprimerFil()"
    />

    <circle
        :cx="d.point[2].x"
        :cy="d.point[2].y"
        :r="circleSize.outer"
        class="grab_element rond_fil"
        @mousedown="mouvementFil(2)"
        @dblclick="supprimerFil()"
    />

    <circle
        :cx="d.point[3].x"
        :cy="d.point[3].y"
        :r="circleSize.outer"
        class="grab_element rond_fil"
        @mousedown="mouvementFil(3)"
        @dblclick="supprimerFil()"
    />

    <circle
        :cx="d.point[4].x"
        :cy="d.point[4].y"
        :r="circleSize.outer"
        class="grab_element rond_fil"
        @mousedown="mouvementFil(4)"
        @dblclick="supprimerFil()"
    />
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'
const props = defineProps({ d: { type: Object, required: true } })
const emit = defineEmits(['movedotconnector', 'deleteconnector'])
const circleSize = { inner: 1.2, outer: 2 }

function mouvementFil(pointIndex) {
    emit('movedotconnector', { id: props.d.id, indexPt: pointIndex })
}
function supprimerFil() {
    if (confirm('Are sure you want to delete this connector ?')) {
        emit('deleteconnector', props.d)
    }
}
const fleche = computed(() => {
    let phrase = ''
    const t = props.d.point
    const w = 6
    const h = 6
    const z = 2
    phrase += `${t[5].x} ${t[5].y - z}, `
    phrase += `${t[5].x - w / 2} ${t[5].y - z - h}, `
    phrase += `${t[5].x + w / 2} ${t[5].y - z - h}`
    return phrase
})
/*
export default {
  name: "fil",
  props: { d: Object },
  data: () => ({
    type: "dry",
    circleSize: { inner: 1.2, outer: 2 },
  }),
  methods: {
    mouvementFil(pointIndex) {
      this.$emit("moveDotConnector", { id: this.d.id, indexPt: pointIndex });
    },
    supprimerFil() {
      if (confirm("Are sure you want to delete this connector ?")) {
        console.log("ok");
        this.$emit("deleteConnector", this.d);
      }
    },
  },
  computed: {
    fleche() {
      let phrase = "";
      const t = this.d.point;
      const w = 6;
      const h = 6;
      const z = 2;
      phrase += `${t[5].x} ${t[5].y - z}, `;
      phrase += `${t[5].x - w / 2} ${t[5].y - z - h}, `;
      phrase += `${t[5].x + w / 2} ${t[5].y - z - h}`;
      return phrase;
    },
  },
};
*/
</script>
<style scoped lang="scss">
.inertCircleFeed {
    fill: tomato;
}
.rond_fil {
    r: 1.5;
    fill: tomato;
    stroke: #888;
    stroke-width: 5;
    stroke-opacity: 0;
}
.rond_fil:hover {
    r: 3;
    stroke-opacity: 0.8;
}

polygon {
    fill: tomato;
}
</style>
