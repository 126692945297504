'use strict'
//Définition principal
function getElementStandart(ref) {
    return {
        id: 'menu',
        type: ref,
        name: '',
        gpos: { x: 10, y: 50 },
        con: [],
        prmShow: {
            flow: true,
            technical: { show: false, posX: -80, posY: 20 },
            name: { show: true, posX: -80, posY: -20 },
            mesh: false,
        },
        perfResults: { load: 1 },
    }
}
//Définition materiaux
function getMaterialTemplate() {
    const matIntrinseque = {
        material: 'LimeStone',
        density: 1.6,
        moisture: 3,
        clay: 1,
        wIndex: 15,
        losAngeles: 23,
        abrasivity: 0.35,
    }
    const distributionStd = JSON.parse(
        JSON.stringify([
            { x: 0.063, y: 1 },
            { x: 1, y: 5 },
            { x: 2, y: 10 },
            { x: 4, y: 40 },
            { x: 10, y: 50 },
            { x: 20, y: 100 },
        ])
    )

    const template = {
        flow: 300,
        distribution: [...distributionStd],
        intrinseque: { ...matIntrinseque },
    }
    return template
}
//Définition Feed | Discharge | Deck
function getDataConnecteur(ref) {
    const dataConnector = [
        {
            type: 'crusherCone',
            con: [
                { type: 'feed', x: 20, y: 5 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'crusherJaw',
            con: [
                { type: 'feed', x: 15, y: 7 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'crusherHSI',
            con: [
                { type: 'feed', x: 8, y: 8 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'crusherVSI',
            con: [
                { type: 'feed', x: 20, y: 7 },
                { type: 'discharge', x: 20, y: 32 },
            ],
        },
        {
            type: 'Bin',
            con: [
                { type: 'feed', x: 20, y: 5 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'StockPile',
            con: [
                { type: 'feed', x: 20, y: 5 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'PanFeeder',
            con: [
                { type: 'feed', x: 10, y: 3 },
                { type: 'discharge', x: 35, y: 25 },
            ],
        },
        {
            type: 'BeltFeeder',
            con: [
                { type: 'feed', x: 7, y: 3 },
                { type: 'discharge', x: 30, y: 38 },
            ],
        },
        {
            type: 'PrimaryFeeder',
            con: [
                { type: 'feed', x: 15, y: 5 },
                { type: 'discharge', x: 35, y: 30 },
            ],
        },
        {
            type: 'Split',
            con: [
                { type: 'feed', x: 10, y: 2 },
                { type: 'discharge', x: 2, y: 18 },
                { type: 'discharge', x: 18, y: 18 },
            ],
        },
        {
            type: 'Add',
            con: [
                { type: 'feed', x: 2.5, y: 2.5 },
                { type: 'feed', x: 17.5, y: 2.5 },
                { type: 'discharge', x: 10, y: 20 },
            ],
        },
        { type: 'Stock', con: [{ type: 'feed', x: 20, y: 7 }] },
        { type: 'Feed', con: [{ type: 'discharge', x: 20, y: 37 }] },
        {
            type: 'GrizzlyScreen',
            con: [
                { type: 'feed', x: 15, y: 5 },
                { type: 'deck', x: 35, y: 30 },
                { type: 'discharge', x: 20, y: 37 },
            ],
        },
        {
            type: 'screen1',
            con: [
                { type: 'feed', x: 10, y: 5 },
                { type: 'deck', x: 35, y: 20 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'screen2',
            con: [
                { type: 'feed', x: 10, y: 5 },
                { type: 'deck', x: 35, y: 15 },
                { type: 'deck', x: 35, y: 20 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
        {
            type: 'screen3',
            con: [
                { type: 'feed', x: 10, y: 5 },
                { type: 'deck', x: 35, y: 15 },
                { type: 'deck', x: 35, y: 20 },
                { type: 'deck', x: 35, y: 25 },
                { type: 'discharge', x: 20, y: 35 },
            ],
        },
    ]
    try {
        let res = dataConnector.filter((a) => a.type === ref)[0]
        return res
    } catch (err) {
        console.error('Erreur : materialTemplates.js | getDataConnecteur')
        console.error(err)
        return {}
    }
}
function getStdDeck() {
    const deckTech = { cutPoint: 40, meshType: 'wire', meshGeo: 'Square' }
    const perf = {
        deckFeedCapacity: 100,
        b: { isError: false },
        deckLoad: 0.85,
        status: 'NOK',
        nbImpact: 500,
        bed: {},
    }
    return {
        id: '1001',
        type: 'deck',
        x: 35,
        y: 15,
        connectedto: '',
        perf: { ...perf },
        deckTech: { ...deckTech },
        material: new getMaterialTemplate(),
    }
}
function getStdFeed() {
    return {
        id: '0000',
        type: 'feed',
        status: 'NOK',
        x: 10,
        y: 5,
        connectedto: '',
        material: new getMaterialTemplate(),
    }
}
function getStdDischarge() {
    return {
        id: '1001',
        type: 'discharge',
        status: 'NOK',
        x: 20,
        y: 35,
        connectedto: '',
        material: new getMaterialTemplate(),
    }
}

//Définition de la position des icones dans le menu
function getPositionMenu(req = 'screen1') {
    const dataPosMenu = {
        screen1: { x: 2, y: 10 },
        screen2: { x: 2, y: 60 },
        screen3: { x: 2, y: 110 },
        crusherJaw: { x: 2, y: 10 },
        crusherCone: { x: 2, y: 60 },
        crusherHSI: { x: 2, y: 110 },
        crusherVSI: { x: 2, y: 160 },
        Add: { x: 2, y: 10 },
        Split: { x: 2, y: 60 },
        Feed: { x: 2, y: 10 },
        Stock: { x: 2, y: 10 },
        StockPile: { x: 2, y: 60 },
        Bin: { x: 2, y: 110 },
        BeltFeeder: { x: 2, y: 10 },
        PanFeeder: { x: 2, y: 60 },
        PrimaryFeeder: { x: 2, y: 110 },
        GrizzlyScreen: { x: 2, y: 160 },
    }

    return dataPosMenu[req] || { x: 0, y: 0 }
}

//Définition technical
function getTechnicalDetails(ref) {
    let res = {}
    switch (ref) {
        case 'Split':
            res = { left: 100, right: 0 }
            break
        case 'screen1':
        case 'screen2':
        case 'screen3':
            res = {
                brand: 'RP',
                ref: 'SC0001',
                calcMode: 'modePerfect',
                motion: 'circular',
                width: 1000,
                length: 4000,
                stroke: 10,
                speed: 800,
                wash: false,
                anScreen: 16,
                anEcc: 45,
                maxFeedSize: 200,
                maxLoad: 1000,
            }
            break
        case 'GrizzlyScreen':
            res = {
                brand: 'RP',
                ref: 'SC0001',
                calcMode: 'modePerfect',
                motion: 'circular',
                width: 1000,
                length: 4000,
                stroke: 15,
                speed: 600,
                wash: false,
                anScreen: 16,
                anEcc: 45,
                maxFeedSize: 1200,
                maxLoad: 1000,
            }
            break
        case 'crusherCone':
            res = {
                brand: 'RP',
                ref: 'CR0001',
                calcMode: 'manual',
                flowReference: 400,
                css: 20,
                maxWI: 28,
                maxFeedSize: 800,
                chamber: 'F',
                mantle: 'Std',
                stroke: 30,
                isExist: false,
                classificationRef: {
                    alpha1: 0.6,
                    alpha2: 2.0,
                    dEtoile: 0,
                    n: 2,
                },
                breakageMatrixRef: {
                    phi: 0.2,
                    m: 0.5,
                    l: 2.5,
                },
                flowRef: {
                    CSS_min: 10,
                    CSS_max: 35,
                    refFlow_min: 46,
                    refFlow_max: 80,
                },
            }
            break
        case 'crusherHSI':
            res = {
                brand: 'RP',
                ref: 'CR0001',
                calcMode: 'manual',
                maxWI: 20,
                maxFeedSize: 400,
                flowReference: 1000,
                css: 20,
                rDiam: 900,
                speed: 600,
                isExist: false,
                impactCrusherRef: {
                    refFeedRate: 400, // en tph
                    refImpactEnergy: 400, // en J/kg
                    refSpecificParticuleSize: 10, // Beta Nikolov
                    refMaterialPrm_N: 0.73,
                    refMaterialPrm_k: 0.95,
                    refIntensityPartPartInter_S: 0.2,
                },
                breakageMatrixRef: {
                    phi: 0.35,
                    m: 0.5,
                    l: 2.5,
                },
            }
            break
        case 'crusherVSI':
            res = {
                brand: 'RP',
                ref: 'CR0001',
                calcMode: 'manual',
                maxWI: 14,
                maxFeedSize: 40,
                flowReference: 1000,
                css: 20,
                rDiam: 900,
                speed: 600,
                isExist: false,
                impactCrusherRef: {
                    refFeedRate: 400, // en tph
                    refImpactEnergy: 400, // en J/kg
                    refSpecificParticuleSize: 10, // Beta Nikolov
                    refMaterialPrm_N: 0.73,
                    refMaterialPrm_k: 0.95,
                    refIntensityPartPartInter_S: 0.2,
                },
                breakageMatrixRef: {
                    phi: 0.35,
                    m: 0.5,
                    l: 2.5,
                },
            }
            break
        case 'crusherJaw':
            res = {
                brand: 'RP',
                ref: 'CR0001',
                calcMode: 'manual',
                isExist: false,
                maxFeedSize: 1000,
                flowReference: 1000,
                css: 20,
                throw: 11,
                width: 1000,
                gape: 1000,
            }
            break
        default:
            res = {}
    }
    return res
}

export default function fctGeneral(ref, compteur = 'menu') {
    //function (ref, compteur) {
    //Donnée connexion
    try {
        const dataConnector = getDataConnecteur(ref)
        let res = getElementStandart(ref)
        let iFeed = 0
        let iDischarge = 0
        dataConnector.con.forEach((item) => {
            //console.log(item);
            switch (item.type) {
                case 'deck':
                    res.con.push({
                        ...getStdDeck(),
                        id: '100' + iDischarge,
                        x: item.x,
                        y: item.y,
                    })
                    iDischarge += 1
                    break
                case 'feed':
                    res.con.push({
                        ...getStdFeed(),
                        id: '000' + iFeed,
                        x: item.x,
                        y: item.y,
                    })
                    iFeed += 1
                    break
                case 'discharge':
                    res.con.push({
                        ...getStdDischarge(),
                        id: '100' + iDischarge,
                        x: item.x,
                        y: item.y,
                    })
                    iDischarge += 1

                    break
            }
        })
        res.technical = getTechnicalDetails(ref)
        res.id = compteur
        if (compteur == 'menu') {
            res.gpos.x = 5
            res.gpos.y = getPositionMenu(ref).y
        }
        console.log({ ref })
        if (ref == 'Split' || ref == 'Add') {
            res.prmShow.flow = false
        }
        return res
    } catch (err) {
        console.error('Erreur : materialTemplates.js | createToolsTemplates')
        console.log(err)
    }
}
