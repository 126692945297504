'use strict'
import { useCookies } from 'vue3-cookies'
export default function () {
    const { cookies } = useCookies()

    function readCookies() {
        let res = {}
        res.plant = cookies.get('plant')
        res.mail = cookies.get('mailCookies')
        res.autho = cookies.get('cookAcceptance')

        return res
    }

    return { readCookies, cookies }
}
