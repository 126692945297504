'use strict'

export default function (plant) {
    function deleteTools(req) {
        const idOutils = req.id
        const indexOutils = plant.tools.map((x) => x.id == req.id).indexOf(true)
        //Suppression des fils associées
        for (let i = 0; i < plant.filTableau.length; i++) {
            if (
                plant.filTableau[i].end.split('@')[0] == idOutils ||
                plant.filTableau[i].start.split('@')[0] == idOutils
            ) {
                plant.filTableau.splice(i, 1)
                i -= 1
            }
        }
        //Suppresions des connexions aus autres elements tools
        plant.tools.forEach((t) => {
            t.con.forEach((c) => {
                if (c.connectedto.split('@')[0] == idOutils) {
                    c.connectedto = ''
                }
            })
        })
        plant.tools.splice(indexOutils, 1)
    }
    function deleteConnector(req) {
        //console.log('this is the connector')
        //console.log(req)
        const i = plant.filTableau.indexOf(req)
        //console.log('index of fil to delete : ' + i)
        plant.filTableau.splice(i, 1)
        //On doit supprimer les connexions dans tools
        plant.tools.forEach((mc) => {
            if (mc.id == req.start.split('@')[0]) {
                mc.con.forEach((con) => {
                    if (con.id == req.start.split('@')[1]) {
                        con.connectedto = ''
                    }
                })
            }
            if (mc.id == req.end.split('@')[0]) {
                mc.con.forEach((con) => {
                    if (con.id == req.end.split('@')[1]) {
                        con.connectedto = ''
                    }
                })
            }
        })
    }

    return { deleteTools, deleteConnector }
}
